import React from "react";
import CustomButton from "../../resuableComponents/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectIsMobileLayout } from "../../../slices/MobileLayoutSlice";
import { isEmpty } from "../../../Utils/utils";
import { addSavedAddress, updateSavedAddress, } from "../../../slices/SavedAddressSlice";
import { getAllPaymentMethods, updateSavedAddressData } from "../../../slices/PaymentSessionDetailsSlice";
import { selectMobileNumber, selectSessionToken } from "../../../slices/ShopperInfoSlice";
import "../paymentButton/paymentButton.css";

export default function MobileAddressButton({
  disabled,
  showCancelButton,
  setShowAddAddressOption,
  setSelectedAddressItem,
  selectedAddressItem,
  payloadData,
  otherLabel,
  selectedSavedAddress,
  selectedCardData,
  handleButtonClicked,
  guestCheckout,
  handleGuestData,
  handleBasicDetails,
}) {
  const { t } = useTranslation();
  const shopperRef = useSelector((state) => selectMobileNumber(state));
  const sessionToken = useSelector((state) => selectSessionToken(state));
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));
  const addressButtonLoading = useSelector((state) => state.savedAddressDetails.addressButtonLoading);
  const isShippingAddressDisplay = useSelector((state) => state.paymentSessionDetails.isShippingAddressDisplay);
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
  const showLogin = useSelector((state) => state.additionalComponents.showLogin);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const payload = {
      name: payloadData?.name,
      address1: payloadData?.address1,
      address2: payloadData?.address2,
      city: payloadData?.city,
      state: payloadData?.state,
      countryCode: payloadData?.countryCode,
      postalCode: payloadData?.postalCode,
      labelType: payloadData?.labelType,
      labelName: payloadData?.labelName,
      email: payloadData?.email,
      panNumber: payloadData?.panNumber,
      dateOfBirth: payloadData?.dateOfBirth,
      phoneNumber: isEmpty(payloadData?.phoneNumber) ? '' : payloadData?.phoneDialCode + payloadData?.phoneNumber,
    };
    dispatch(addSavedAddress({ shopperRef, payload, sessionToken }));
  };
  const handleEditAddress = () => {
    const payload = {
      name: payloadData?.name,
      address1: payloadData?.address1,
      address2: payloadData?.address2,
      city: payloadData?.city,
      state: payloadData?.state,
      countryCode: payloadData?.countryCode,
      postalCode: payloadData?.postalCode,
      labelType: payloadData?.labelType,
      labelName: payloadData?.labelName,
      email: payloadData?.email,
      panNumber: payloadData?.panNumber,
      dateOfBirth: payloadData?.dateOfBirth,
      phoneNumber: isEmpty(payloadData?.phoneNumber) ? '' : payloadData?.phoneDialCode + payloadData?.phoneNumber,
    };

    dispatch(
      updateSavedAddress({
        shopperRef,
        payload,
        sessionToken,
        labelType: selectedSavedAddress,
        labelName: otherLabel,
      })
    );
    setSelectedAddressItem("");
  };

  const handleSubmitSavedAddress = () => {
    dispatch(updateSavedAddressData(selectedCardData));
    handleButtonClicked(true);
  };

  const handleGuestCheckout = () => {
    const payload = {
      name: payloadData?.name,
      address1: payloadData?.address1,
      address2: payloadData?.address2,
      city: payloadData?.city,
      state: payloadData?.state,
      countryCode: payloadData?.countryCode,
      postalCode: payloadData?.postalCode,
      email: payloadData?.email,
      panNumber: payloadData?.panNumber,
      dateOfBirth: payloadData?.dateOfBirth,
      phoneNumber: isEmpty(payloadData?.phoneNumber) ? '' : payloadData?.phoneDialCode + payloadData?.phoneNumber,
    };

    handleGuestData(payload);
    dispatch(
      getAllPaymentMethods({
        token: sessionDetails?.token,
        countryCode: payload?.countryCode,
      })
    );
  };

  const handleBasicDetailsButton = () => {
    const payload = {
      name: payloadData?.name,
      email: payloadData?.email,
      phoneNumber: isEmpty(payloadData?.phoneNumber) ? '' : payloadData?.phoneDialCode + payloadData?.phoneNumber,
    };
    handleBasicDetails(payload);
  };

  return (
    <div className={isMobileLayout ? "domestic-mbtn-root" : ""}>
      {showCancelButton && (
        <CustomButton
          text={t("GO_BACK")}
          buttonType={"primary-btn"}
          padding={"10px 50px"}
          containerType={""}
          onClick={() => {
            setShowAddAddressOption(false);
            setSelectedAddressItem("");
          }}
        />
      )}
      <CustomButton
        text={t("CONTINUE")}
        buttonType={"primary-btn"}
        block={true}
        disabled={disabled}
        loading={addressButtonLoading}
        onClick={
          selectedAddressItem ? handleEditAddress
            : selectedCardData ? handleSubmitSavedAddress
              : guestCheckout || showLogin === false ? handleGuestCheckout
                : !isShippingAddressDisplay ? handleBasicDetailsButton
                  : handleSubmit
        }
      />
    </div>
  );
}
