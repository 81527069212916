import React, { useEffect } from "react";
import { Tooltip, Typography } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { generateDynamicStyling } from "../../../../Utils/utils";
import { selectCurrencyCode } from "../../../../slices/PaymentSessionDetailsSlice";
import { selectIsMobileLayout } from "../../../../slices/MobileLayoutSlice";
import TranslateText from "../../../resuableComponents/TranslateText";

export default function PaymentLinkOrderSummary() {
  const { t } = useTranslation();
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
  const currencyCode = useSelector((state) => selectCurrencyCode(state));
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));

  const timeConvert = (dates, locale) => {
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const timeZoneAbbreviation = browserTimeZone === "GMT" ? "GMT" : "IST";
    locale = locale.split("_").join("-");
    const date = new Date(dates);
    let istOptions = {
      timeZone: browserTimeZone,
      weekday: "short",
      day: "numeric",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    let Time;
    Time = date.toLocaleString(locale, istOptions);
    let arr = Time.split(",");
    let formattedTime = `${arr.pop()} ${timeZoneAbbreviation}`;
    arr = [arr.join(" "), formattedTime.toUpperCase()];
    return arr;
  };
  const currencySymbol = sessionDetails?.paymentDetails?.money?.currencySymbol;
  useEffect(() => {
    if (sessionDetails) {
      generateDynamicStyling(sessionDetails);
    }
  }, [sessionDetails]);

  return (
    <>
      {(sessionDetails?.title ||
        sessionDetails?.paymentDetails?.product ||
        sessionDetails?.description ||
        sessionDetails?.paymentDetails?.context?.orderId) && (
          <>
            {!isMobileLayout && (
              <div className="deliveryAddressHeader">
                <div className="headerDiv">
                  <img
                    src="/assets/cardHeader/OrderSummary.svg"
                    alt="card"
                    style={{ height: "16px", widht: "16px" }}
                  />
                  <Typography className="deeliveryHeading dynamic-font-family">
                    <TranslateText label={"ORDER_SUMMARY"}>Order Summary</TranslateText>
                  </Typography>
                </div>
              </div>
            )}
            <div className="orderSummaryCard">
              <div className="orderSummaryScroll">
                {sessionDetails?.title && (
                  <Tooltip placement="topLeft" title={sessionDetails?.title}>
                    <Typography className="dynamic-font-family payment-link-productTitle payment-link-title">
                      {sessionDetails?.title}
                    </Typography>
                  </Tooltip>
                )}
                {sessionDetails?.paymentDetails?.context?.orderId && (
                  <div style={{ display: "flex", gap: "4px" }}>
                    <Typography
                      className="dynamic-font-family payment-link-productName"
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      <TranslateText label={"REFERENCE_ID"}>Ref. Id</TranslateText>:
                    </Typography>
                    <Tooltip
                      placement="topLeft"
                      title={sessionDetails?.paymentDetails?.context?.orderId}
                    >
                      <Typography className="dynamic-font-family payment-link-productName payment-link-orderid">
                        {sessionDetails?.paymentDetails?.context?.orderId}
                      </Typography>
                    </Tooltip>
                  </div>
                )}
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  {sessionDetails?.productName && (
                    <Tooltip
                      placement="topLeft"
                      title={sessionDetails?.productName}
                    >
                      <Typography className="dynamic-font-family payment-link-productName payment-link-title">
                        {sessionDetails?.productName}
                      </Typography>
                    </Tooltip>
                  )}
                </div>{" "}
                {(sessionDetails?.paymentDetails?.product ||
                  sessionDetails?.description) && (
                    <Typography
                      className="dynamic-font-family payment-link-description "
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {sessionDetails?.description}
                    </Typography>
                  )}
              </div>
            </div>
          </>
        )}
    </>
  );
}
