import Typography from "antd/es/typography/Typography";
import { useTranslation } from "react-i18next";
import React from "react";
import "./orComponent.css";
import TranslateText from "../TranslateText";
const OrComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="or-root">
      <div className="or-left-divider"></div>
      <Typography
        className="regular12Heading dynamic-font-family"
        style={{ margin: "auto", color: "#878686" }}
      >
        <TranslateText label={"OR"}>or</TranslateText>
      </Typography>
      <div className="or-left-right"></div>
    </div>
  );
};

export default OrComponent;
