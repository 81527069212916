import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ShopperLogin from "../../../components/shopperLogin/ShopperLogin";
import ShopperLoginMobileConatiner from "../../../components/shopperLogin/ShopperLoginMobileConatiner";
import { useDispatch, useSelector } from "react-redux";
import { generateDynamicStyling } from "../../../../Utils/utils";
import Loading from "../../../components/loadingScreen/Loading";
import BottomFooter from "../../../components/checkoutFooter/BottomFooter";
import "./mobileLayout.css";
import "../styles.css";
import "../../../components/fontStyling.css";
import DeliveryAddress from "../../../components/address/deliveryAddress";
import { PaymentSummaryMobileContainer } from "../../../components/paymentSummary/PaymentSummaryMobileContainer";
import PaymentSummary from "../../../components/paymentSummary/PaymentSummary";
import OrderSummaryMobileContainer from "../../../components/orderSummary/OrderSummaryMobileContainer";
import OrderSummary from "../../../components/orderSummary/OrderSummary";
import PaymentOptions from "../../../components/paymentOptions/PaymentOptions";
import LogoutMobile from "../../../components/logout/LogoutMobile";
import PaymentButton from "../../../components/paymentButton/PaymentButton";
import {
  getSavedIntsruments,
  selectSavedInstruments,
  selectSelectedIntrument,
  updateSelectedIntruments,
} from "../../../../slices/SavedInstrumentSlice";
import {
  changeActivePaymentMethod,
  selectActivePaymentMethod,
} from "../../../../slices/PaymentButtonSlice";
import { changeUpiVpa } from "../../../../slices/UpiSlice";
import ErrorPage from "../../../components/errorPages/errorPage";
import {
  selectExpiryTimeStamp,
  setIsSessionInvalid,
  updatePaymentMethodDictionary,
  updateShopperDetails,
  updateUniqueReference,
} from "../../../../slices/PaymentSessionDetailsSlice";
import {
  resetShopperInfo,
  selectGuestCheckout,
} from "../../../../slices/ShopperInfoSlice";
import { selectUnauthorised } from "../../../../slices/ApiErrorSlice";
import { Typography } from "antd";
import ExpiryTimer from "../../../resuableComponents/ExpiryTimer";
import { checkoutLoadedAnalytics } from "../../../../Utils/uiAnalytics";
import GuestLogoutMobile from "../../../components/logout/GuestLogoutMobile";
import TranslateText from "../../../resuableComponents/TranslateText";

const MobileLayout = () => {
  const { t } = useTranslation()
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
  const unauthorised = useSelector((state) => selectUnauthorised(state));
  const guestCheckout = useSelector((state) => selectGuestCheckout(state));
  const isLoggedIn = useSelector((state) => state.shopperInfo.isLoggedIn);
  const activePaymentMethod = useSelector((state) => selectActivePaymentMethod(state));
  const isSessionExpired = useSelector((state) => state.paymentSessionDetails.isSessionExpired);
  const callerType = useSelector((state) => state.paymentSessionDetails.callerType);
  const isSessionInvalid = useSelector((state) => state.paymentSessionDetails.isSessionInvalid);
  const technicalError = useSelector((state) => state.paymentSessionDetails.technicalError);
  const addressCollpase = useSelector((state) => state.paymentSessionDetails.addressCollpase);
  const showLogin = useSelector((state) => state.additionalComponents.showLogin);
  const isShippingAddressDisplay = useSelector((state) => state.paymentSessionDetails.isShippingAddressDisplay);
  const { showEmailAddress, showFullName, showContactNumber } = useSelector((state) => state.additionalComponents);
  const [loading, setLoading] = useState(true);
  const savedInstruments = useSelector((state) => selectSavedInstruments(state));
  const [duration, setDuration] = useState(null);
  const expiryTimeStamp = useSelector((state) => selectExpiryTimeStamp(state));
  const isShowTimerExpiry = useSelector((state) => state.additionalComponents.isShowTimerExpiry);
  const showOrderItem = useSelector((state) => state.additionalComponents.showOrderItem);
  const isGuestLogedIn = useSelector((state) => state.shopperInfo.isGuestLogedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    if (expiryTimeStamp) {
      const newDate = new Date();
      const expTime = new Date(expiryTimeStamp);
      const timeDiff = expTime - newDate;
      const diffInSeconds = Math.floor(timeDiff / 1000);
      setDuration(diffInSeconds);
    }
  }, [expiryTimeStamp]);

  useEffect(() => {
    if (sessionDetails) {
      generateDynamicStyling(sessionDetails);
    }
    if (isLoggedIn && !guestCheckout) {
      dispatch(getSavedIntsruments({ checkoutToken: sessionDetails?.token }));
    }
    if (sessionDetails?.token) {
      checkoutLoadedAnalytics(sessionDetails, dispatch);
    }
  }, [sessionDetails, isLoggedIn]);

  useEffect(() => {
    if (savedInstruments?.length > 0) {
      dispatch(
        updatePaymentMethodDictionary({
          Recommended: savedInstruments,
        })
      );
    }
  }, [savedInstruments]);

  useEffect(() => {
    if (sessionDetails) {
      const delay = setTimeout(() => {
        setLoading(false);
      }, 500);

      return () => clearTimeout(delay);
    }
  }, [sessionDetails]);

  useEffect(() => {
    if (unauthorised) {
      dispatch(resetShopperInfo());
    }
  }, [unauthorised]);

  useEffect(() => {
    dispatch(updateSelectedIntruments(null));
    dispatch(changeUpiVpa(null));
  }, [activePaymentMethod]);

  useEffect(() => {
    if (sessionDetails) {
      const isCheckoutDomain = window.location.hostname.includes(
        "checkout.boxpay.tech"
      );
      const isNewCheckoutDomain = window.location.hostname.includes(
        "checkout-v2.boxpay.tech"
      );
      const isPaymentLinkDomain =
        window.location.hostname.includes("pl.boxpay.tech");
      if (
        (callerType === "PAYMENT_LINK" &&
          (isCheckoutDomain || isNewCheckoutDomain)) ||
        (callerType === "CHECKOUT" && isPaymentLinkDomain)
      ) {
        dispatch(setIsSessionInvalid(true));
      }
    }
  }, [sessionDetails, callerType]);

  const handleTimerExpire = () => {
    dispatch(setIsSessionInvalid(true));
  };
  const latestSavedAddress = useSelector(
    (state) => state.paymentSessionDetails.latestSavedAddress
  );
  const shopperRef = localStorage.getItem("shopperRef");
  useEffect(() => {
    if (shopperRef) {
      dispatch(updateUniqueReference(shopperRef));
    } else if (latestSavedAddress) {
      const selectedCardData = latestSavedAddress;
      const currentPhoneNumber = selectedCardData?.phoneNumber;
      const currentEmail = selectedCardData?.email;
      if (
        sessionDetails?.paymentDetails?.shopper?.phoneNumber !==
        currentPhoneNumber
      ) {
        dispatch(updateUniqueReference(currentPhoneNumber));
      }
      if (sessionDetails?.paymentDetails?.shopper?.email !== currentEmail) {
        dispatch(updateUniqueReference(currentEmail));
      }
      if (
        sessionDetails?.paymentDetails?.shopper?.phoneNumber ===
        currentPhoneNumber &&
        sessionDetails?.paymentDetails?.shopper?.email === currentEmail
      ) {
        dispatch(
          updateUniqueReference(
            sessionDetails?.paymentDetails?.shopper?.phoneNumber ||
            sessionDetails?.paymentDetails?.shopper?.uniqueReference
          )
        );
      }
      dispatch(updateShopperDetails({ selectedCardData }));
    }
  }, [sessionDetails, latestSavedAddress, shopperRef]);
  const selectedInstrument = useSelector((state) =>
    selectSelectedIntrument(state)
  );

  useEffect(() => {
    if (savedInstruments?.length > 0) {
      dispatch(changeActivePaymentMethod("Recommended"));
    }
  }, [savedInstruments]);
  return (
    <>
      {loading ? (
        <Loading
          text="Please wait. We are taking you to the checkout page"
          subText="Note: Kindly avoid using back button"
        />
      ) : (
        <>
          {isSessionExpired ? (
            <ErrorPage
              imgUrl="/assets/errorImage/sessionExpired.svg"
              heading={
                callerType === "PAYMENT_LINK"
                  ? "The payment link has expired"
                  : "The session has expired"
              }
              subHeading={
                callerType === "PAYMENT_LINK"
                  ? "You may now close the window"
                  : "Please go back and try again"
              }
            />
          ) : isSessionInvalid ? (
            <ErrorPage
              imgUrl="/assets/errorImage/invalidSession.svg"
              heading={
                callerType === "PAYMENT_LINK"
                  ? "Invalid Payment Link"
                  : "Invalid Session"
              }
              subHeading={
                callerType === "PAYMENT_LINK"
                  ? "Please change the URL or check it again"
                  : "Please contact support for further assistance"
              }
            />
          ) : technicalError ? (
            <ErrorPage
              imgUrl="/assets/errorImage/technical.svg"
              heading="Technical Error"
              subHeading="Please try again in some time"
            />
          ) : (
            <div className="domestic-m-layout-root dynamic-font-family">
              {
                (showEmailAddress || showFullName || showContactNumber || isShippingAddressDisplay) && (
                  <div
                    className="review-pay-container"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography className="medium16Heading">
                      <TranslateText label={"REVIEW_AND_PAY"}>Review & Pay</TranslateText>
                    </Typography>
                    {isShowTimerExpiry && duration && (
                      <>
                        <Typography
                          className="regular16Heading"
                          style={{ color: "var(--Neutral-Grey-1000)" }}
                        >
                          <TranslateText label={"WITHIN"}>within</TranslateText>
                          <span
                            className="regular16Heading"
                            style={{
                              color: "var(--Red-900)",
                              fontWeight: 600,
                            }}
                          >
                            <ExpiryTimer
                              duration={duration}
                              callback={handleTimerExpire}
                            />
                          </span>
                        </Typography>
                      </>
                    )}
                  </div>
                )
              }

              {showOrderItem &&
                (sessionDetails?.paymentDetails?.order?.items?.length ||
                  sessionDetails?.paymentDetails?.product ||
                  sessionDetails?.description ||
                  sessionDetails?.paymentDetails?.context?.orderId ||
                  sessionDetails?.paymentDetails?.subscriptionDetails) && (
                  <OrderSummaryMobileContainer children={<OrderSummary />} />
                )}
              <PaymentSummaryMobileContainer children={<PaymentSummary />} />
              {isLoggedIn || guestCheckout || !showLogin ? (
                <>
                  <DeliveryAddress />
                </>
              ) : (
                <ShopperLoginMobileConatiner children={<ShopperLogin />} />
              )}

              {(isLoggedIn || guestCheckout || !showLogin) &&
                addressCollpase && (
                  <>
                    <PaymentOptions />
                    <PaymentButton />
                  </>
                )}
              {isLoggedIn && <LogoutMobile />}
              {isGuestLogedIn && <GuestLogoutMobile />}
              <BottomFooter />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MobileLayout;
