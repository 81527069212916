import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../locale/en/translation.json";
import translationKO from "../locale/ko/translation.json";
import translationZH from "../locale/zh/translation.json";
import translationVI from "../locale/vi/translation.json";
import translationID from "../locale/id/translation.json";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: translationEN
            },
            ko: {
                translation: translationKO
            },
            zh: {
                translation: translationZH
            },
            vi: {
                translation: translationVI
            },
            id: {
                translation: translationID
            },
        },
        lng: "en",
        // debug: true,
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n