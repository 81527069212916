import React from "react";
import MobilePaymentNavigation from "../../mobilePaymentNavigation/MobilePaymentNavigation";
import { PlusCircleOutlined } from "@ant-design/icons";
import CardPaymentOption from "../cardPaymentOption";
import { useTranslation } from "react-i18next";

export default function CardMobilePayment({ cardMethodInfo }) {
  const { t } = useTranslation();
  return (
    <>
      <MobilePaymentNavigation
        icon={<PlusCircleOutlined style={{ color: "#4F4D55" }} />}
        title={t("PAY_VIA_CARD")}
        subTitleStyling={true}
        children={<CardPaymentOption />}
        collapseKey={"Card"}
      />
    </>
  );
}
