import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as jose from "jose";
import { importKey } from "./importKey";
import { Input, Typography } from "antd";
import {
  cardCvcData,
  cardExpiryDateData,
  cardHolderNameData,
  cardNumberData,
} from "../slices/CardSlice";
import InnerHTML from "dangerously-set-html-content";
import { getPopDimensionDetails, isEmpty, isFireFox } from "./utils";
import { selectPopWindowIframeUpiIntent } from "../slices/PaymentButtonSlice";
import { useTranslation } from "react-i18next";

export default function PaymentResponseHandler({ isIframe, popWindowIframeRef }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const paymentInitiationLoading = useSelector((state) => state.paymentInitiation.paymentInitiationLoading);
  const [response, setResponse] = useState(undefined);
  const paymentInitiationData = useSelector((state) => state.paymentInitiation.paymentInitiationData);

  const popWindowIframeUpiIntent = useSelector((state) => selectPopWindowIframeUpiIntent(state));

  const cardNumber = useSelector((state) => cardNumberData(state));
  const cardHolderName = useSelector((state) => cardHolderNameData(state));
  const cardExpiryDate = useSelector((state) => cardExpiryDateData(state));
  const cardCvc = useSelector((state) => cardCvcData(state));
  const formDivRef = useRef();
  const formRef = useRef();
  const [processedResponse, setProcessedResponse] = useState();
  const isIphone = useSelector((state) => state.mobileLayout.isIphone);

  useEffect(() => {
    if (paymentInitiationData === undefined && response === undefined) {
      return;
    }
    if (paymentInitiationData !== response) {
      setResponse(paymentInitiationData);
    }
  }, [paymentInitiationData]);
  const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };
  useEffect(() => {
    (async () => {
      const encryptionWork = {};
      const encryptAction = response?.actions?.find(
        (action) => action.type === "encrypt"
      );
      if (encryptAction) {
        const publicKey = await importKey(encryptAction.encryptionKey);

        const [expiry_month, expiry_year] = cardExpiryDate.split("/");
        const cardNo = cardNumber?.replace(/\s/g, "");
        const cardDetails = {
          number: cardNo,
          expiry_month: expiry_month,
          expiry_year: `20${expiry_year}`,
          holder_name: cardHolderName,
          cvv: cardCvc,
        };

        const jwe = await new jose.CompactEncrypt(
          new TextEncoder().encode(JSON.stringify(cardDetails))
        )
          .setProtectedHeader({
            alg: encryptAction.algo,
            enc: "A128GCM",
            kid: encryptAction.keyId,
            "x5t#S256": encryptAction.certificateFingerprint,
          })
          .encrypt(publicKey);

        encryptionWork[encryptAction.outputField] = jwe;

        setProcessedResponse((prev) => {
          const newResponse = { ...response };
          newResponse.actions = response?.actions?.map((action) => {
            const newAction = { ...action };
            newAction.data = { ...action.data, ...encryptionWork };
            return newAction;
          });
          return newResponse;
        });
      } else {
        setProcessedResponse(response);
      }
    })();
  }, [response]);
  const isAutoSubmitForm = () => {
    if (isEmpty(processedResponse)) return
    let isHtml = processedResponse?.actions?.some(d => d.type === 'html');
    return isHtml;
  };
  const getPaymentCompletionUrl = (url) => {
    const isLocal = window.location.hostname === 'localhost';
    if (isLocal) {
      const localUrl = url.replace('https://test-checkout.boxpay.tech', `http://${window.location.host}`);
      return localUrl;
    }
    return url;
  }
  const getFormDataForRedirection = () => {
    let formArr = [];
    let actionData = processedResponse?.actions;

    if (isEmpty(actionData)) return formArr[0]
    actionData.forEach((action) => {
      if (action?.type === "redirect") {
        if (isValidUrl(action?.url)) {
          const formElement = document.createElement('form');
          formElement.method = action?.method;
          // formElement.action = getPaymentCompletionUrl(action?.url);
          formElement.action = action?.url;

          // add input
          Object.entries(addQueryParamsToObject(action?.url, action?.data)).forEach((entry, i) => {
            const input = document.createElement('input');
            input.key = i
            input.type = 'hidden';
            input.name = entry[0];
            input.value = entry[1];
            formElement.appendChild(input);
          })
          formArr.push(formElement)
        }
      } else if (action?.type === "html" && action?.htmlPageString !== undefined) {
        if (isIframe) {
          return formArr.push(action.htmlPageString)
        }
        if (document.getElementById("autosubmit-form") !== null) {
          formArr.push(document.getElementById("autosubmit-form"));
        }
        if (document.getElementById("payment_post") !== null) {
          formArr.push(document.getElementById("payment_post"));
        }
        if (document.getElementById("threedsChallengeRedirectForm") !== null) {
          formArr.push(document.getElementById("threedsChallengeRedirectForm"));
        }
      } else if (action?.type === "appRedirect" && !isEmpty(formRef.current)) {
        if (isIphone && !isEmpty(popWindowIframeUpiIntent)) {
          popWindowIframeUpiIntent.document.body.appendChild(formRef.current);
          formRef.current.submit();
        } else {
          formRef.current.submit()
        }
      }
    })
    return formArr[0];
  };
  const handleIframeEvents = () => {
    const { width, height, left, top } = getPopDimensionDetails();
    let redirectionForm = getFormDataForRedirection();
    if (!redirectionForm) return

    if (isEmpty(popWindowIframeRef.current)) {
      popWindowIframeRef.current = window.open('', 'FormPopup', `width=${width},height=${height},top=${top},left=${left}`);
    } else {

    }
    // check if the pop-up window is blocked in user's device
    // if (!popWindowIframeRef.current || popWindowIframeRef.current.closed || typeof popWindowIframeRef.current.closed === 'undefined') {
    //   alert('Popup blocked. Please allow popups for this site.-');
    //   return;
    // }

    if (redirectionForm && !isAutoSubmitForm()) {
      popWindowIframeRef.current.document.body.appendChild(redirectionForm);
      redirectionForm.submit();
    } else {
      const iframeDocument = popWindowIframeRef.current.document;
      iframeDocument.open();
      iframeDocument.write(redirectionForm);
      iframeDocument.close();
      const form = iframeDocument.querySelector('form');
      if (form) {
        form.submit();
      }
    }
  };

  useEffect(() => {
    console.log("-", formRef.current);
    if (isEmpty(processedResponse)) return

    // handle UPI intent status
    // if (processedResponse.paymentMethod.brand === 'UpiIntent') {
    //   window.addEventListener('blur', handleBlurEvent)
    // }

    // handling redirection forms in Iframe
    if (isIframe && processedResponse !== undefined) {
      return handleIframeEvents();
    }
    // normal flow of payment redirection
    let redirectionForm = getFormDataForRedirection();

    if (processedResponse !== undefined && redirectionForm?.submit !== undefined) {
      formDivRef.current.appendChild(redirectionForm)
      redirectionForm.submit();
    }
    if (processedResponse !== undefined && formRef.current) {
      formRef.current.submit();
    }

    // this is needed as html react parser doesn't execute javascript
    if (document.getElementById("autosubmit-form") !== null) {
      document.getElementById("autosubmit-form").submit();
    }
    if (document.getElementById("payment_post") !== null) {
      document.getElementById("payment_post").submit();
    }
    if (document.getElementById("threedsChallengeRedirectForm") !== null) {
      document.getElementById("threedsChallengeRedirectForm").submit();
    }
  }, [processedResponse]);
  const addQueryParamsToObject = (url, data) => {
    if (data === undefined) {
      data = {};
    } else {
      data = JSON.parse(JSON.stringify(data));
    }

    if (url.includes("?")) {
      var queryString = url.split("?")[1];

      // Split the query string into an array of key-value pairs
      var params = queryString.split("&");

      // Loop through each key-value pair
      params.forEach(function (param) {
        var pair = param.split("=");
        var key = decodeURIComponent(pair[0]);
        var value = decodeURIComponent(pair[1] || "");

        // Add the key-value pair to the paramsObject
        data[key] = value;
      });
    }

    return data;
  };
  return (
    <>
      {processedResponse?.actions &&
        processedResponse?.actions?.length > 0 &&
        processedResponse?.actions?.filter((action) => action?.type === "html" && action?.htmlPageString !== undefined).map((action, i) => {
          return (
            isIframe ? (<></>) : (
              // isAutoSubmitForm() && isIframe ? (<></>) : (
              <InnerHTML
                key={`html-${i}`}
                id="dynamic-html-container"
                html={action.htmlPageString}
              />
            )
          );
        })}

      <div ref={formDivRef} />

      {response?.actions?.filter((action) => action?.type === "appRedirect").map((action) => {
        const appUrl = atob(action?.url);
        return (
          <form
            key={appUrl}
            method={action?.method}
            ref={formRef}
            action={appUrl}
          >
            {Object.entries(addQueryParamsToObject(appUrl, action?.data)).map((entry) => {
              return (
                <Input
                  key={entry[0]}
                  name={entry[0]}
                  value={entry[1]}
                  type="hidden"
                />
              );
            }
            )}
          </form>
        );
      })}
    </>
  );
}
