import React from 'react';
import { useTranslation } from "react-i18next";

const TranslateText = ({ label, children }) => {
    const { t, i18n } = useTranslation()
    const translationExists = i18n.exists(label);

    // If translation exists, use it; otherwise, use the children as fallback
    const translatedText = translationExists ? t(label) : children;
    return (
        <>{t(translatedText)}</>
    )
}

export default TranslateText;