import React, { useState } from "react";
import "./logoutMobile.css";
import Typography from "antd/es/typography/Typography";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TranslateText from "../../resuableComponents/TranslateText";
import {
  resetShopperInfo,
  selectMobileNumber,
} from "../../../slices/ShopperInfoSlice";
import { generateDynamicLogo } from "../../../Utils/utils";
import {
  removeUpdatedShopperDetails,
  selectBaseColor,
} from "../../../slices/PaymentSessionDetailsSlice";
import ErrorModal from "../modals/errorModal/ErrorModal";
import { CloseCircleOutlined } from "@ant-design/icons";
import { selectSavedAddresses } from "../../../slices/SavedAddressSlice";

const LogoutMobile = () => {
  const { t } = useTranslation();
  const mobileNumber = useSelector((state) => selectMobileNumber(state));
  const baseColor = useSelector((state) => selectBaseColor(state));
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const savedAddresses = useSelector((state) => selectSavedAddresses(state));
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(resetShopperInfo());
    dispatch(removeUpdatedShopperDetails());
    sessionStorage.removeItem("latestSaved");
    setOpenLogoutModal(false);
  };

  return (
    <div className="domestic-logout-root dynamic-font-styling">
      <ErrorModal
        icon={
          <CloseCircleOutlined style={{ color: "#FAAD14", fontSize: "22px" }} />
        }
        title={`${("CONFIRM_LOGOUT")}?`}
        desc={`${("SESSION_END_WARNING")}.`}
        showModal={openLogoutModal}
        primaryOnClick={() => handleLogout()}
        secondaryOnClick={() => setOpenLogoutModal(false)}
        primaryBtnText={t("YES_LOGOUT")}
        secondaryBtnText={t("NO")}
        showSecondaryButton={true}
      />
      {mobileNumber && (
        <Typography
          className="regular12Heading"
          style={{ color: "var(--Neutral-Grey-1000)" }}
        >
          <TranslateText label={"SHOWING_DETAILS_FOR"}>Showing details for</TranslateText>
          <span className="semiBold12Heading">{mobileNumber}</span>
        </Typography>
      )}
      <div
        className="domestic-mb-logout"
        onClick={() => setOpenLogoutModal(true)}
      >
        {generateDynamicLogo("logout", "12px", "12px", baseColor)}
        <Typography className="dynamic-title semiBold12Heading">
          <TranslateText label={"LOGOUT"}>Logout</TranslateText>
        </Typography>
      </div>
    </div>
  );
};

export default LogoutMobile;
