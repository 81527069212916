import React, { useEffect, useState } from "react";
import "./paymentSuccess.css";
import { Divider, Skeleton, Typography } from "antd";
import Lottie from "lottie-react";
import animationData from "../lotties/successLogo.json";
import dateFormat from "dateformat";
import { useDispatch, useSelector } from "react-redux";
import { generateDynamicStyling, getStroage, isEmpty, numberToCurrency } from "../../../Utils/utils";
import { getTransactionStatus, selectTransactionData } from "../../../slices/TransactionIdSlice";
import TranslateText from "../../resuableComponents/TranslateText";
import { selectFrontReturnUrl } from "../../../slices/PaymentSessionDetailsSlice";
import { useTranslation } from "react-i18next";

const PaymentSuccess = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isDccQuotationSelected } = useSelector((state) => state.cardDetails);
  const { dccQuotation, cardHolderName, dccCardScheme } = useSelector(state => state.cardDetails);
  const { brand, baseMoney } = dccQuotation;
  const merchantCurrencyCode = baseMoney?.currencyCode;
  const merchantCurrencyAmount = baseMoney?.amount;
  const { dccMoney, fxRate, marginPercent } = dccQuotation?.dccQuotationDetails || {};
  const issuerCurrencyCode = dccMoney?.currencyCode;
  const issuerCurrencyAmount = dccMoney?.amount;
  const paymentSuccessData = useSelector((state) => selectTransactionData(state));
  const sessionDetails = getStroage("session", "s_d");
  const transactionId = getStroage("session", "t_id") || getStroage("session", "s_d")?.lastTransactionId;
  const token = getStroage("session", "tkn");
  const [paymentSuccessDetails, setPaymentSuccessDetails] = useState(null);
  const [showOnlyText, setShowOnlyText] = useState();
  const frontReturnUrl = useSelector((state) => selectFrontReturnUrl(state));

  generateDynamicStyling(sessionDetails);
  useEffect(() => {
    if (token && transactionId) {
      dispatch(getTransactionStatus({ token, transactionId }));
      setShowOnlyText(false);
    } else {
      setShowOnlyText(true);
    }
  }, [token, transactionId]);

  useEffect(() => {
    if (paymentSuccessData) {
      let paymentSummaryDictionary = [
        {
          title: t("TRANSACTION_ID"),
          value: paymentSuccessData?.transactionId,
          show: true
        },
        {
          title: t("CARD_TYPE"),
          value: brand,
          show: isDccQuotationSelected
        },
        {
          title: t("CARDHOLDER_NAME"),
          value: cardHolderName,
          show: isDccQuotationSelected
        },
        {
          title: `${t("TRANSACTION_TOTAL")} ${merchantCurrencyCode}`,
          value: `${merchantCurrencyCode} ${merchantCurrencyAmount}`,
          show: isDccQuotationSelected
        },
        {
          title: t("EXCHANGE_RATE"),
          value: `1 ${merchantCurrencyCode} = ${fxRate?.toFixed(2)} ${issuerCurrencyCode}`,
          show: isDccQuotationSelected
        },
        {
          title: t("MARGIN"),
          value: marginPercent + "%",
          show: isDccQuotationSelected && dccCardScheme === 'VISA'
        },
        {
          title: t("TRANSACTION_CURRENCY"),
          value: issuerCurrencyCode,
          show: isDccQuotationSelected
        },
        {
          title: t("STATUS"),
          value: paymentSuccessData?.status,
          show: true
        },
        {
          title: t("DATE_AND_TIME"),
          value: dateFormat(
            paymentSuccessData?.transactionTimestamp,
            "mmmm dS, yyyy, h:MM:ss TT"
          ),
          show: true
        },
      ];
      setPaymentSuccessDetails(paymentSummaryDictionary);
    }
  }, [paymentSuccessData]);

  const DccAmount = () => {
    return (
      <div className="domestic-s-bold-text">
        <span>{issuerCurrencyCode} </span>
        <span>{issuerCurrencyAmount}</span>
      </div>
    )
  }

  return (
    <div style={{ minHeight: "calc(100vh - 72px)", display: "flex" }}>
      {showOnlyText === undefined ? (
        <></>
      ) : (
        <>
          {
            !showOnlyText ? (
              <div className="domestic-s-root dynamic-font-styling">
                <div className="domestic-s-header">
                  <Lottie
                    animationData={animationData}
                    loop={true}
                    autoplay={true}
                    style={{ width: "70px", height: "70px" }}
                    speed={4.5}
                  />
                  <Typography
                    className="semiBold24Heading dynamic-font-styling"
                    style={{
                      padding: showOnlyText && "0px 40px",
                      textAlign: "center",
                    }}
                  >
                    {showOnlyText
                      ? <TranslateText label={"PAYMENT_ALREADY_COMPLETED"}>Payment has already been completed on this link</TranslateText>
                      : (
                        <>
                          <TranslateText label={"PAYMENT_SUCCESSFUL"}>Payment Successful</TranslateText>!
                          {
                            isDccQuotationSelected && (<DccAmount />)
                          }
                        </>
                      )
                    }
                  </Typography>
                </div>
                <div className="domestic-s-divider-root">
                  <div className="domestic-s-cut-1"></div>
                  <div className="domestic-s-divider"></div>
                  <div className="domestic-s-cut-2"></div>
                </div>
                <div className="domestic-s-summary dynamic-font-styling">
                  {paymentSuccessDetails ? paymentSuccessDetails?.map((data, i) => data.show && (
                    <div className="domestic-s-summary-item" key={`details-${i}`}>
                      <Typography className="domestic-s-summary-item-title">
                        {data?.title}
                      </Typography>
                      <Typography className="domestic-s-summary-item-value">
                        {data?.value}
                      </Typography>
                    </div>
                  ))
                    : [...Array(3)].map((_, i) => (
                      <Skeleton.Button active size="small" block key={i} />
                    ))}
                  <div className="domestic-s-divider-2"></div>
                  <div className="domestic-s-summary-item dynamic-font-styling">
                    {paymentSuccessData ? (
                      <>
                        <Typography className="domestic-s-summary-item-title">
                          <TranslateText label={"TOTAL_AMOUNT"}>Total Amount</TranslateText>
                        </Typography>

                        {
                          isDccQuotationSelected ? ((<DccAmount />)) : (
                            <Typography className="domestic-s-amount domestic-s-bold-text">
                              <span>{paymentSuccessData?.currencyCode} </span>{" "}
                              {numberToCurrency(paymentSuccessData?.amount, paymentSuccessData?.currencyCode)}
                            </Typography>
                          )
                        }
                      </>
                    ) : (
                      <Skeleton.Button active size="small" block />
                    )}
                  </div>
                  {
                    isDccQuotationSelected && (
                      <>
                        <div className="domestic-s-divider-2"></div>
                        <div>
                          {
                            dccCardScheme === 'VISA' && (
                              <Typography className="domestic-s-summary-item-title domestic-s-summary-margin domestic-s-bold-text">
                                <TranslateText label={"CURRENCY_CHOICE_OFFERED"}> I have been offered a choice of currencies and agreed to pay in </TranslateText>
                                <span className="currency-span">{issuerCurrencyCode}.</span>
                                <TranslateText label={"CURRENCY_CONVERSION_SERVICE_PROVIDER"}> This currency conversion service is provided by Fexco</TranslateText>.
                              </Typography>
                            )
                          }

                          <div className="domestic-s-print-text">
                            <Typography className="domestic-s-summary-item-title domestic-s-bold-text">
                              <TranslateText label={"PRINT_AND_RETAIN_RECORDS"}>Please print and retain for your records</TranslateText>
                            </Typography>
                          </div>
                        </div>
                      </>
                    )
                  }

                </div>
                <div className="domestic-s-footer-divider">
                  <Divider />
                </div>

                <div className="domestic-s-footer dynamic-font-styling">

                  {
                    isDccQuotationSelected && !isEmpty(frontReturnUrl) ? (
                      <>
                        <Typography className="domestic-s-summary-item-title dynamic-font-styling">
                          <TranslateText label={"REDIRECT_BACK"}>Redirect back to the </TranslateText>
                          <a href={frontReturnUrl} className="domestic-s-merchant-site-link">
                            <TranslateText label={"MERCHANT_SITE"}> merchant's site</TranslateText>
                          </a>
                        </Typography>
                      </>
                    ) : (
                      <Typography className="domestic-s-summary-item-title dynamic-font-styling">
                        <TranslateText label={"CLOSE_THE_WINDOW"}>You may close the window.</TranslateText>.
                      </Typography>
                    )
                  }

                </div>
              </div>
            ) : (
              <div
                style={{
                  margin: "auto",
                }}
              >
                <div style={{ width: "258px" }}>
                  <img
                    src="/assets/errorImage/oppsImage.svg"
                    style={{ width: "calc(166 / 161 * 100%)" }}
                  />
                </div>
                <Typography className="oppsHeading">
                  <TranslateText label={"OOPS"}>Oops</TranslateText>!! </Typography>
                <Typography className="oppsSubHeading">
                  <TranslateText label={"SEEMS_LIKE_LOST"}>Seems like you are lost</TranslateText>.
                </Typography>
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default PaymentSuccess;
