import { Modal, Typography } from "antd";
import React from "react";
import CustomButton from "../../../resuableComponents/CustomButton";
import "./errorModal.css";

const ErrorModal = ({
  icon,
  title,
  desc,
  showModal,
  primaryOnClick,
  secondaryOnClick,
  primaryBtnText,
  secondaryBtnText,
  showSecondaryButton,
}) => {
  return (
    <Modal
      centered
      open={showModal}
      footer={null}
      closable={false}
      width={"416px"}
    >
      <div className="domestic-error-root">
        <div className="domestic-error-header-conatiner">
          <div className="domestic-error-header-logo">{icon}</div>
          <div className="domestic-error-header-content dynamic-font-styling">
            <Typography className="domestic-error-header-title">
              {title}
            </Typography>
            <Typography
              className="domestic-error-header-desc"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {desc}
            </Typography>
          </div>
        </div>
        <div className="domestic-error-footer">
          {showSecondaryButton && (
            <CustomButton
              buttonType={"primary-btn"}
              containerType={""}
              padding={"5px 15px"}
              onClick={secondaryOnClick}
              text={secondaryBtnText}
            />
          )}
          <CustomButton
            text={primaryBtnText}
            buttonType={"primary-btn"}
            padding={"5px 15px"}
            onClick={primaryOnClick}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
