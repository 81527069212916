export const CountryData = [
  {
    name: "Afghanistan",
    dial_code: "+93",
    code: "AF",
    currency: "AFN",
    flag: "https://flagcdn.com/af.svg"
  },
  {
    name: "Aland Islands",
    dial_code: "+358",
    code: "AX",
    currency: "EUR",
    flag: "https://flagcdn.com/ax.svg"
  },
  {
    name: "Albania",
    dial_code: "+355",
    code: "AL",
    currency: "ALL",
    flag: "https://flagcdn.com/al.svg"
  },
  {
    name: "Algeria",
    dial_code: "+213",
    code: "DZ",
    currency: "DZD",
    flag: "https://flagcdn.com/dz.svg"
  },
  {
    name: "AmericanSamoa",
    dial_code: "+1684",
    code: "AS",
    currency: "USD",
    flag: "https://flagcdn.com/as.svg"
  },
  {
    name: "Andorra",
    dial_code: "+376",
    code: "AD",
    currency: "EUR",
    flag: "https://flagcdn.com/ad.svg"
  },
  {
    name: "Angola",
    dial_code: "+244",
    code: "AO",
    currency: "AOA",
    flag: "https://flagcdn.com/ao.svg"
  },
  {
    name: "Anguilla",
    dial_code: "+1264",
    code: "AI",
    currency: "XCD",
    flag: "https://flagcdn.com/ai.svg"
  },
  {
    name: "Antarctica",
    dial_code: "+672",
    code: "AQ",
    currency: "",
    flag: "https://flagcdn.com/aq.svg"
  },
  {
    name: "Antigua and Barbuda",
    dial_code: "+1268",
    code: "AG",
    currency: "XCD",
    flag: "https://flagcdn.com/ag.svg"
  },
  {
    name: "Argentina",
    dial_code: "+54",
    code: "AR",
    currency: "ARS",
    flag: "https://flagcdn.com/ar.svg"
  },
  {
    name: "Armenia",
    dial_code: "+374",
    code: "AM",
    currency: "AMD",
    flag: "https://flagcdn.com/am.svg"
  },
  {
    name: "Aruba",
    dial_code: "+297",
    code: "AW",
    currency: "AWG",
    flag: "https://flagcdn.com/aw.svg"
  },
  {
    name: "Australia",
    dial_code: "+61",
    code: "AU",
    currency: "AUD",
    flag: "https://flagcdn.com/au.svg"
  },
  {
    name: "Austria",
    dial_code: "+43",
    code: "AT",
    currency: "EUR",
    flag: "https://flagcdn.com/at.svg"
  },
  {
    name: "Azerbaijan",
    dial_code: "+994",
    code: "AZ",
    currency: "AZN",
    flag: "https://flagcdn.com/az.svg"
  },
  {
    name: "Bahamas",
    dial_code: "+1242",
    code: "BS",
    currency: "BSD",
    flag: "https://flagcdn.com/bs.svg"
  },
  {
    name: "Bahrain",
    dial_code: "+973",
    code: "BH",
    currency: "BHD",
    flag: "https://flagcdn.com/bh.svg"
  },
  {
    name: "Bangladesh",
    dial_code: "+880",
    code: "BD",
    currency: "BDT",
    flag: "https://flagcdn.com/bd.svg"
  },
  {
    name: "Barbados",
    dial_code: "+1246",
    code: "BB",
    currency: "BBD",
    flag: "https://flagcdn.com/bb.svg"
  },
  {
    name: "Belarus",
    dial_code: "+375",
    code: "BY",
    currency: "BYR",
    flag: "https://flagcdn.com/by.svg"
  },
  {
    name: "Belgium",
    dial_code: "+32",
    code: "BE",
    currency: "EUR",
    flag: "https://flagcdn.com/be.svg"
  },
  {
    name: "Belize",
    dial_code: "+501",
    code: "BZ",
    currency: "BZD",
    flag: "https://flagcdn.com/bz.svg"
  },
  {
    name: "Benin",
    dial_code: "+229",
    code: "BJ",
    currency: "XOF",
    flag: "https://flagcdn.com/bj.svg"
  },
  {
    name: "Bermuda",
    dial_code: "+1441",
    code: "BM",
    currency: "BMD",
    flag: "https://flagcdn.com/bm.svg"
  },
  {
    name: "Bhutan",
    dial_code: "+975",
    code: "BT",
    currency: "BTN",
    flag: "https://flagcdn.com/bt.svg"
  },
  {
    name: "Bolivia, Plurinational State of",
    dial_code: "+591",
    code: "BO",
    currency: "BOB",
    flag: "https://flagcdn.com/bo.svg"
  },
  {
    name: "Bosnia and Herzegovina",
    dial_code: "+387",
    code: "BA",
    currency: "BAM",
    flag: "https://flagcdn.com/ba.svg"
  },
  {
    name: "Botswana",
    dial_code: "+267",
    code: "BW",
    currency: "BWP",
    flag: "https://flagcdn.com/bw.svg"
  },
  {
    name: "Brazil",
    dial_code: "+55",
    code: "BR",
    currency: "BRL",
    flag: "https://flagcdn.com/br.svg"
  },
  {
    name: "British Indian Ocean Territory",
    dial_code: "+246",
    code: "IO",
    currency: "USD",
    flag: "https://flagcdn.com/io.svg"
  },
  {
    name: "Brunei Darussalam",
    dial_code: "+673",
    code: "BN",
    currency: "BND",
    flag: "https://flagcdn.com/bn.svg"
  },
  {
    name: "Bulgaria",
    dial_code: "+359",
    code: "BG",
    currency: "BGN",
    flag: "https://flagcdn.com/bg.svg"
  },
  {
    name: "Burkina Faso",
    dial_code: "+226",
    code: "BF",
    currency: "XOF",
    flag: "https://flagcdn.com/bf.svg"
  },
  {
    name: "Burundi",
    dial_code: "+257",
    code: "BI",
    currency: "BIF",
    flag: "https://flagcdn.com/bi.svg"
  },
  {
    name: "Cambodia",
    dial_code: "+855",
    code: "KH",
    currency: "KHR",
    flag: "https://flagcdn.com/kh.svg"
  },
  {
    name: "Cameroon",
    dial_code: "+237",
    code: "CM",
    currency: "XAF",
    flag: "https://flagcdn.com/cm.svg"
  },
  {
    name: "Canada",
    dial_code: "+1",
    code: "CA",
    currency: "CAD",
    flag: "https://flagcdn.com/ca.svg"
  },
  {
    name: "Cape Verde",
    dial_code: "+238",
    code: "CV",
    currency: "CVE",
    flag: "https://flagcdn.com/cv.svg"
  },
  {
    name: "Cayman Islands",
    dial_code: "+ 345",
    code: "KY",
    currency: "KYD",
    flag: "https://flagcdn.com/ky.svg"
  },
  {
    name: "Central African Republic",
    dial_code: "+236",
    code: "CF",
    currency: "XAF",
    flag: "https://flagcdn.com/cf.svg"
  },
  {
    name: "Chad",
    dial_code: "+235",
    code: "TD",
    currency: "XAF",
    flag: "https://flagcdn.com/td.svg"
  },
  {
    name: "Chile",
    dial_code: "+56",
    code: "CL",
    currency: "CLP",
    flag: "https://flagcdn.com/cl.svg"
  },
  {
    name: "China",
    dial_code: "+86",
    code: "CN",
    currency: "CNY",
    flag: "https://flagcdn.com/cn.svg"
  },
  {
    name: "Christmas Island",
    dial_code: "+61",
    code: "CX",
    currency: "AUD",
    flag: "https://flagcdn.com/cx.svg"
  },
  {
    name: "Cocos (Keeling) Islands",
    dial_code: "+61",
    code: "CC",
    currency: "AUD",
    flag: "https://flagcdn.com/cc.svg"
  },
  {
    name: "Colombia",
    dial_code: "+57",
    code: "CO",
    currency: "COP",
    flag: "https://flagcdn.com/co.svg"
  },
  {
    name: "Comoros",
    dial_code: "+269",
    code: "KM",
    currency: "KMF",
    flag: "https://flagcdn.com/km.svg"
  },
  {
    name: "Congo",
    dial_code: "+242",
    code: "CG",
    currency: "XAF",
    flag: "https://flagcdn.com/cg.svg"
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    dial_code: "+243",
    code: "CD",
    currency: "CDF",
    flag: "https://flagcdn.com/cd.svg"
  },
  {
    name: "Cook Islands",
    dial_code: "+682",
    code: "CK",
    currency: "NZD",
    flag: "https://flagcdn.com/ck.svg"
  },
  {
    name: "Costa Rica",
    dial_code: "+506",
    code: "CR",
    currency: "CRC",
    flag: "https://flagcdn.com/cr.svg"
  },
  {
    name: "Cote d'Ivoire",
    dial_code: "+225",
    code: "CI",
    currency: "XOF",
    flag: "https://flagcdn.com/ci.svg"
  },
  {
    name: "Croatia",
    dial_code: "+385",
    code: "HR",
    currency: "HRK",
    flag: "https://flagcdn.com/hr.svg"
  },
  {
    name: "Cuba",
    dial_code: "+53",
    code: "CU",
    currency: "CUP",
    flag: "https://flagcdn.com/cu.svg"
  },
  {
    name: "Cyprus",
    dial_code: "+357",
    code: "CY",
    currency: "EUR",
    flag: "https://flagcdn.com/cy.svg"
  },
  {
    name: "Czech Republic",
    dial_code: "+420",
    code: "CZ",
    currency: "CZK",
    flag: "https://flagcdn.com/cz.svg"
  },
  {
    name: "Denmark",
    dial_code: "+45",
    code: "DK",
    currency: "DKK",
    flag: "https://flagcdn.com/dk.svg"
  },
  {
    name: "Djibouti",
    dial_code: "+253",
    code: "DJ",
    currency: "DJF",
    flag: "https://flagcdn.com/dj.svg"
  },
  {
    name: "Dominica",
    dial_code: "+1767",
    code: "DM",
    currency: "XCD",
    flag: "https://flagcdn.com/dm.svg"
  },
  {
    name: "Dominican Republic",
    dial_code: "+1849",
    code: "DO",
    currency: "DOP",
    flag: "https://flagcdn.com/do.svg"
  },
  {
    name: "Ecuador",
    dial_code: "+593",
    code: "EC",
    currency: "USD",
    flag: "https://flagcdn.com/ec.svg"
  },
  {
    name: "Egypt",
    dial_code: "+20",
    code: "EG",
    currency: "EGP",
    flag: "https://flagcdn.com/eg.svg"
  },
  {
    name: "El Salvador",
    dial_code: "+503",
    code: "SV",
    currency: "USD",
    flag: "https://flagcdn.com/sv.svg"
  },
  {
    name: "Equatorial Guinea",
    dial_code: "+240",
    code: "GQ",
    currency: "XAF",
    flag: "https://flagcdn.com/gq.svg"
  },
  {
    name: "Eritrea",
    dial_code: "+291",
    code: "ER",
    currency: "ERN",
    flag: "https://flagcdn.com/er.svg"
  },
  {
    name: "Estonia",
    dial_code: "+372",
    code: "EE",
    currency: "EUR",
    flag: "https://flagcdn.com/ee.svg"
  },
  {
    name: "Ethiopia",
    dial_code: "+251",
    code: "ET",
    currency: "ETB",
    flag: "https://flagcdn.com/et.svg"
  },
  {
    name: "Falkland Islands (Malvinas)",
    dial_code: "+500",
    code: "FK",
    currency: "FKP",
    flag: "https://flagcdn.com/fk.svg"
  },
  {
    name: "Faroe Islands",
    dial_code: "+298",
    code: "FO",
    currency: "DKK",
    flag: "https://flagcdn.com/fo.svg"
  },
  {
    name: "Fiji",
    dial_code: "+679",
    code: "FJ",
    currency: "FJD",
    flag: "https://flagcdn.com/fj.svg"
  },
  {
    name: "Finland",
    dial_code: "+358",
    code: "FI",
    currency: "EUR",
    flag: "https://flagcdn.com/fi.svg"
  },
  {
    name: "France",
    dial_code: "+33",
    code: "FR",
    currency: "EUR",
    flag: "https://flagcdn.com/fr.svg"
  },
  {
    name: "French Guiana",
    dial_code: "+594",
    code: "GF",
    currency: "EUR",
    flag: "https://flagcdn.com/gf.svg"
  },
  {
    name: "French Polynesia",
    dial_code: "+689",
    code: "PF",
    currency: "XPF",
    flag: "https://flagcdn.com/pf.svg"
  },
  {
    name: "Gabon",
    dial_code: "+241",
    code: "GA",
    currency: "XAF",
    flag: "https://flagcdn.com/ga.svg"
  },
  {
    name: "Gambia",
    dial_code: "+220",
    code: "GM",
    currency: "GMD",
    flag: "https://flagcdn.com/gm.svg"
  },
  {
    name: "Georgia",
    dial_code: "+995",
    code: "GE",
    currency: "GEL",
    flag: "https://flagcdn.com/ge.svg"
  },
  {
    name: "Germany",
    dial_code: "+49",
    code: "DE",
    currency: "EUR",
    flag: "https://flagcdn.com/de.svg"
  },
  {
    name: "Ghana",
    dial_code: "+233",
    code: "GH",
    currency: "GHS",
    flag: "https://flagcdn.com/gh.svg"
  },
  {
    name: "Gibraltar",
    dial_code: "+350",
    code: "GI",
    currency: "GIP",
    flag: "https://flagcdn.com/gi.svg"
  },
  {
    name: "Greece",
    dial_code: "+30",
    code: "GR",
    currency: "EUR",
    flag: "https://flagcdn.com/gr.svg"
  },
  {
    name: "Greenland",
    dial_code: "+299",
    code: "GL",
    currency: "DKK",
    flag: "https://flagcdn.com/gl.svg"
  },
  {
    name: "Grenada",
    dial_code: "+1473",
    code: "GD",
    currency: "XCD",
    flag: "https://flagcdn.com/gd.svg"
  },
  {
    name: "Guadeloupe",
    dial_code: "+590",
    code: "GP",
    currency: "EUR",
    flag: "https://flagcdn.com/gp.svg"
  },
  {
    name: "Guam",
    dial_code: "+1671",
    code: "GU",
    currency: "USD",
    flag: "https://flagcdn.com/gu.svg"
  },
  {
    name: "Guatemala",
    dial_code: "+502",
    code: "GT",
    currency: "GTQ",
    flag: "https://flagcdn.com/gt.svg"
  },
  {
    name: "Guernsey",
    dial_code: "+44",
    code: "GG",
    currency: "GBP",
    flag: "https://flagcdn.com/gg.svg"
  },
  {
    name: "Guinea",
    dial_code: "+224",
    code: "GN",
    currency: "GNF",
    flag: "https://flagcdn.com/gn.svg"
  },
  {
    name: "Guinea-Bissau",
    dial_code: "+245",
    code: "GW",
    currency: "XOF",
    flag: "https://flagcdn.com/gw.svg"
  },
  {
    name: "Guyana",
    dial_code: "+595",
    code: "GY",
    currency: "GYD",
    flag: "https://flagcdn.com/gy.svg"
  },
  {
    name: "Haiti",
    dial_code: "+509",
    code: "HT",
    currency: "HTG",
    flag: "https://flagcdn.com/ht.svg"
  },
  {
    name: "Holy See (Vatican City State)",
    dial_code: "+379",
    code: "VA",
    currency: "EUR",
    flag: "https://flagcdn.com/va.svg"
  },
  {
    name: "Honduras",
    dial_code: "+504",
    code: "HN",
    currency: "HNL",
    flag: "https://flagcdn.com/hn.svg"
  },
  {
    name: "Hong Kong",
    dial_code: "+852",
    code: "HK",
    currency: "HKD",
    flag: "https://flagcdn.com/hk.svg"
  },
  {
    name: "Hungary",
    dial_code: "+36",
    code: "HU",
    currency: "HUF",
    flag: "https://flagcdn.com/hu.svg"
  },
  {
    name: "Iceland",
    dial_code: "+354",
    code: "IS",
    currency: "ISK",
    flag: "https://flagcdn.com/is.svg"
  },
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
    currency: "INR",
    flag: "https://flagcdn.com/in.svg"
  },
  {
    name: "Indonesia",
    dial_code: "+62",
    code: "ID",
    currency: "IDR",
    flag: "https://flagcdn.com/id.svg"
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    dial_code: "+98",
    code: "IR",
    currency: "IRR",
    flag: "https://flagcdn.com/ir.svg"
  },
  {
    name: "Iraq",
    dial_code: "+964",
    code: "IQ",
    currency: "IQD",
    flag: "https://flagcdn.com/iq.svg"
  },
  {
    name: "Ireland",
    dial_code: "+353",
    code: "IE",
    currency: "EUR",
    flag: "https://flagcdn.com/ie.svg"
  },
  {
    name: "Isle of Man",
    dial_code: "+44",
    code: "IM",
    currency: "GBP",
    flag: "https://flagcdn.com/im.svg"
  },
  {
    name: "Israel",
    dial_code: "+972",
    code: "IL",
    currency: "ILS",
    flag: "https://flagcdn.com/il.svg"
  },
  {
    name: "Italy",
    dial_code: "+39",
    code: "IT",
    currency: "EUR",
    flag: "https://flagcdn.com/it.svg"
  },
  {
    name: "Jamaica",
    dial_code: "+1876",
    code: "JM",
    currency: "JMD",
    flag: "https://flagcdn.com/jm.svg"
  },
  {
    name: "Japan",
    dial_code: "+81",
    code: "JP",
    currency: "JPY",
    flag: "https://flagcdn.com/jp.svg"
  },
  {
    name: "Jersey",
    dial_code: "+44",
    code: "JE",
    currency: "GBP",
    flag: "https://flagcdn.com/je.svg"
  },
  {
    name: "Jordan",
    dial_code: "+962",
    code: "JO",
    currency: "JOD",
    flag: "https://flagcdn.com/jo.svg"
  },
  {
    name: "Kazakhstan",
    dial_code: "+77",
    code: "KZ",
    currency: "KZT",
    flag: "https://flagcdn.com/kz.svg"
  },
  {
    name: "Kenya",
    dial_code: "+254",
    code: "KE",
    currency: "KES",
    flag: "https://flagcdn.com/ke.svg"
  },
  {
    name: "Kiribati",
    dial_code: "+686",
    code: "KI",
    currency: "AUD",
    flag: "https://flagcdn.com/ki.svg"
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: "+850",
    code: "KP",
    currency: "KPW",
    flag: "https://flagcdn.com/kp.svg"
  },
  {
    name: "Korea, Republic of South Korea",
    dial_code: "+82",
    code: "KR",
    currency: "KRW",
    flag: "https://flagcdn.com/kr.svg"
  },
  {
    name: "Kuwait",
    dial_code: "+965",
    code: "KW",
    currency: "KWD",
    flag: "https://flagcdn.com/kw.svg"
  },
  {
    name: "Kyrgyzstan",
    dial_code: "+996",
    code: "KG",
    currency: "KGS",
    flag: "https://flagcdn.com/kg.svg"
  },
  {
    name: "Laos",
    dial_code: "+856",
    code: "LA",
    currency: "LAK",
    flag: "https://flagcdn.com/la.svg"
  },
  {
    name: "Latvia",
    dial_code: "+371",
    code: "LV",
    currency: "EUR",
    flag: "https://flagcdn.com/lv.svg"
  },
  {
    name: "Lebanon",
    dial_code: "+961",
    code: "LB",
    currency: "LBP",
    flag: "https://flagcdn.com/lb.svg"
  },
  {
    name: "Lesotho",
    dial_code: "+266",
    code: "LS",
    currency: "LSL",
    flag: "https://flagcdn.com/ls.svg"
  },
  {
    name: "Liberia",
    dial_code: "+231",
    code: "LR",
    currency: "LRD",
    flag: "https://flagcdn.com/lr.svg"
  },
  {
    name: "Libyan Arab Jamahiriya",
    dial_code: "+218",
    code: "LY",
    currency: "LYD",
    flag: "https://flagcdn.com/ly.svg"
  },
  {
    name: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
    currency: "CHF",
    flag: "https://flagcdn.com/li.svg"
  },
  {
    name: "Lithuania",
    dial_code: "+370",
    code: "LT",
    currency: "EUR",
    flag: "https://flagcdn.com/lt.svg"
  },
  {
    name: "Luxembourg",
    dial_code: "+352",
    code: "LU",
    currency: "EUR",
    flag: "https://flagcdn.com/lu.svg"
  },
  {
    name: "Macao",
    dial_code: "+853",
    code: "MO",
    currency: "MOP",
    flag: "https://flagcdn.com/mo.svg"
  },
  {
    name: "Macedonia",
    dial_code: "+389",
    code: "MK",
    currency: "MKD",
    flag: "https://flagcdn.com/mk.svg"
  },
  {
    name: "Madagascar",
    dial_code: "+261",
    code: "MG",
    currency: "MGA",
    flag: "https://flagcdn.com/mg.svg"
  },
  {
    name: "Malawi",
    dial_code: "+265",
    code: "MW",
    currency: "MWK",
    flag: "https://flagcdn.com/mw.svg"
  },
  {
    name: "Malaysia",
    dial_code: "+60",
    code: "MY",
    currency: "MYR",
    flag: "https://flagcdn.com/my.svg"
  },
  {
    name: "Maldives",
    dial_code: "+960",
    code: "MV",
    currency: "MVR",
    flag: "https://flagcdn.com/mv.svg"
  },
  {
    name: "Mali",
    dial_code: "+223",
    code: "ML",
    currency: "XOF",
    flag: "https://flagcdn.com/ml.svg"
  },
  {
    name: "Malta",
    dial_code: "+356",
    code: "MT",
    currency: "EUR",
    flag: "https://flagcdn.com/mt.svg"
  },
  {
    name: "Marshall Islands",
    dial_code: "+692",
    code: "MH",
    currency: "USD",
    flag: "https://flagcdn.com/mh.svg"
  },
  {
    name: "Martinique",
    dial_code: "+596",
    code: "MQ",
    currency: "EUR",
    flag: "https://flagcdn.com/mq.svg"
  },
  {
    name: "Mauritania",
    dial_code: "+222",
    code: "MR",
    currency: "MRO",
    flag: "https://flagcdn.com/mr.svg"
  },
  {
    name: "Mauritius",
    dial_code: "+230",
    code: "MU",
    currency: "MUR",
    flag: "https://flagcdn.com/mu.svg"
  },
  {
    name: "Mayotte",
    dial_code: "+262",
    code: "YT",
    currency: "EUR",
    flag: "https://flagcdn.com/yt.svg"
  },
  {
    name: "Mexico",
    dial_code: "+52",
    code: "MX",
    currency: "MXN",
    flag: "https://flagcdn.com/mx.svg"
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    dial_code: "+691",
    code: "FM",
    currency: "USD",
    flag: "https://flagcdn.com/fm.svg"
  },
  {
    name: "Moldova",
    dial_code: "+373",
    code: "MD",
    currency: "MDL",
    flag: "https://flagcdn.com/md.svg"
  },
  {
    name: "Monaco",
    dial_code: "+377",
    code: "MC",
    currency: "EUR",
    flag: "https://flagcdn.com/mc.svg"
  },
  {
    name: "Mongolia",
    dial_code: "+976",
    code: "MN",
    currency: "MNT",
    flag: "https://flagcdn.com/mn.svg"
  },
  {
    name: "Montenegro",
    dial_code: "+382",
    code: "ME",
    currency: "EUR",
    flag: "https://flagcdn.com/me.svg"
  },
  {
    name: "Montserrat",
    dial_code: "+1664",
    code: "MS",
    currency: "XCD",
    flag: "https://flagcdn.com/ms.svg"
  },
  {
    name: "Morocco",
    dial_code: "+212",
    code: "MA",
    currency: "MAD",
    flag: "https://flagcdn.com/ma.svg"
  },
  {
    name: "Mozambique",
    dial_code: "+258",
    code: "MZ",
    currency: "MZN",
    flag: "https://flagcdn.com/mz.svg"
  },
  {
    name: "Myanmar",
    dial_code: "+95",
    code: "MM",
    currency: "MMK",
    flag: "https://flagcdn.com/mm.svg"
  },
  {
    name: "Namibia",
    dial_code: "+264",
    code: "NA",
    currency: "NAD",
    flag: "https://flagcdn.com/na.svg"
  },
  {
    name: "Nauru",
    dial_code: "+674",
    code: "NR",
    currency: "AUD",
    flag: "https://flagcdn.com/nr.svg"
  },
  {
    name: "Nepal",
    dial_code: "+977",
    code: "NP",
    currency: "NPR",
    flag: "https://flagcdn.com/np.svg"
  },
  {
    name: "Netherlands",
    dial_code: "+31",
    code: "NL",
    currency: "EUR",
    flag: "https://flagcdn.com/nl.svg"
  },
  {
    name: "New Caledonia",
    dial_code: "+687",
    code: "NC",
    currency: "XPF",
    flag: "https://flagcdn.com/nc.svg"
  },
  {
    name: "New Zealand",
    dial_code: "+64",
    code: "NZ",
    currency: "NZD",
    flag: "https://flagcdn.com/nz.svg"
  },
  {
    name: "Nicaragua",
    dial_code: "+505",
    code: "NI",
    currency: "NIO",
    flag: "https://flagcdn.com/ni.svg"
  },
  {
    name: "Niger",
    dial_code: "+227",
    code: "NE",
    currency: "XOF",
    flag: "https://flagcdn.com/ne.svg"
  },
  {
    name: "Nigeria",
    dial_code: "+234",
    code: "NG",
    currency: "NGN",
    flag: "https://flagcdn.com/ng.svg"
  },
  {
    name: "Niue",
    dial_code: "+683",
    code: "NU",
    currency: "NZD",
    flag: "https://flagcdn.com/nu.svg"
  },
  {
    name: "Norfolk Island",
    dial_code: "+672",
    code: "NF",
    currency: "AUD",
    flag: "https://flagcdn.com/nf.svg"
  },
  {
    name: "Northern Mariana Islands",
    dial_code: "+1670",
    code: "MP",
    currency: "USD",
    flag: "https://flagcdn.com/mp.svg"
  },
  {
    name: "Norway",
    dial_code: "+47",
    code: "NO",
    currency: "NOK",
    flag: "https://flagcdn.com/no.svg"
  },
  {
    name: "Oman",
    dial_code: "+968",
    code: "OM",
    currency: "OMR",
    flag: "https://flagcdn.com/om.svg"
  },
  {
    name: "Pakistan",
    dial_code: "+92",
    code: "PK",
    currency: "PKR",
    flag: "https://flagcdn.com/pk.svg"
  },
  {
    name: "Palau",
    dial_code: "+680",
    code: "PW",
    currency: "USD",
    flag: "https://flagcdn.com/pw.svg"
  },
  {
    name: "Palestinian Territory, Occupied",
    dial_code: "+970",
    code: "PS",
    currency: "ILS",
    flag: "https://flagcdn.com/ps.svg"
  },
  {
    name: "Panama",
    dial_code: "+507",
    code: "PA",
    currency: "PAB",
    flag: "https://flagcdn.com/pa.svg"
  },
  {
    name: "Papua New Guinea",
    dial_code: "+675",
    code: "PG",
    currency: "PGK",
    flag: "https://flagcdn.com/pg.svg"
  },
  {
    name: "Paraguay",
    dial_code: "+595",
    code: "PY",
    currency: "PYG",
    flag: "https://flagcdn.com/py.svg"
  },
  {
    name: "Peru",
    dial_code: "+51",
    code: "PE",
    currency: "PEN",
    flag: "https://flagcdn.com/pe.svg"
  },
  {
    name: "Philippines",
    dial_code: "+63",
    code: "PH",
    currency: "PHP",
    flag: "https://flagcdn.com/ph.svg"
  },
  {
    name: "Pitcairn",
    dial_code: "+872",
    code: "PN",
    currency: "NZD",
    flag: "https://flagcdn.com/pn.svg"
  },
  {
    name: "Poland",
    dial_code: "+48",
    code: "PL",
    currency: "PLN",
    flag: "https://flagcdn.com/pl.svg"
  },
  {
    name: "Portugal",
    dial_code: "+351",
    code: "PT",
    currency: "EUR",
    flag: "https://flagcdn.com/pt.svg"
  },
  {
    name: "Puerto Rico",
    dial_code: "+1939",
    code: "PR",
    currency: "USD",
    flag: "https://flagcdn.com/pr.svg"
  },
  {
    name: "Qatar",
    dial_code: "+974",
    code: "QA",
    currency: "QAR",
    flag: "https://flagcdn.com/qa.svg"
  },
  {
    name: "Romania",
    dial_code: "+40",
    code: "RO",
    currency: "RON",
    flag: "https://flagcdn.com/ro.svg"
  },
  {
    name: "Russia",
    dial_code: "+7",
    code: "RU",
    currency: "RUB",
    flag: "https://flagcdn.com/ru.svg"
  },
  {
    name: "Rwanda",
    dial_code: "+250",
    code: "RW",
    currency: "RWF",
    flag: "https://flagcdn.com/rw.svg"
  },
  {
    name: "Reunion",
    dial_code: "+262",
    code: "RE",
    currency: "EUR",
    flag: "https://flagcdn.com/re.svg"
  },
  {
    name: "Saint Barthelemy",
    dial_code: "+590",
    code: "BL",
    currency: "EUR",
    flag: "https://flagcdn.com/bl.svg"
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
    code: "SH",
    currency: "SHP",
    flag: "https://flagcdn.com/sh.svg"
  },
  {
    name: "Saint Kitts and Nevis",
    dial_code: "+1869",
    code: "KN",
    currency: "XCD",
    flag: "https://flagcdn.com/kn.svg"
  },
  {
    name: "Saint Lucia",
    dial_code: "+1758",
    code: "LC",
    currency: "XCD",
    flag: "https://flagcdn.com/lc.svg"
  },
  {
    name: "Saint Martin",
    dial_code: "+590",
    code: "MF",
    currency: "EUR",
    flag: "https://flagcdn.com/mf.svg"
  },
  {
    name: "Saint Pierre and Miquelon",
    dial_code: "+508",
    code: "PM",
    currency: "EUR",
    flag: "https://flagcdn.com/pm.svg"
  },
  {
    name: "Saint Vincent and the Grenadines",
    dial_code: "+1784",
    code: "VC",
    currency: "XCD",
    flag: "https://flagcdn.com/vc.svg"
  },
  {
    name: "Samoa",
    dial_code: "+685",
    code: "WS",
    currency: "WST",
    flag: "https://flagcdn.com/ws.svg"
  },
  {
    name: "San Marino",
    dial_code: "+378",
    code: "SM",
    currency: "EUR",
    flag: "https://flagcdn.com/sm.svg"
  },
  {
    name: "Sao Tome and Principe",
    dial_code: "+239",
    code: "ST",
    currency: "STD",
    flag: "https://flagcdn.com/st.svg"
  },
  {
    name: "Saudi Arabia",
    dial_code: "+966",
    code: "SA",
    currency: "SAR",
    flag: "https://flagcdn.com/sa.svg"
  },
  {
    name: "Senegal",
    dial_code: "+221",
    code: "SN",
    currency: "XOF",
    flag: "https://flagcdn.com/sn.svg"
  },
  {
    name: "Serbia",
    dial_code: "+381",
    code: "RS",
    currency: "RSD",
    flag: "https://flagcdn.com/rs.svg"
  },
  {
    name: "Seychelles",
    dial_code: "+248",
    code: "SC",
    currency: "SCR",
    flag: "https://flagcdn.com/sc.svg"
  },
  {
    name: "Sierra Leone",
    dial_code: "+232",
    code: "SL",
    currency: "SLL",
    flag: "https://flagcdn.com/sl.svg"
  },
  {
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
    currency: "SGD",
    flag: "https://flagcdn.com/sg.svg"
  },
  {
    name: "Slovakia",
    dial_code: "+421",
    code: "SK",
    currency: "EUR",
    flag: "https://flagcdn.com/sk.svg"
  },
  {
    name: "Slovenia",
    dial_code: "+386",
    code: "SI",
    currency: "EUR",
    flag: "https://flagcdn.com/si.svg"
  },
  {
    name: "Solomon Islands",
    dial_code: "+677",
    code: "SB",
    currency: "SBD",
    flag: "https://flagcdn.com/sb.svg"
  },
  {
    name: "Somalia",
    dial_code: "+252",
    code: "SO",
    currency: "SOS",
    flag: "https://flagcdn.com/so.svg"
  },
  {
    name: "South Africa",
    dial_code: "+27",
    code: "ZA",
    currency: "ZAR",
    flag: "https://flagcdn.com/za.svg"
  },
  {
    name: "South Sudan",
    dial_code: "+211",
    code: "SS",
    currency: "SSP",
    flag: "https://flagcdn.com/ss.svg"
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "+500",
    code: "GS",
    currency: "GBP",
    flag: "https://flagcdn.com/gs.svg"
  },
  {
    name: "Spain",
    dial_code: "+34",
    code: "ES",
    currency: "EUR",
    flag: "https://flagcdn.com/es.svg"
  },
  {
    name: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
    currency: "LKR",
    flag: "https://flagcdn.com/lk.svg"
  },
  {
    name: "Sudan",
    dial_code: "+249",
    code: "SD",
    currency: "SDG",
    flag: "https://flagcdn.com/sd.svg"
  },
  {
    name: "Suriname",
    dial_code: "+597",
    code: "SR",
    currency: "SRD",
    flag: "https://flagcdn.com/sr.svg"
  },
  {
    name: "Svalbard and Jan Mayen",
    dial_code: "+47",
    code: "SJ",
    currency: "NOK",
    flag: "https://flagcdn.com/sj.svg"
  },
  {
    name: "Swaziland",
    dial_code: "+268",
    code: "SZ",
    currency: "SZL",
    flag: "https://flagcdn.com/sz.svg"
  },
  {
    name: "Sweden",
    dial_code: "+46",
    code: "SE",
    currency: "SEK",
    flag: "https://flagcdn.com/se.svg"
  },
  {
    name: "Switzerland",
    dial_code: "+41",
    code: "CH",
    currency: "CHF",
    flag: "https://flagcdn.com/ch.svg"
  },
  {
    name: "Syrian Arab Republic",
    dial_code: "+963",
    code: "SY",
    currency: "SYP",
    flag: "https://flagcdn.com/sy.svg"
  },
  {
    name: "Taiwan",
    dial_code: "+886",
    code: "TW",
    currency: "TWD",
    flag: "https://flagcdn.com/tw.svg"
  },
  {
    name: "Tajikistan",
    dial_code: "+992",
    code: "TJ",
    currency: "TJS",
    flag: "https://flagcdn.com/tj.svg"
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    dial_code: "+255",
    code: "TZ",
    currency: "TZS",
    flag: "https://flagcdn.com/tz.svg"
  },
  {
    name: "Thailand",
    dial_code: "+66",
    code: "TH",
    currency: "THB",
    flag: "https://flagcdn.com/th.svg"
  },
  {
    name: "Timor-Leste",
    dial_code: "+670",
    code: "TL",
    currency: "USD",
    flag: "https://flagcdn.com/tl.svg"
  },
  {
    name: "Togo",
    dial_code: "+228",
    code: "TG",
    currency: "XOF",
    flag: "https://flagcdn.com/tg.svg"
  },
  {
    name: "Tokelau",
    dial_code: "+690",
    code: "TK",
    currency: "NZD",
    flag: "https://flagcdn.com/tk.svg"
  },
  {
    name: "Tonga",
    dial_code: "+676",
    code: "TO",
    currency: "TOP",
    flag: "https://flagcdn.com/to.svg"
  },
  {
    name: "Trinidad and Tobago",
    dial_code: "+1868",
    code: "TT",
    currency: "TTD",
    flag: "https://flagcdn.com/tt.svg"
  },
  {
    name: "Tunisia",
    dial_code: "+216",
    code: "TN",
    currency: "TND",
    flag: "https://flagcdn.com/tn.svg"
  },
  {
    name: "Turkey",
    dial_code: "+90",
    code: "TR",
    currency: "TRY",
    flag: "https://flagcdn.com/tr.svg"
  },
  {
    name: "Turkmenistan",
    dial_code: "+993",
    code: "TM",
    currency: "TMT",
    flag: "https://flagcdn.com/tm.svg"
  },
  {
    name: "Turks and Caicos Islands",
    dial_code: "+1649",
    code: "TC",
    currency: "USD",
    flag: "https://flagcdn.com/tc.svg"
  },
  {
    name: "Tuvalu",
    dial_code: "+688",
    code: "TV",
    currency: "AUD",
    flag: "https://flagcdn.com/tv.svg"
  },
  {
    name: "Uganda",
    dial_code: "+256",
    code: "UG",
    currency: "UGX",
    flag: "https://flagcdn.com/ug.svg"
  },
  {
    name: "Ukraine",
    dial_code: "+380",
    code: "UA",
    currency: "UAH",
    flag: "https://flagcdn.com/ua.svg"
  },
  {
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
    currency: "AED",
    flag: "https://flagcdn.com/ae.svg"
  },
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
    currency: "GBP",
    flag: "https://flagcdn.com/gb.svg"
  },
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
    currency: "USD",
    flag: "https://flagcdn.com/us.svg"
  },
  {
    name: "Uruguay",
    dial_code: "+598",
    code: "UY",
    currency: "UYU",
    flag: "https://flagcdn.com/uy.svg"
  },
  {
    name: "Uzbekistan",
    dial_code: "+998",
    code: "UZ",
    currency: "UZS",
    flag: "https://flagcdn.com/uz.svg"
  },
  {
    name: "Vanuatu",
    dial_code: "+678",
    code: "VU",
    currency: "VUV",
    flag: "https://flagcdn.com/vu.svg"
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    dial_code: "+58",
    code: "VE",
    currency: "VEF",
    flag: "https://flagcdn.com/ve.svg"
  },
  {
    name: "Vietnam",
    dial_code: "+84",
    code: "VN",
    currency: "VND",
    flag: "https://flagcdn.com/vn.svg"
  },
  {
    name: "Virgin Islands, British",
    dial_code: "+1284",
    code: "VG",
    currency: "USD",
    flag: "https://flagcdn.com/vg.svg"
  },
  {
    name: "Virgin Islands, U.S.",
    dial_code: "+1340",
    code: "VI",
    currency: "USD",
    flag: "https://flagcdn.com/vi.svg"
  },
  {
    name: "Wallis and Futuna",
    dial_code: "+681",
    code: "WF",
    currency: "XPF",
    flag: "https://flagcdn.com/wf.svg"
  },
  {
    name: "Yemen",
    dial_code: "+967",
    code: "YE",
    currency: "YER",
    flag: "https://flagcdn.com/ye.svg"
  },
  {
    name: "Zambia",
    dial_code: "+260",
    code: "ZM",
    currency: "ZMW",
    flag: "https://flagcdn.com/zm.svg"
  },
  {
    name: "Zimbabwe",
    dial_code: "+263",
    code: "ZW",
    currency: "ZWL",
    flag: "https://flagcdn.com/zw.svg"
  }
]