import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import animationData from "../lotties/BoxPayLogo.json";
import TranslateText from "../../resuableComponents/TranslateText";
import "./loading.css";

export default function Loading({ text, subText, redirectionName }) {
  const { t } = useTranslation();
  return (
    <div className="loadingMainDiv">
      <div className="innerText">
        <Lottie
          animationData={animationData}
          loop={true}
          autoplay={true}
          style={{ width: "48px", height: "48px" }}
          speed={4.5}
        />
        {redirectionName?.length ? (
          <Typography className="loadingText">
            <TranslateText label={"REDIRECTING_TO"}>Please wait. We are redirecting you to</TranslateText> {redirectionName}'s <TranslateText label={"PAGE"}>page</TranslateText>
          </Typography>
        ) : (
          <Typography className="loadingText">{text}</Typography>
        )}
        <Typography className="loadingSubText">{subText}</Typography>
      </div>
    </div>
  );
}
