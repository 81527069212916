import React, { useEffect, useState } from "react";
import { Skeleton, Typography } from "antd";
import { useTranslation } from "react-i18next";
import TranslateText from "../../resuableComponents/TranslateText";
import { useDispatch, useSelector } from "react-redux";
import { generateDynamicLogo } from "../../../Utils/utils";
import CardContainer from "../../resuableComponents/cardContainer/CardContainer";
import MobilePaymentNavigation from "./mobilePaymentNavigation/MobilePaymentNavigation";
import {
  removeSelectedIntruments,
  selectSavedInstruments,
} from "../../../slices/SavedInstrumentSlice";
import "./paymentOptionsContainer.css";
import { selectBaseColor } from "../../../slices/PaymentSessionDetailsSlice";
import BottomSheet from "../../resuableComponents/BottomSheet/BottomSheet";
import PaymentIntrumentSelection from "./paymentInstrumentSelection/PaymentIntrumentSelection";
import {
  changeActivePaymentMethod,
  changePaymentMethodInfo,
} from "../../../slices/PaymentButtonSlice";
import Emi from "./emi/emi";

const PaymentOptionsMobileContainer = ({ tabList, darkColor, lightColor }) => {
  const { t } = useTranslation();
  const paymentMethodDictionary = useSelector((state) => state?.paymentSessionDetails?.paymentMethodDictionary);
  let savedInstruments = useSelector((state) => selectSavedInstruments(state));
  const baseColor = useSelector((state) => selectBaseColor(state));
  const [openUpiBottomSheet, setOpenUpiBottomSheet] = useState(false);
  const [upiCollectInstruments, setUpiCollectInstruments] = useState([]);
  const [cardPaymentMethod, setCardPaymentMethod] = useState([]);
  const dispatch = useDispatch();
  const [isPaymentLoading, setIsPaymentLoading] = useState(true);

  useEffect(() => {
    if (Object.keys(paymentMethodDictionary)?.length >= 0) {
      const timer = setTimeout(() => {
        setIsPaymentLoading(false);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [paymentMethodDictionary]);
  useEffect(() => {
    if (savedInstruments) {
      let upiCollect = savedInstruments?.filter(
        (instrumentInfo) => instrumentInfo?.brand === "UpiCollect"
      );
      setUpiCollectInstruments(upiCollect);
      let card = savedInstruments?.filter(
        (cardInfo) => cardInfo?.brand === "Card"
      );
      setCardPaymentMethod(card);
    }
  }, [savedInstruments]);

  const handleClose = () => {
    dispatch(removeSelectedIntruments());
    setOpenUpiBottomSheet(false);
  };

  const savedInstrumentsOption = (type) => {
    if (type === "Upi") {
      return (
        <>
          {upiCollectInstruments?.length > 0 && (
            <div
              className="domestic-saved-instruments dynamic-font-family"
              onClick={() => {
                let upiCollect = paymentMethodDictionary["Upi"].find(
                  (upiInfo) => upiInfo.brand === "UpiCollect"
                );
                dispatch(changeActivePaymentMethod("UpiSaved"));
                dispatch(changePaymentMethodInfo(upiCollect));
                setOpenUpiBottomSheet(true);
              }}
            >
              <Typography className="domestic-saved-instruments-title dynamic-title">
                <TranslateText label={"SAVED_UPI"}>Saved UPI</TranslateText>
              </Typography>
              {generateDynamicLogo("rightArraow", "14px", "14px", baseColor)}
            </div>
          )}
        </>
      );
    }
    if (type === "Card") {
      return (
        <>
          {cardPaymentMethod && cardPaymentMethod?.length > 0 && (
            <div
              className="domestic-saved-instruments dynamic-font-family"
              onClick={() => {
                setOpenUpiBottomSheet(true);
              }}
            >
              <Typography className="domestic-saved-instruments-title dynamic-title">
                <TranslateText label={"SAVED_CARD"}>Saved Card</TranslateText>
              </Typography>
              {generateDynamicLogo("rightArraow", "14px", "14px", baseColor)}
            </div>
          )}
        </>
      );
    }
  };

  const shouldShowAllPaymentOptions = [
    "Wallet",
    "NetBanking",
    "BuyNowPayLater",
  ].some((method) => paymentMethodDictionary.hasOwnProperty(method));

  const isOnlyUpiQr =
    paymentMethodDictionary.Upi?.length === 1 &&
    paymentMethodDictionary.Upi[0].brand === "UpiQr";

  return (
    <>
      {isPaymentLoading ? (
        <Skeleton active size="large" />
      ) : Object.keys(paymentMethodDictionary)?.length ? (
        <>
          {upiCollectInstruments?.length > 0 && (
            <BottomSheet
              title={t("SAVED_UPI")}
              type={"pay"}
              open={openUpiBottomSheet}
              handleClose={handleClose}
              children={
                <PaymentIntrumentSelection data={upiCollectInstruments} />
              }
            />
          )}
          {cardPaymentMethod?.length > 0 && (
            <BottomSheet
              title={t("SAVED_CARD")}
              open={openUpiBottomSheet}
              handleClose={handleClose}
              children={<PaymentIntrumentSelection data={cardPaymentMethod} />}
            />
          )}
          {tabList?.map((tab, id) => {
            const shouldRenderTab =
              tab?.type === "Upi" && isOnlyUpiQr
                ? false
                : (tab?.type === "Upi" ||
                  tab?.type === "Card" ||
                  tab?.type === "Recommended") &&
                tab?.type in paymentMethodDictionary;
            return (
              shouldRenderTab && (
                <CardContainer
                  key={id}
                  header={
                    <div
                      style={{
                        display: "flex",
                        gap: "4px",
                        alignItems: "center",
                      }}
                    >
                      {tab?.logo}
                      <Typography className="domestic-card-mb-header dynamic-font-family">
                        <TranslateText label={tab.type.toUpperCase()}>
                          {tab?.type === "Recommended"
                            ? "Recommended"
                            : paymentMethodDictionary[tab?.type]?.length > 1
                              ? paymentMethodDictionary[tab?.type][0]?.typeTitle
                              : paymentMethodDictionary[tab?.type][0]?.title}
                        </TranslateText>
                      </Typography>
                    </div>
                  }
                  content={tab?.mobileContent || tab?.content}
                  extra={savedInstrumentsOption(tab?.type)}
                />
              )
            );
          })}

          {shouldShowAllPaymentOptions && (
            <CardContainer
              header={
                <div
                  style={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                  }}
                >
                  <img src="/assets/cardHeader/card.svg" />
                  <Typography className="domestic-card-mb-header dynamic-font-family">
                    <TranslateText label={"OTHER_PAYMENT_OPTIONS"}>Other Payment Options</TranslateText>
                  </Typography>
                </div>
              }
              content={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  {tabList?.map((tab, id) => {
                    if (
                      tab?.type === "Emi" &&
                      tab?.type in paymentMethodDictionary
                    ) {
                      return (
                        <MobilePaymentNavigation
                          key={id}
                          icon={tab?.logo}
                          title={
                            paymentMethodDictionary[tab?.type]?.length > 1
                              ? paymentMethodDictionary[tab?.type][0]?.typeTitle
                              : paymentMethodDictionary[tab?.type][0]?.title
                          }
                          children={
                            <Emi
                              key={id}
                              emiMethodInfo={
                                paymentMethodDictionary?.[tab?.type]
                              }
                            />
                          }
                          collapseKey={tab?.type}
                        />
                      );
                    } else if (
                      tab?.type !== "Upi" &&
                      tab?.type !== "Card" &&
                      tab?.type !== "Recommended" &&
                      tab?.type in paymentMethodDictionary
                    ) {
                      return (
                        <MobilePaymentNavigation
                          key={id}
                          icon={tab?.logo}
                          title={
                            paymentMethodDictionary[tab?.type]?.length > 1
                              ? paymentMethodDictionary[tab?.type][0]?.typeTitle
                              : paymentMethodDictionary[tab?.type][0]?.title
                          }
                          children={
                            <PaymentIntrumentSelection
                              data={paymentMethodDictionary[tab?.type]}
                              searchComponent={
                                tab?.type === "NetBanking" ? true : false
                              }
                              searchPlaceholder={
                                tab?.type === "NetBanking"
                                  ? "Search Banks"
                                  : "Search"
                              }
                            />
                          }
                          collapseKey={tab?.type}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              }
            />
          )}
        </>
      ) : (
        <div className="domestic-empty-po-root">
          <div className="no-payment-options-div">
            <img
              src="/assets/paymentOptions/paymentoptions.svg"
              alt="payment"
            />
            <Typography className="no-payment-options-Heading dynamic-font-family">
              <TranslateText label={"NO_PAYMENT_OPTION_AVAILABLE"}>No payment option available</TranslateText>
            </Typography>
            <Typography className="no-payment-options-subHeading dynamic-font-family">
              <TranslateText label={"CHANGE_COUNTRY_FOR_PAYMENT_OPTIONS"}>Please change your country in the address to see the available payment options</TranslateText>
            </Typography>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentOptionsMobileContainer;
