import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activePaymentMethod: null,
  buttonError: false,
  buttonLoading: false,
  paymentMethodInfo: null,
  popWindowIframeUpiIntent: undefined
};

export const paymentButtonSlice = createSlice({
  name: "paymentButton",
  initialState,
  reducers: {
    changeActivePaymentMethod(state, action) {
      state.activePaymentMethod = action.payload;
      state.buttonError = false;
      state.buttonLoading = false;
      state.paymentMethodInfo = null;
    },
    changePaymentMethodInfo(state, action) {
      state.paymentMethodInfo = action.payload;
    },
    toggleButtonError(state, action) {
      state.buttonError = action.payload;
    },
    toggleButtonLoading(state, action) {
      state.buttonLoading = action.payload;
    },
    setPopWindowIframeForUpiIntent(state, action) {
      state.popWindowIframeUpiIntent = action.payload;
    },
  },
});
export const {
  changeActivePaymentMethod,
  changePaymentMethodInfo,
  toggleButtonLoading,
  toggleButtonError,
  setPopWindowIframeForUpiIntent
} = paymentButtonSlice.actions;

export const selectActivePaymentMethod = (state) =>
  state?.paymentButton?.activePaymentMethod;

export const selectButtonError = (state) => state?.paymentButton?.buttonError;

export const selectButtonLoading = (state) =>
  state?.paymentButton?.buttonLoading;
export const selectPopWindowIframeUpiIntent = (state) =>
  state?.paymentButton?.popWindowIframeUpiIntent;

export const selectPaymentMethodInfo = (state) =>
  state?.paymentButton?.paymentMethodInfo;

export default paymentButtonSlice.reducer;
