import React, { useCallback, useEffect, useRef, useState } from "react";
import { Divider, Form, Input, Select, Space, Typography } from "antd";
import "./deliveryAddress.css";
import { useTranslation } from "react-i18next";
import CustomButton from "../../resuableComponents/CustomButton";
import { CountryData } from "../../../staticData/countryData";
import { useDispatch, useSelector } from "react-redux";
import { getPostalDetails } from "../../../slices/PostalCodeSlice";
import { countryPinCodeData } from "../../../staticData/countryPinCodeData";
import {
  addSavedAddress,
  handleAddressConflictModal,
  updateSavedAddress,
} from "../../../slices/SavedAddressSlice";
import { selectIsMobileLayout } from "../../../slices/MobileLayoutSlice";
import { blockInvalidChar, disableFutureDates, isDateInRange, isEmpty, validateDob } from "../../../Utils/utils";
import {
  collpaseAddressComponent,
  getAllPaymentMethods,
  updateShopperDetails,
} from "../../../slices/PaymentSessionDetailsSlice";
import MobileAddressButton from "./mobileAddressButon";
import {
  selectMobileNumber,
  selectSessionToken,
} from "../../../slices/ShopperInfoSlice";
import { addressAnalyticsEvent } from "../../../Utils/uiAnalytics";
import ErrorModal from "../modals/errorModal/ErrorModal";
import { InfoCircleOutlined } from "@ant-design/icons";
import TranslateText from "../../resuableComponents/TranslateText";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

export default function AddDeliveryAddress({
  addressFieldValue,
  selectedAddressItem,
  setShowAddAddressOption,
  showCancelButton,
  setSelectedAddressItem,
  handleGuestData,
}) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [panNumber, setPanNmber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [otherLabel, setOtherLabel] = useState("");
  const [disableStateField, setDisableStateField] = useState(false);
  const lastCleanedPostalCode = useRef("");
  const [selectedSavedAddress, setSelectedSavedAddress] = useState("");
  const [phoneDialCode, setPhoneDialCode] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  const [pincodeError, setPincodeError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [payloadData, setPayloadData] = useState({});
  const [isHomePresent, setIsHomePresent] = useState("");
  const [isWorkPresent, setIsWorkPresent] = useState("");
  const [isOtherDisabled, setIsOtherDisabled] = useState(false);
  const shopperRef = useSelector((state) => selectMobileNumber(state));
  const sessionToken = useSelector((state) => selectSessionToken(state));
  const shopperinfo = useSelector((state) => state.shopperInfo.shopperinfo);
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
  const isShippingAddressDisplay = useSelector((state) => state.paymentSessionDetails.isShippingAddressDisplay);
  const postalDetails = useSelector((state) => state.postalDetails.postalDetails);
  const allSavedAddresses = useSelector((state) => state.savedAddressDetails.allSavedAddresses);
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));
  const savedAddress = useSelector((state) => state.savedAddressDetails.savedAddress);
  const updatedSavedAddressData = useSelector((state) => state.savedAddressDetails.updatedSavedAddressData);
  const showBackButton = useSelector((state) => state.savedAddressDetails.showBackButton);
  const guestCheckout = useSelector((state) => state.shopperInfo.guestCheckout);
  const showLogin = useSelector((state) => state.additionalComponents.showLogin);
  const addressButtonLoading = useSelector((state) => state.savedAddressDetails.addressButtonLoading);
  const isConflictedEntity = useSelector((state) => state.savedAddressDetails.isConflictedEntity);
  const conflictedLabelName = useSelector((state) => state.savedAddressDetails.conflictedLabelName);
  const { showEmailAddress, showFullName, showContactNumber, showPan, showDob } = useSelector((state) => state.additionalComponents);

  useEffect(() => {
    if (guestCheckout) {
      if (sessionDetails?.paymentDetails?.shopper?.phoneNumber) {
        const shopperPhone = sessionDetails.paymentDetails.shopper.phoneNumber;
        const normalizedPhoneNumber = shopperPhone.startsWith("+") ? shopperPhone : !isEmpty(shopperPhone) ? `+${shopperPhone}` : '';
        const country = CountryData.find((country) =>
          normalizedPhoneNumber.startsWith(country.dial_code)
        );
        if (country) {
          setPhoneDialCode(country.dial_code);
        }
      } else {
        setPhoneDialCode("+91");
      }
    } else {
      setPhoneDialCode("+91");
    }
  }, [guestCheckout, sessionDetails]);
  useEffect(() => {
    const flagSetBefore = sessionStorage.getItem("selectedPhoneFlagSet");
    if (!flagSetBefore) {
      setCountry("IN");
      sessionStorage.setItem("selectedPhoneFlagSet", "true");
    }
  }, []);
  useEffect(() => {
    if (shopperinfo) {
      const dialCode = countryPinCodeData?.find((country) =>
        shopperinfo?.shopperRef?.startsWith(country.dial_code)
      );
      const phoneNumberWithoutDialCode = dialCode ? shopperinfo?.shopperRef.replace(dialCode.dial_code, "") : shopperinfo?.shopperRef;
      setPhoneNumber(phoneNumberWithoutDialCode);
      form.setFieldsValue({ phoneNumber: phoneNumberWithoutDialCode });
    }
  }, [shopperinfo, form]);
  useEffect(() => {
    if (selectedAddressItem) {
      const dialCode = countryPinCodeData?.find((country) => selectedAddressItem?.phoneNumber?.startsWith(country.dial_code));
      const phoneNumberWithoutDialCode = dialCode ? selectedAddressItem?.phoneNumber.replace(dialCode.dial_code, "") : selectedAddressItem?.phoneNumber;
      setPhoneNumber(phoneNumberWithoutDialCode);
      form.setFieldsValue({ phoneNumber: phoneNumberWithoutDialCode });
    }
  }, [selectedAddressItem, form]);
  useEffect(() => {
    if (sessionDetails) {
      const { firstName, lastName, email, panNumber, dateOfBirth } = sessionDetails?.paymentDetails?.shopper;
      if (firstName && lastName) {
        const fullName = `${firstName} ${lastName}`;
        setFullName(fullName);
        form.setFieldsValue({ fullName: fullName });
      } else if (firstName) {
        setFullName(firstName);
        form.setFieldsValue({ fullName: firstName });
      } else if (lastName) {
        setFullName(lastName);
        form.setFieldsValue({ fullName: lastName });
      } else {
        setFullName("");
        form.setFieldsValue({ fullName: "" });
      }
      setEmailAddress(email);
      form.setFieldsValue({ email: email });

      if (!isEmpty(dateOfBirth) && isDateInRange(dateOfBirth)) {
        const date = dayjs(dateOfBirth);
        setDateOfBirth(dateOfBirth);
        form.setFieldsValue({ dateOfBirth: date });
      }

      setPanNmber(panNumber);
      form.setFieldsValue({ panNumber: panNumber });
    }
  }, [sessionDetails, form]);
  useEffect(() => {
    if (guestCheckout) {
      if (sessionDetails) {
        const shopperPhone = sessionDetails?.paymentDetails?.shopper?.phoneNumber;
        const normalizedPhoneNumber = shopperPhone?.startsWith("+") ? shopperPhone : !isEmpty(shopperPhone) ? `+${shopperPhone}` : '';
        const dialCode = CountryData?.find((country) => normalizedPhoneNumber?.startsWith(country.dial_code));
        const phoneNumberWithoutDialCode = dialCode ? normalizedPhoneNumber.replace(dialCode.dial_code, "") : normalizedPhoneNumber;
        setPhoneNumber(phoneNumberWithoutDialCode);
        form.setFieldsValue({ phoneNumber: phoneNumberWithoutDialCode });
      }
    }
  }, [guestCheckout, sessionDetails, form]);
  useEffect(() => {
    if (Object.keys(addressFieldValue)?.length) {
      const normalizedPhoneNumber = addressFieldValue?.phoneNumber?.startsWith("+") ? addressFieldValue?.phoneNumber : `+${addressFieldValue?.phoneNumber}`;
      let tempDialCode = CountryData?.find((country) => normalizedPhoneNumber?.startsWith(country?.dial_code));
      const dialCode = tempDialCode ? tempDialCode : CountryData?.find((country) => country.code === addressFieldValue.countryCode);
      const phoneNumberWithoutDialCode = dialCode ? normalizedPhoneNumber.replace(dialCode.dial_code, "") : normalizedPhoneNumber;

      setFullName(addressFieldValue.name);
      setPhoneNumber(phoneNumberWithoutDialCode);
      setPhoneDialCode(dialCode?.dial_code);
      setEmailAddress(addressFieldValue.email);
      setPanNmber(addressFieldValue.panNumber);
      setCountry(addressFieldValue?.countryCode);
      setPincode(addressFieldValue?.postalCode);
      setState(addressFieldValue?.state);
      setCity(addressFieldValue?.city);
      setAddress1(addressFieldValue?.address1);
      setAddress2(addressFieldValue?.address2);
      setOtherLabel(addressFieldValue?.labelName);
      if (!isEmpty(addressFieldValue.dateOfBirth) && isDateInRange(addressFieldValue.dateOfBirth)) {
        const date = dayjs(addressFieldValue.dateOfBirth);
        setDateOfBirth(addressFieldValue.dateOfBirth);
        form.setFieldsValue({ dateOfBirth: date });
      }

      form.setFieldsValue({
        fullName: addressFieldValue?.name,
        phoneNumber: phoneNumberWithoutDialCode,
        email: addressFieldValue?.email,
        panNumber: addressFieldValue?.panNumber,
        country: addressFieldValue?.countryCode,
        pincode: addressFieldValue?.postalCode,
        state: addressFieldValue?.state,
        city: addressFieldValue?.city,
        address1: addressFieldValue?.address1,
        address2: addressFieldValue?.address2,
        otherLabel: addressFieldValue?.labelName,
      });
    }
  }, [addressFieldValue, form]);
  useEffect(() => {
    if (guestCheckout || showLogin === false) {
      if (sessionDetails && Object.keys(addressFieldValue)?.length === 0) {
        const shopperPhone = sessionDetails?.paymentDetails?.shopper?.phoneNumber;
        const normalizedPhoneNumber = shopperPhone?.startsWith("+") ? shopperPhone : !isEmpty(shopperPhone) ? `+${shopperPhone}` : '';
        const dialCode = CountryData?.find((country) =>
          normalizedPhoneNumber?.startsWith(country.dial_code)
        );
        const phoneNumberWithoutDialCode = dialCode ? normalizedPhoneNumber.replace(dialCode.dial_code, "") : normalizedPhoneNumber;
        const country = CountryData.find((country) =>
          normalizedPhoneNumber.startsWith(country.dial_code)
        );
        if (country) {
          setPhoneDialCode(country.dial_code);
        }
        const firstName = sessionDetails?.paymentDetails?.shopper?.firstName;
        const lastName = sessionDetails?.paymentDetails?.shopper?.lastName;
        if (firstName && lastName) {
          const fullName = `${firstName} ${lastName}`;
          setFullName(fullName);
          form.setFieldsValue({ fullName: fullName });
        } else if (firstName) {
          setFullName(firstName);
          form.setFieldsValue({ fullName: firstName });
        } else if (lastName) {
          setFullName(lastName);
          form.setFieldsValue({ fullName: lastName });
        } else {
          setFullName("");
          form.setFieldsValue({ fullName: "" });
        }
        setPhoneNumber(phoneNumberWithoutDialCode);
        setEmailAddress(sessionDetails?.paymentDetails?.shopper?.email);
        setPanNmber(sessionDetails?.paymentDetails?.shopper?.panNumber);
        if (sessionDetails?.paymentDetails?.shopper?.deliveryAddress?.countryCode) {
          setCountry(sessionDetails?.paymentDetails?.shopper?.deliveryAddress?.countryCode);
        } else {
          setCountry(dialCode?.code);
        }
        setPincode(sessionDetails?.paymentDetails?.shopper?.deliveryAddress?.postalCode);
        setState(sessionDetails?.paymentDetails?.shopper?.deliveryAddress?.state);
        setCity(sessionDetails?.paymentDetails?.shopper?.deliveryAddress?.city);
        setAddress1(sessionDetails?.paymentDetails?.shopper?.deliveryAddress?.address1);
        setAddress2(sessionDetails?.paymentDetails?.shopper?.deliveryAddress?.address2);
        if (!isEmpty(sessionDetails?.dateOfBirth) && isDateInRange(sessionDetails?.dateOfBirth)) {
          const date = dayjs(sessionDetails?.dateOfBirth);
          setDateOfBirth(sessionDetails?.dateOfBirth);
          form.setFieldsValue({ dateOfBirth: date });
        }
        form.setFieldsValue({
          phoneNumber: phoneNumberWithoutDialCode,
          email: sessionDetails?.paymentDetails?.shopper?.email,
          panNumber: sessionDetails?.paymentDetails?.shopper?.panNumber,
          country: sessionDetails?.paymentDetails?.shopper?.deliveryAddress?.countryCode,
          pincode: sessionDetails?.paymentDetails?.shopper?.deliveryAddress?.postalCode,
          state: sessionDetails?.paymentDetails?.shopper?.deliveryAddress?.state,
          city: sessionDetails?.paymentDetails?.shopper?.deliveryAddress?.city,
          address1: sessionDetails?.paymentDetails?.shopper?.deliveryAddress?.address1,
          address2: sessionDetails?.paymentDetails?.shopper?.deliveryAddress?.address2,
        });
      }
    }
  }, [guestCheckout, sessionDetails, addressFieldValue, showLogin, form]);
  const validateEmail = (rule, value, callback) => {
    const regex = /^[^\s@']+@[^\s@']+\.[^\s@']+$/;
    if (!value) {
      callback(t("REQUIRED"));
    } else if (!regex.test(value)) {
      callback(t("INVALID_EMAIL"));
      setDisableButton(true);
    } else {
      callback();
    }
  };
  const validatePan = (rule, value, callback) => {
    const regex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (!value) {
      callback(t("REQUIRED"));
    } else if (!regex.test(value)) {
      callback(t("INVALID_PAN"));
      setDisableButton(true);
    } else {
      callback();
    }
  }
  const validateOtherLabel = (rule, value, callback) => {
    const regex = /^[\w\s+.,_()'-]*$/;
    if (!value) {
      callback(t("REQUIRED"));
    } else if (!regex.test(value)) {
      callback("Only +,._-()' are allowed");
      setDisableButton(true);
    } else {
      callback();
      setDisableButton(false);
    }
  };
  const handleFullName = (e) => {
    setFullName(e.target.value);
    addressAnalyticsEvent(sessionDetails, dispatch);
  };
  const validatePhoneNumber = (telNo, country) => {
    if (telNo === "") {
      return t("REQUIRED");
    } else if (country) {
      if (telNo.length !== country.phoneNumberLength) {
        return `${t("MOBILE_NUMBER_MUST_BE")} ${country.phoneNumberLength} ${t("DIGITS")}`;
      } else if (telNo.charAt(0) === "0") {
        return t("INVALID_NUMBER");
      }
    }
    return "";
  };
  const handlePhoneChange = (e) => {
    const telNo = e.target.value;
    const re = /^[0-9\b]+$/;
    if (telNo === "" || re.test(telNo)) {
      setPhoneNumber(telNo);
      const country = countryPinCodeData.find(
        (country) => country.dial_code === phoneDialCode
      );
      const error = validatePhoneNumber(telNo, country);
      setPhoneError(error);
      addressAnalyticsEvent(sessionDetails, dispatch);
    }
  };
  const handleEmailAddress = (e) => {
    setEmailAddress(e.target.value);
    addressAnalyticsEvent(sessionDetails, dispatch);
  };
  const handlePan = (e) => {
    setPanNmber(e.target.value);
    addressAnalyticsEvent(sessionDetails, dispatch);
  };
  const handleDob = (date, dateString) => {
    if (!date) {
      setDateOfBirth(null); // When no date is selected, set it to null
    } else {
      const formattedDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
      setDateOfBirth(formattedDate);
    }
    addressAnalyticsEvent(sessionDetails, dispatch);
  };
  const handleCountry = (value) => {
    setCountry(value);
    addressAnalyticsEvent(sessionDetails, dispatch);
  };
  const handlePincode = (e) => {
    const cleanedPostalCode = e.target.value;
    setPincode(cleanedPostalCode);
    const matchingCountry = countryPinCodeData.find(
      (matchedCode) => country === matchedCode?.code
    );
    const maxPincodeLength = matchingCountry?.pinCodeLength;
    if (
      matchingCountry &&
      cleanedPostalCode?.length === matchingCountry?.pinCodeLength &&
      country
    ) {
      if (cleanedPostalCode !== lastCleanedPostalCode.current) {
        dispatch(
          getPostalDetails({
            token: sessionDetails?.token,
            countryCode: country,
            postalCode: cleanedPostalCode,
          })
        );
        lastCleanedPostalCode.current = cleanedPostalCode;
      }
      setPincodeError("");
    } else {
      lastCleanedPostalCode.current = "";
      setDisableStateField(false);
    }
    if (country && matchingCountry) {
      if (cleanedPostalCode.length !== maxPincodeLength) {
        setPincode(cleanedPostalCode.slice(0, maxPincodeLength));
        setPincodeError(`${t("ZIP_POSTAL_REQUIRED")} ${maxPincodeLength} ${t("DIGITS")}`);
      }
      if (cleanedPostalCode.length === 0) {
        setPincodeError(t("REQUIRED"));
      }
    }
    addressAnalyticsEvent(sessionDetails, dispatch);
  };
  useEffect(() => {
    if (
      Object.keys(addressFieldValue)?.length === 0 &&
      postalDetails &&
      Object.keys(postalDetails)?.length
    ) {
      setState(postalDetails?.state);
      setCity(postalDetails?.city);
      setAddress1(postalDetails?.address1);
      setAddress2(postalDetails?.address2);
      form.setFieldsValue({
        state: postalDetails?.state,
        city: postalDetails?.city,
        address1: postalDetails?.address1,
        address2: postalDetails?.address2,
      });
      if (postalDetails?.state) {
        setDisableStateField(true);
      }
    }
  }, [addressFieldValue, postalDetails, form]);
  const handleState = (e) => {
    setState(e.target.value);
    addressAnalyticsEvent(sessionDetails, dispatch);
  };
  const handleCity = (e) => {
    setCity(e.target.value);
    addressAnalyticsEvent(sessionDetails, dispatch);
  };
  const handleAddress1 = (e) => {
    setAddress1(e.target.value);
    addressAnalyticsEvent(sessionDetails, dispatch);
  };
  const handleAddress2 = (e) => {
    setAddress2(e.target.value);
    addressAnalyticsEvent(sessionDetails, dispatch);
  };
  const handleOthersLabel = (e) => {
    const value = e.target.value.trim();
    setOtherLabel(value);
    addressAnalyticsEvent(sessionDetails, dispatch);
  };
  const handleCountryChange = (value) => {
    setPhoneDialCode(value);
    const dialCode = CountryData?.find((code) => code?.dial_code === value);
    setCountry(dialCode?.code);
  };
  useEffect(() => {
    if (country) {
      setDisableStateField(false);
    }
  }, [country]);
  const filterOptionCountry = (input, option) =>
    (option?.props?.children ?? "").toLowerCase().includes(input.toLowerCase());
  const filterCountry = (input, option) =>
    (option?.props?.children ?? "").toLowerCase().includes(input.toLowerCase());
  const handleSavedAddress = (value) => {
    setSelectedSavedAddress(value);
  };
  const handleSubmit = () => {
    const payload = {
      name: fullName,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      countryCode: country,
      postalCode: pincode,
      labelType: selectedSavedAddress,
      labelName: otherLabel,
      email: emailAddress,
      panNumber,
      dateOfBirth,
      phoneNumber: isEmpty(phoneNumber) ? '' : phoneDialCode + phoneNumber,
    };
    dispatch(addSavedAddress({ shopperRef, payload, sessionToken }));
    addressAnalyticsEvent(sessionDetails, dispatch);
  };
  const handleGuestCheckout = () => {
    const guestData = {
      name: fullName,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      countryCode: country,
      postalCode: pincode,
      email: emailAddress,
      panNumber,
      dateOfBirth,
      phoneNumber: isEmpty(phoneNumber) ? '' : phoneDialCode + phoneNumber,
    };
    handleGuestData(guestData);
    dispatch(
      getAllPaymentMethods({
        token: sessionDetails?.token,
        countryCode: guestData?.countryCode,
      })
    );
    addressAnalyticsEvent(sessionDetails, dispatch);
  };
  useEffect(() => {
    if (allSavedAddresses?.length > 0 && addressFieldValue && Object.keys(addressFieldValue)?.length === 0) {
      const allSavedLabelTypes = allSavedAddresses?.map(
        (address) => address.labelType
      );
      const homePresent = allSavedLabelTypes.includes("Home");
      const workPresent = allSavedLabelTypes.includes("Work");

      if (homePresent && workPresent) {
        setSelectedSavedAddress("Other");
        setIsHomePresent(true);
        setIsWorkPresent(true);
        setIsOtherDisabled(false);
      } else if (homePresent) {
        setSelectedSavedAddress("Work");
        setIsHomePresent(true);
        setIsWorkPresent(false);
        setIsOtherDisabled(false);
      } else if (workPresent) {
        setSelectedSavedAddress("Home");
        setIsHomePresent(false);
        setIsWorkPresent(true);
        setIsOtherDisabled(false);
      } else {
        setSelectedSavedAddress("Home");
        setIsHomePresent(false);
        setIsWorkPresent(false);
        setIsOtherDisabled(false);
      }
    } else if (addressFieldValue && Object.keys(addressFieldValue)?.length > 0 && allSavedAddresses?.length > 0) {
      if (
        allSavedAddresses.some((address) => address.labelType === "Home") &&
        addressFieldValue.labelType === "Work"
      ) {
        setSelectedSavedAddress("Work");
        setIsHomePresent(true);
        setIsWorkPresent(false);
        setIsOtherDisabled(true);
      } else if (
        allSavedAddresses.some((address) => address.labelType === "Work") &&
        addressFieldValue.labelType === "Home"
      ) {
        setSelectedSavedAddress("Home");
        setIsHomePresent(false);
        setIsWorkPresent(true);
        setIsOtherDisabled(true);
      } else {
        setSelectedSavedAddress(addressFieldValue.labelType);
        setIsHomePresent(true);
        setIsWorkPresent(true);
        setIsOtherDisabled(false);
      }
    } else {
      setSelectedSavedAddress("Home");
      setIsHomePresent(false);
      setIsWorkPresent(false);
    }
  }, [allSavedAddresses, addressFieldValue]);
  const handleEditAddress = () => {
    const payload = {
      name: fullName,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      countryCode: country,
      postalCode: pincode,
      labelType: selectedSavedAddress,
      labelName: otherLabel,
      email: emailAddress,
      panNumber,
      dateOfBirth,
      phoneNumber: isEmpty(phoneNumber) ? '' : phoneDialCode + phoneNumber,
    };
    dispatch(
      updateSavedAddress({
        shopperRef,
        payload,
        sessionToken,
        labelType: selectedSavedAddress,
        labelName: otherLabel,
      })
    );
    setSelectedAddressItem("");
    addressAnalyticsEvent(sessionDetails, dispatch);
  };
  useEffect(() => {
    const formData = {
      name: fullName,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      countryCode: country,
      postalCode: pincode,
      labelType: selectedSavedAddress,
      labelName: otherLabel,
      email: emailAddress,
      panNumber,
      dateOfBirth,
      phoneNumber: phoneNumber,
      phoneDialCode: phoneDialCode,
    };
    setPayloadData(formData);
  }, [
    fullName,
    address1,
    address2,
    city,
    state,
    country,
    pincode,
    selectedSavedAddress,
    otherLabel,
    emailAddress,
    panNumber,
    dateOfBirth,
    phoneDialCode,
    phoneNumber,
  ]);
  useEffect(() => {
    if (Object.keys(savedAddress)?.length) {
      const selectedCardData = savedAddress;
      dispatch(updateShopperDetails({ selectedCardData }));
    }
    if (Object.keys(updatedSavedAddressData)?.length) {
      const selectedCardData = updatedSavedAddressData;
      dispatch(updateShopperDetails({ selectedCardData }));
    }
  }, [savedAddress, updatedSavedAddressData, dispatch]);

  useEffect(() => {
    const isAddressComplete =
      fullName &&
      phoneNumber &&
      country &&
      pincode &&
      state &&
      city &&
      address1 &&
      selectedSavedAddress &&
      (showEmailAddress ? emailAddress : true);

    const hasErrors =
      phoneError?.length > 0 ||
      pincodeError?.length > 0 ||
      (showFullName && fullName?.length <= 0) ||
      (showContactNumber && phoneNumber?.length <= 0) ||
      (showEmailAddress && emailAddress?.length <= 0) ||
      (showPan && isEmpty(panNumber)) ||
      (showDob && (isEmpty(dateOfBirth) || !isDateInRange(dateOfBirth))) ||
      country?.length <= 0 ||
      pincode?.length <= 0 ||
      state?.length <= 0 ||
      city?.length <= 0 ||
      address1?.length <= 0 ||
      (selectedSavedAddress === "Other" && otherLabel?.length === 0);
    if (!showFullName && !showContactNumber && !showEmailAddress && !isShippingAddressDisplay && !showDob && !showPan) {
      dispatch(collpaseAddressComponent());
    }
    if (isAddressComplete && !hasErrors) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [
    fullName,
    phoneNumber,
    emailAddress,
    country,
    pincode,
    state,
    city,
    address1,
    selectedSavedAddress,
    phoneError,
    pincodeError,
    otherLabel,
    showFullName,
    showContactNumber,
    showEmailAddress,
    showPan,
    panNumber,
    showDob,
    dateOfBirth
  ]);

  useEffect(() => {
    const handleLanguageChange = () => {
      form.validateFields(['fullName']);
      form.validateFields(['phoneNumber']);
      form.validateFields(['email']);
      form.validateFields(['panNumber']);
      form.validateFields(['dateOfBirth']);
      form.validateFields(['country']);
      form.validateFields(['pincode']);
      form.validateFields(['state']);
      form.validateFields(['city']);
      form.validateFields(['address1']);
      form.validateFields(['address2']);
    };

    // Listen for language changes
    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n, form]);
  const DobComponent = useCallback(() => {
    return (
      <Form.Item
        label={
          <Typography className="regular14Heading deliveryTitle dynamic-font-family">
            <TranslateText label={"DATE_OF_BIRTH"}>Date of birth</TranslateText>*
          </Typography>
        }
        name="dateOfBirth"
        rules={[
          {
            required: true,
            message: t("REQUIRED"),
          },
          {
            validator: validateDob // Custom validator for additional checks if needed
          },
        ]}
      >
        <DatePicker className={"date-picker-w-100"}
          defaultPickerValue={dayjs().subtract(5, 'year')}
          value={!isEmpty(dateOfBirth) && typeof dateOfBirth === 'object' ? dayjs(dateOfBirth) : null}
          onChange={handleDob}
          disabledDate={disableFutureDates}
          renderExtraFooter={() => null}
          placeholder={t("YYYY_DD_MM")}
          showToday={false}
          allowClear={false}
          inputReadOnly
        />
      </Form.Item>
    )
  }, [])

  return (
    <div className="deliveryForm">
      <div className="addressDivider">
        {Object.keys(addressFieldValue)?.length ? (
          <Divider orientation="left">
            <TranslateText label={"EDIT_ADDRESS"}>Edit Address</TranslateText>
          </Divider>
        ) : (
          <Divider orientation="left">
            <TranslateText label={"ADD_NEW_ADDRESS"}>Add New Address</TranslateText>
          </Divider>
        )}
      </div>

      <Form
        requiredMark={"optional"}
        layout="vertical"
        name="addresss"
        form={form}
      >
        <div className="cardPadding">
          <div className="deliveryAddressFields">
            {
              (isShippingAddressDisplay || showFullName) && (
                <Form.Item
                  label={
                    <Typography className="regular14Heading deliveryTitle dynamic-font-family">
                      <TranslateText label={"FULL_NAME"}>Full Name</TranslateText>*
                    </Typography>
                  }
                  name="fullName"
                  rules={[
                    {
                      required: true,
                      message: t("REQUIRED"),
                    },
                  ]}
                >
                  <>
                    <Input
                      placeholder={t("FULL_NAME")}
                      value={fullName}
                      onChange={handleFullName}
                    />
                  </>
                </Form.Item>
              )
            }
            {
              (isShippingAddressDisplay || showContactNumber) && (
                <Form.Item
                  label={
                    <Typography className="regular14Heading deliveryTitle dynamic-font-family">
                      <TranslateText label={"MOBILE_NUMBER"}>Mobile Number</TranslateText>*
                    </Typography>
                  }
                  name="phoneNumber"
                  validateStatus={phoneError ? "error" : ""}
                  // help={phoneError}
                  rules={[
                    {
                      required: true,
                      message: t("REQUIRED")
                    },

                  ]}
                >
                  <Space
                    direction="vertical"
                    size="middle"
                    style={{ width: "100%" }}
                  >
                    <Space.Compact>
                      <Select
                        className="contactNumber"
                        showSearch
                        filterOption={filterCountry}
                        value={phoneDialCode}
                        onChange={handleCountryChange}
                      >
                        {CountryData.map((country) => (
                          <Select.Option
                            key={country.code}
                            value={country.dial_code}
                          >
                            {country.dial_code}
                          </Select.Option>
                        ))}
                      </Select>
                      <Input
                        type="number"
                        value={phoneNumber}
                        style={{ width: "100%" }}
                        onKeyDown={blockInvalidChar}
                        onChange={handlePhoneChange}
                        placeholder={t("MOBILE_NUMBER")}
                        inputMode="numeric"
                        pattern="[0-9]*"
                      />
                    </Space.Compact>
                  </Space>
                </Form.Item>
              )
            }
          </div>

          <div className="deliveryAddressFields">
            {
              (isShippingAddressDisplay || showEmailAddress) && (
                <Form.Item
                  label={
                    <Typography className="regular14Heading deliveryTitle dynamic-font-family">
                      <TranslateText label={"EMAIL"}>Email</TranslateText>*
                    </Typography>
                  }
                  name="email"
                  rules={[
                    {
                      required: true,
                      validator: validateEmail,
                    },
                  ]}
                >
                  <>
                    <Input
                      placeholder={t("EMAIL_ADDRESS")}
                      value={emailAddress}
                      onChange={handleEmailAddress}
                    />
                  </>
                </Form.Item>
              )
            }
            {showPan && (
              <Form.Item
                label={
                  <Typography className="regular14Heading deliveryTitle dynamic-font-family">
                    <TranslateText label={"PAN"}>Pan</TranslateText>*
                  </Typography>
                }
                name="panNumber"
                rules={[
                  {
                    required: true,
                    validator: validatePan,
                  },
                ]}
              >
                <>
                  <Input
                    placeholder={t("PAN")}
                    value={panNumber}
                    onChange={handlePan}
                  />
                </>
              </Form.Item>
            )}
            {!showPan && showDob && (<DobComponent />)}
          </div>

          <div className="deliveryAddressFields">
            {showDob && showPan && (<DobComponent />)}
          </div>

          <div className="deliveryAddressFields">
            <Form.Item
              label={
                <Typography className="regular14Heading deliveryTitle dynamic-font-family">
                  <TranslateText label={"COUNTRY"}>Country</TranslateText>*
                </Typography>
              }
              name="country"
              rules={[
                {
                  required: true,
                  message: t("REQUIRED"),
                },
              ]}
            >
              <Space
                direction="vertical"
                size="middle"
                style={{ width: "100%" }}
              >
                <Space.Compact>
                  <Select
                    showSearch
                    placeholder={t("COUNTRY")}
                    optionFilterProp="name"
                    filterOption={filterOptionCountry}
                    value={country}
                    onChange={handleCountry}
                  >
                    {CountryData.map((country) => (
                      <Select.Option key={country.code} value={country.value}>
                        {country.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Space.Compact>
              </Space>
            </Form.Item>
            <Form.Item
              label={
                <Typography className="regular14Heading deliveryTitle dynamic-font-family">
                  <TranslateText label={"ZIP_POSTAL_CODE"}>ZIP/Postal Code</TranslateText>*
                </Typography>
              }
              name="pincode"
              rules={[
                {
                  required: true,
                  message: t("REQUIRED"),
                },
                {
                  pattern: /^[a-zA-Z0-9- ]+$/,
                  message: "Only digits, alphabets, and hyphens are allowed",
                },
              ]}
              validateStatus={pincodeError ? "error" : ""}
              help={t(`${pincodeError}`)}
            >
              <>
                <Input
                  placeholder={t("ZIP_POSTAL_CODE")}
                  value={pincode}
                  onChange={handlePincode}
                  inputMode="numeric"
                  pattern="[0-9]*"
                />
              </>
            </Form.Item>
          </div>
          <div className="deliveryAddressFields">
            <Form.Item
              label={
                <Typography className="regular14Heading deliveryTitle dynamic-font-family">
                  <TranslateText label={"STATE"}>State</TranslateText>*
                </Typography>
              }
              name="state"
              rules={[
                {
                  required: true,
                  message: t("REQUIRED"),
                },
              ]}
            >
              <>
                <Input
                  placeholder={t("STATE")}
                  value={state}
                  onChange={handleState}
                  disabled={disableStateField}
                />
              </>
            </Form.Item>
            <Form.Item
              label={
                <Typography className="regular14Heading deliveryTitle dynamic-font-family">
                  <TranslateText label={"CITY"}>City</TranslateText>*
                </Typography>
              }
              name="city"
              rules={[
                {
                  required: true,
                  message: t("REQUIRED"),
                },
              ]}
            >
              <>
                <Input placeholder={t("CITY")} value={city} onChange={handleCity} />
              </>
            </Form.Item>
          </div>
          <Form.Item
            label={
              <Typography className="regular14Heading deliveryTitle dynamic-font-family">
                <TranslateText label={"HOUSE_NUMBER_APARTMENT"}>House Number, Apartment</TranslateText>*
              </Typography>
            }
            name="address1"
            rules={[
              {
                required: true,
                message: t("REQUIRED"),
              },
            ]}
          >
            <>
              <Input
                placeholder={t("HOUSE_NUMBER_APARTMENT")}
                value={address1}
                onChange={handleAddress1}
              />
            </>
          </Form.Item>
          <Form.Item
            label={
              <Typography className="regular14Heading deliveryTitle dynamic-font-family">
                <TranslateText label={"AREA_COLONY_STREET_SECTOR"}>Area, Colony, Street, Sector</TranslateText>
              </Typography>
            }
            name="address2"
          >
            <>
              <Input
                placeholder={t("AREA_COLONY_STREET_SECTOR")}
                value={address2}
                onChange={handleAddress2}
              />
            </>
          </Form.Item>
          {showLogin === true && !guestCheckout && (
            <>
              <Typography className="regular12Heading deliveryTitle">
                <TranslateText label={"SAVE_ADDRESS_AS"}>Save address as</TranslateText>
              </Typography>
              <div style={{ display: "flex" }}>
                <div
                  className={`savedAddresTitle ${selectedSavedAddress === "Home"
                    ? "selectedSavedAddress"
                    : isHomePresent
                      ? "disabledAddressField"
                      : "unselectedAddress"
                    }`}
                  onClick={() => {
                    if (!isHomePresent) handleSavedAddress("Home");
                  }}
                >
                  <TranslateText label={"HOME"}>Home</TranslateText>
                </div>
                <div
                  className={`savedAddresTitle ${selectedSavedAddress === "Work"
                    ? "selectedSavedAddress"
                    : isWorkPresent
                      ? "disabledAddressField"
                      : "unselectedAddress"
                    }`}
                  onClick={() => {
                    if (!isWorkPresent) handleSavedAddress("Work");
                  }}
                >
                  <TranslateText label={"OFFICE"}>Office</TranslateText>
                </div>
                <div
                  className={`savedAddresTitle ${selectedSavedAddress === "Other"
                    ? "selectedSavedAddress"
                    : isOtherDisabled
                      ? "disabledAddressField"
                      : "unselectedAddress"
                    }`}
                  onClick={() => handleSavedAddress("Other")}
                >
                  <TranslateText label={"OTHERS"}>Others</TranslateText>
                </div>
              </div>
            </>
          )}
          {selectedSavedAddress === "Other" && (
            <div
              className="deliveryAddressFields"
              style={{ marginTop: "12px" }}
            >
              <Form.Item
                name="otherLabel"
                rules={[
                  {
                    required: true,
                    validator: validateOtherLabel,
                  },
                ]}
              >
                <>
                  <Input
                    placeholder={t("ENTER_OWN_LABEL")}
                    value={otherLabel}
                    onChange={handleOthersLabel}
                    disabled={
                      Object.keys(addressFieldValue)?.length &&
                      addressFieldValue?.labelType === "Other" &&
                      true
                    }
                  />
                </>
              </Form.Item>
            </div>
          )}
        </div>
        <div
          className={isMobileLayout ? "" : "continueButtonDiv"}
          style={{ display: isMobileLayout ? "none" : "" }}
        >
          {showBackButton && (
            <CustomButton
              text={`${t("GO_BACK")}`}
              buttonType={"primary-btn"}
              padding={"10px 50px"}
              containerType={""}
              onClick={() => {
                setShowAddAddressOption(false);
                setSelectedAddressItem("");
              }}
              htmlType="submit"
            />
          )}
          <CustomButton
            text={`${t("CONTINUE")}`}
            buttonType={"primary-btn"}
            padding={"10px 50px"}
            onClick={
              selectedAddressItem
                ? handleEditAddress
                : guestCheckout || showLogin === false
                  ? handleGuestCheckout
                  : handleSubmit
            }
            loading={addressButtonLoading}
            htmlType="submit"
            disabled={disableButton}
          />
        </div>
        <div style={{ display: isMobileLayout ? "" : "none" }}>
          <MobileAddressButton
            disabled={disableButton}
            showCancelButton={showCancelButton}
            setShowAddAddressOption={setShowAddAddressOption}
            setSelectedAddressItem={setSelectedAddressItem}
            selectedAddressItem={selectedAddressItem}
            payloadData={payloadData}
            otherLabel={otherLabel}
            selectedSavedAddress={selectedSavedAddress}
            guestCheckout={guestCheckout}
            handleGuestData={handleGuestData}
          />
        </div>
      </Form>
      <ErrorModal
        icon={
          <InfoCircleOutlined style={{ color: "#FF4D4F", fontSize: "22px" }} />
        }
        title={`${t("ADDRESS_ALREADY_EXISTS")}`}
        desc={`${t("ADDRESS_ALREADY_SAVED")}.`}
        showModal={isConflictedEntity}
        primaryOnClick={() => {
          dispatch(handleAddressConflictModal(false));
        }}
        secondaryOnClick={() => {
          dispatch(handleAddressConflictModal(false));
        }}
        primaryBtnText={`${t("RETRY")}`}
        secondaryBtnText={`${t("CANCEL")}`}
        showSecondaryButton={true}
      />
    </div>
  );
}
