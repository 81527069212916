import { Divider, Input, Skeleton, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./paymentSummary.css";
import "../fontStyling.css";
import {
  selectCurrencyCode,
  selectCurrencySymbol,
  selectFontFamily,
} from "../../../slices/PaymentSessionDetailsSlice";
import {
  blockInvalidChar,
  generateDynamicStyling,
  numberToCurrency,
} from "../../../Utils/utils";
import getSymbolFromCurrency from "currency-symbol-map";
import { selectIsMobileLayout } from "../../../slices/MobileLayoutSlice";
import {
  handleTotalPaymentSummaryError,
  handleTotalPaymentSummaryValue,
} from "../../../slices/HandleAdditionalComponentsSlice";
import TranslateText from "../../resuableComponents/TranslateText";

const PaymentSummary = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDeta);
  const [items, setItems] = useState(0);
  const paymentDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails.paymentDetails);
  const sessionDetailsLoading = useSelector((state) => state.paymentSessionDetails.sessionDetailsLoading);
  const showTotalPaymentSummaryField = useSelector((state) => state.additionalComponents.showTotalPaymentSummaryField);
  const totalPaymentSummaryValue = useSelector((state) => state.additionalComponents.totalPaymentSummaryValue);
  const showTotalPaymentSummaryError = useSelector((state) => state.additionalComponents.showTotalPaymentSummaryError);
  const sampleToken = useSelector((state) => state.paymentSessionDetails.sampleToken);
  const fontFamily = useSelector((state) => selectFontFamily(state));
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));
  const currencyCode = useSelector((state) => selectCurrencyCode(state));
  const [paymentSummary, setPaymentSummary] = useState();
  const isDccQuotationSelected = useSelector((state) => state.cardDetails.isDccQuotationSelected);
  const dccQuotation = useSelector(state => state.cardDetails.dccQuotation);
  const dccMoney = dccQuotation?.dccQuotationDetails?.dccMoney?.amount;
  const issuerCurrencyDetails = useSelector(state => state.cardDetails.issuerCurrencyDetails);

  useEffect(() => {
    if (sessionDetails) {
      generateDynamicStyling(sessionDetails);
    }
  }, [sessionDetails]);

  useEffect(() => {
    if (paymentDetails && currencyCode) {
      let paymentSummaryDictionary = [
        {
          title: 'Subtotal',
          label: 'SUBTOTAL',
          // title: t("SUBTOTAL"),
          value:
            !sampleToken && paymentDetails?.order?.originalAmount != null
              ? numberToCurrency(
                paymentDetails?.order?.originalAmount,
                currencyCode
              )
              : null,
        },
        {
          title: "Shipping Cost",
          label: 'SHIPPING_COST',
          value:
            !sampleToken && paymentDetails?.order?.shippingAmount != null
              ? numberToCurrency(
                paymentDetails?.order?.shippingAmount,
                currencyCode
              )
              : null,
        },
        {
          title: "Taxes and Fees",
          label: "TAXES_AND_FEES",
          value: numberToCurrency(
            paymentDetails?.order?.taxAmount,
            currencyCode
          ),
        },
        {
          label: "DISCOUNT",
          title: "Discount",
          value: numberToCurrency(
            paymentDetails?.order?.totalDiscountedAmount,
            currencyCode
          ),
        },
      ];
      let itemsPresent = paymentSummaryDictionary?.filter(
        (object) => object?.value != null
      )?.length;
      setItems(itemsPresent);
      setPaymentSummary(paymentSummaryDictionary);
    }
  }, [paymentDetails, currencyCode]);

  const totalPaymentErrors = (number) => {
    if (!number) {
      dispatch(handleTotalPaymentSummaryError("Please enter amount"));
    } else if (number.startsWith("0") && number.length > 1) {
      dispatch(handleTotalPaymentSummaryError("Must be greater than 0"));
    } else {
      dispatch(handleTotalPaymentSummaryError(""));
    }
  };
  const handleTotalPaymentAmount = (e) => {
    const telNo = e.target.value;

    totalPaymentErrors(telNo);
    dispatch(handleTotalPaymentSummaryValue(e.target.value));
  };

  const blockInvalidCharNumber = (e) =>
    ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();

  return (
    <>
      {paymentDetails && (
        <>
          {sessionDetailsLoading ? (
            <Skeleton active size="large" />
          ) : (
            <>
              {!isMobileLayout && (
                <div className="deliveryAddressHeader">
                  <div className="headerDiv">
                    <img
                      src="/assets/cardHeader/PaymentSummary.svg"
                      alt="delivery"
                      style={{ height: "16px", widht: "16px" }}
                    />
                    <Typography className="deeliveryHeading ">
                      <TranslateText label={"PAYMENT_SUMMARY"}>Payment Summary</TranslateText>
                    </Typography>
                  </div>
                </div>
              )}
              <div className="domestic-payment-summary-root dynamic-font-family">
                <div className="payment-summary-desc dynamic-font-family">
                  {paymentSummary?.map((object, i) => {
                    return (
                      object?.value && (
                        <div className="payment-summary-item dynamic-font-family" key={`summary-${i}`}>
                          <Typography
                            className="payment-summary-item-title dynamic-font-family"
                            style={{ fontFamily: fontFamily }}
                          >
                            <TranslateText label={object.label}>{t(`${object?.title}`)}</TranslateText>
                          </Typography>
                          <Typography
                            className="payment-summary-item-value dynamic-font-family"
                            style={{ fontFamily: fontFamily }}
                          >
                            <span className="currency-symbol">
                              {getSymbolFromCurrency(currencyCode)}{" "}
                            </span>
                            {object?.value}
                          </Typography>
                        </div>
                      )
                    );
                  })}
                </div>

                {paymentDetails?.money?.amountLocaleFull && (
                  <div
                    className="payment-summary-total"
                    style={{ marginTop: items > 0 ? "16px" : "0px" }}
                  >
                    {items > 0 && (
                      <Divider
                        style={{
                          marginBottom: items > 0 ? "12px" : "0",
                          background: "#F1F1F1",
                        }}
                      />
                    )}
                    <div className="payment-summary-item">
                      <Typography className="semiBold18Heading payment-summary-total-title dynamic-font-family">
                        <TranslateText label={"TOTAL"}>Total</TranslateText>
                      </Typography>

                      {showTotalPaymentSummaryField ? (
                        <div style={{ width: "70%" }}>
                          <Input
                            type="number"
                            onKeyDown={blockInvalidCharNumber}
                            addonBefore={
                              <span className="currency-symbol">
                                {getSymbolFromCurrency(currencyCode)}{" "}
                              </span>
                            }
                            onChange={(e) => handleTotalPaymentAmount(e)}
                            placeholder="Amount"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            className="mobile-no-input"
                          />
                        </div>
                      ) : (
                        <Typography className="semiBold18Heading payment-summary-total-value dynamic-font-family">
                          {
                            isDccQuotationSelected ? (
                              <>
                                {/* TODO add dcc currency symbol */}
                                <span className="currency-symbol"> {issuerCurrencyDetails.currencyCode}  </span>
                                {/* TODO add dcc currency symbol */}
                                {dccMoney}
                              </>
                            ) : (
                              <>
                                <span className="currency-symbol"> {getSymbolFromCurrency(currencyCode)}  </span>
                                {showTotalPaymentSummaryField ? totalPaymentSummaryValue : numberToCurrency(paymentDetails?.money?.amount, currencyCode)}
                              </>
                            )
                          }
                        </Typography>
                      )}
                    </div>
                    {showTotalPaymentSummaryField && (
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "30%" }}></div>
                        <div>
                          <Typography
                            className="regular12Heading dynamic-font-family"
                            style={{ color: "var(--Red-800)" }}
                          >
                            {showTotalPaymentSummaryError}
                          </Typography>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PaymentSummary;
