import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
const persistConfig = {
    key: 'commonPersisted', // Unique key for this slice
    storage, // Use session storage
};

const initialState = {
    selectedLanguage: "",
};

const commonPersistedSlice = createSlice({
    name: "commonPersisted",
    initialState,
    reducers: {
        setSelectedLanguage(state, action) {
            state.selectedLanguage = action.payload;
        },
    },
    extraReducers: {}
});

export const persistedCommonSliceReducer = persistReducer(persistConfig, commonPersistedSlice.reducer);
export const {
    setSelectedLanguage,
} = commonPersistedSlice.actions;

export default commonPersistedSlice.reducer;
