import React from "react";
import { useSelector } from "react-redux";
import { selectIsMobileLayout } from "../../../slices/MobileLayoutSlice";
import { Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";
import TranslateText from "../../resuableComponents/TranslateText";

export default function DefaultOderSummary() {
  const { t } = useTranslation()
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));
  return (
    <>
      {!isMobileLayout && (
        <div className="deliveryAddressHeader">
          <div className="headerDiv">
            <img
              src="/assets/cardHeader/OrderSummary.svg"
              alt="card"
              style={{ height: "16px", widht: "16px" }}
            />
            <Typography className="deeliveryHeading dynamic-font-family">
              <TranslateText label={"ORDER_SUMMARY"}>Order Summary</TranslateText>
            </Typography>
          </div>
        </div>
      )}
      <div className="orderSummaryCard">
        <div className="orderSummaryScroll">
          {sessionDetails?.paymentDetails?.context?.orderId && (
            <div style={{ display: "flex", gap: "4px" }}>
              <Typography
                className="dynamic-font-family payment-link-productName"
                style={{
                  fontWeight: 600,
                }}
              >
                <TranslateText label={"REFERENCE_ID"}>Ref. Id</TranslateText>:
              </Typography>
              <Tooltip
                placement="topLeft"
                title={sessionDetails?.paymentDetails?.context?.orderId}
              >
                <Typography className="dynamic-font-family payment-link-productName payment-link-orderid">
                  {sessionDetails?.paymentDetails?.context?.orderId}
                </Typography>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
