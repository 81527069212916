import React, { useEffect, useState } from "react";
import "../../fontStyling.css";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../../modals/errorModal/ErrorModal";
import {
  selectCurrencyCode,
  selectCurrencySymbol,
  selectPaymentDetails,
} from "../../../../slices/PaymentSessionDetailsSlice";
import { numberToCurrency } from "../../../../Utils/utils";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  toggleUpiVpaError,
  selectShowUpiVpaError,
} from "../../../../slices/UpiSlice";
import { selectIsMobileLayout } from "../../../../slices/MobileLayoutSlice";
import UpiMobile from "./upiMobile/UpiMobile";
import UpiWeb from "./upiWeb/UpiWeb";
import getSymbolFromCurrency from "currency-symbol-map";

const Upi = ({ upiMethodInfo }) => {

  const dispatch = useDispatch();
  const isNativeApp = useSelector((state) => state.mobileLayout.isNativeApp);
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));
  const paymentDetails = useSelector((state) => selectPaymentDetails(state));
  const currencyCode = useSelector((state) => selectCurrencyCode(state));
  const callerType = useSelector((state) => state.paymentSessionDetails.callerType);
  const showUpiVpaError = useSelector((state) => selectShowUpiVpaError(state));

  return (
    <div>
      <ErrorModal
        icon={
          <CloseCircleOutlined style={{ color: "#FF4D4F", fontSize: "22px" }} />
        }
        title={"Payment failed"}
        desc={
          <>
            You may have cancelled the payment or there was a delay in response
            from the UPI app. Retry payment of{" "}
            <span
              className="semiBold14Heading currency-symbol"
              style={{ color: "var(--character-primary-85)" }}
            >
              {getSymbolFromCurrency(currencyCode)}{" "}
            </span>
            <span
              className="semiBold14Heading"
              style={{ color: "var(--character-primary-85)" }}
            >
              {numberToCurrency(paymentDetails?.money?.amount, currencyCode)}
            </span>
          </>
        }
        showModal={showUpiVpaError}
        primaryOnClick={() => dispatch(toggleUpiVpaError())}
        secondaryOnClick={() => dispatch(toggleUpiVpaError())}
        primaryBtnText={"Retry other methods"}
        secondaryBtnText={"Cancel"}
        showSecondaryButton={callerType === "CHECKOUT" && true}
      />
      {isMobileLayout ? (
        <>
          {
            isNativeApp ? <UpiMobile upiMethodInfo={upiMethodInfo} /> : <UpiWeb upiMethodInfo={upiMethodInfo} />
          }
        </>
      ) : (
        <UpiWeb upiMethodInfo={upiMethodInfo} />
      )}
    </div>
  );
};

export default Upi;
