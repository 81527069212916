import React, { useEffect, useState } from 'react'
import { Typography } from "antd";
import TranslateText from '../../resuableComponents/TranslateText'
import { Radio } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { CurrencyData } from "../../../staticData/currencyData";
import { isEmpty } from '../../../Utils/utils';
import { setDccCardScheme, setDccCurrencyDetails, setDccQuotationSelection } from '../../../slices/CardSlice';
import './dccQuotation.css';

const DccQuotation = () => {
    const dispatch = useDispatch();
    const dccQuotation = useSelector(state => state.cardDetails.dccQuotation);
    const brand = dccQuotation?.brand;
    const { fxRate, marginPercent } = dccQuotation?.dccQuotationDetails;
    const amount = dccQuotation?.dccQuotationDetails?.dccMoney?.amount;
    const issuerCurrency = dccQuotation?.dccQuotationDetails?.dccMoney?.currencyCode;
    const merchantCurrency = dccQuotation?.baseMoney?.currencyCode;
    const merchantCurrencyDetails = CurrencyData.find(c => c.currencyCode === merchantCurrency);
    const issuerCurrencyDetails = CurrencyData.find(c => c.currencyCode === issuerCurrency);
    const dccCardScheme = useSelector((state) => state.cardDetails.dccCardScheme);
    const [radioButtonValue, setRadioButtonValue] = useState('')

    useEffect(() => {
        dispatch(setDccCurrencyDetails({ merchantCurrencyDetails, issuerCurrencyDetails }))
    }, [])
    useEffect(() => {
        dispatch(setDccCardScheme(brand.toUpperCase()))
    }, [brand]);

    useEffect(() => {
        // TODO add dcc currency to the pay button and payment summary
        if (isEmpty(dccCardScheme)) return
        if (dccCardScheme === 'MASTERCARD') {
            setRadioButtonValue('issuer')
            dispatch(setDccQuotationSelection(true))
        }
        else dispatch(setDccQuotationSelection(false))
    }, [dccCardScheme])

    const onChange = (e) => {
        if (e.target.value === 'issuer') {
            setRadioButtonValue('issuer');
            dispatch(setDccQuotationSelection(true))
        }
        else {
            setRadioButtonValue('merchant');
            dispatch(setDccQuotationSelection(false))
        }
    };

    return (
        <div className='dcc-quotation-main'>
            <div className='dcc-quotation-header'>
                <Typography className="regular14Heading">
                    {
                        dccCardScheme === 'MASTERCARD' ? (
                            <><TranslateText label={"CURRENCY_SELECT_MASTERCARD"}>Please choose the currency to be charged to your account</TranslateText>.</>
                        ) : (
                            <><TranslateText label={"CURRENCY_SELECT"}>Please select currency</TranslateText>.</>
                        )
                    }
                </Typography>
            </div>

            <div className='dcc-quotation-container-1'>
                {/* Issuer */}
                <div className='dcc-quotation-section'>
                    <Radio value={"issuer"} className='dcc-quotation-antd-radio' checked={radioButtonValue === 'issuer'} onChange={onChange}>
                        <div className='dcc-quotation-flag-box'>
                            <div className='dcc-quotation-flag'>
                                <img src={issuerCurrencyDetails?.flag} alt="flag" width={27} />
                            </div>
                            <span>{issuerCurrencyDetails?.currencyCode}</span>
                        </div>
                    </Radio>

                    <div className='dcc-quotation-radio-details'>
                        <div>1 {merchantCurrencyDetails?.currencyCode} = {fxRate.toFixed(2)} {issuerCurrencyDetails?.currencyCode}</div>
                        {dccCardScheme === 'VISA' && <div><TranslateText label={"INCLUDES_MARGIN"}>Includes margin</TranslateText>: {marginPercent}%</div>}
                        <div>{issuerCurrencyDetails?.currencyCode} {amount}</div>
                    </div>
                </div>

                <div className='dcc-quotation-section'>
                    <Radio value={'merchant'} className='dcc-quotation-antd-radio' checked={radioButtonValue === 'merchant'} onChange={onChange}>
                        <div className='dcc-quotation-flag-box'>
                            <div className='dcc-quotation-flag'>
                                <img src={merchantCurrencyDetails?.flag} alt="flag" width={27} />
                            </div>
                            <span>{merchantCurrencyDetails?.currencyCode}</span>
                        </div>
                    </Radio>

                    <div className='dcc-quotation-radio-details'>
                        <div><TranslateText label={"EXCHANGE_RATE_DETERMINED_BY_ISSUER"}>Exchange rate will be determined by the card issuer</TranslateText>.</div>
                        <div>
                            <span>{dccQuotation.baseMoney.currencyCode}</span>
                            <span className='dcc-quotation-amount-text'>{dccQuotation.baseMoney.amount}</span>
                        </div>
                    </div>
                </div>
            </div>

            {
                dccCardScheme === 'MASTERCARD' && (
                    <div className='dcc-quotation-disclaimer'>
                        <TranslateText label="CURRENCY_CONVERSION_COST_WARNING">
                            Make sure you understand the cost of currency conversion as they may be different depending on whether you select your home currency or the transaction currency
                        </TranslateText>.
                    </div>
                )
            }
        </div>
    )
}

export default DccQuotation