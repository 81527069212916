import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import axios from "axios";

const persistConfig = {
  key: 'cardDetails', // Unique key for this slice
  storage, // Use session storage
};

const baseUrl = process.env.REACT_APP_BASE_URL;
export const getDccQuotations = createAsyncThunk(
  "cardDetails/getDccQuotations",
  async ({ token, req }) => {
    const response = await axios.post(`${baseUrl}/checkout/sessions/${token}/dcc/quotations`, req);
    return response.data;
  }
);

const initialState = {
  cardNumber: "",
  cardHolderName: "",
  cardExpiryDate: "",
  cardCvc: "",
  latestBrandName: "",
  buttonDisableState: false,
  isDccQuotationLoading: false,
  dccQuotation: {},
  isDccQuotationSelected: false,
  dccCardScheme: '',
  dccCurrencySymbol: '',
  merchantCurrencyDetails: {},
  issuerCurrencyDetails: {},
  DCC_CARD_MAX_LENGTH: 10
};

const cardDetailsSlice = createSlice({
  name: "cardDetails",
  initialState,
  reducers: {
    changeCardNumber(state, action) {
      state.cardNumber = action.payload;
    },
    changeCardHolderName(state, action) {
      state.cardHolderName = action.payload;
    },
    changeExpiryDate(state, action) {
      state.cardExpiryDate = action.payload;
    },
    changeCardCvc(state, action) {
      state.cardCvc = action.payload;
    },
    changeBrandName(state, action) {
      state.latestBrandName = action.payload;
    },
    cardPayButtonState(state, action) {
      state.buttonDisableState = action.payload;
    },
    resetDccQuotation(state) {
      state.dccQuotation = {};
      state.isDccQuotationSelected = false;
      state.dccCardScheme = '';
      state.dccCurrencySymbol = '';
    },
    setDccQuotationSelection(state, action) {
      state.isDccQuotationSelected = action.payload;
    },
    setDccCardScheme(state, action) {
      state.dccCardScheme = action.payload;
    },
    setDccCurrencySymbol(state, action) {
      state.dccCurrencySymbol = action.payload;
    },
    setDccCurrencyDetails(state, action) {
      state.merchantCurrencyDetails = action.payload.merchantCurrencyDetails;
      state.issuerCurrencyDetails = action.payload.issuerCurrencyDetails;
    }
  },
  extraReducers: {
    [getDccQuotations.pending]: (state) => {
      state.isDccQuotationLoading = true;
    },
    [getDccQuotations.fulfilled]: (state, action) => {
      state.isDccQuotationLoading = false;
      state.dccQuotation = state.cardNumber.length > state.DCC_CARD_MAX_LENGTH ? action.payload : {};
    },
    [getDccQuotations.rejected]: (state) => {
      state.isDccQuotationLoading = false;
      state.dccQuotation = {};
    },
  }
});

export const persistedCardDetailsSliceReducer = persistReducer(persistConfig, cardDetailsSlice.reducer);
export const {
  changeCardNumber,
  changeCardHolderName,
  changeExpiryDate,
  changeCardCvc,
  changeBrandName,
  cardPayButtonState,
  resetDccQuotation,
  setDccQuotationSelection,
  setDccCardScheme,
  setDccCurrencyDetails
} = cardDetailsSlice.actions;

export const cardNumberData = (state) => state.cardDetails.cardNumber;
export const cardHolderNameData = (state) => state.cardDetails.cardHolderName;
export const cardExpiryDateData = (state) => state.cardDetails.cardExpiryDate;
export const cardCvcData = (state) => state.cardDetails.cardCvc;
export const cardbrandName = (state) => state.cardDetails.latestBrandName;
export const disableStateOfButton = (state) => state.cardDetails.buttonDisableState;

export default cardDetailsSlice.reducer;
