import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";

let fingerPrintValue = "";

getCurrentBrowserFingerPrint().then((fingerprint) => {
  fingerPrintValue = fingerprint;
});

function clearEmpties(o) {
  for (var k in o) {
    if (!o[k] || typeof o[k] !== "object") {
      continue;
    }
    clearEmpties(o[k]);
    if (Object.keys(o[k]).length === 0) {
      o[k] = undefined;
    }
  }
  return o;
}

export const buildPaymentRequest = (
  instrumentTypeValue,
  updatedShopperDetails,
  sessionDetails,
  emiCode
) => {
  const urlParams = new URLSearchParams(window.location.search);
  const utmSourceParam = urlParams.get("utm_source");
  const utmMediumParam = urlParams.get("utm_medium");
  const utmCampaignParam = urlParams.get("utm_campaign");
  const paymentRequest = {
    token: sessionDetails?.token,
    payload: {
      instrumentDetails: {
        type: instrumentTypeValue,
      },
      browserData: {
        userAgentHeader: navigator.userAgent,
        browserLanguage: navigator.language,
        timeZoneOffSet: new Date().getTimezoneOffset(),
        browserFingerprint: fingerPrintValue,
      },
    },
  };

  if (emiCode) {
    paymentRequest.payload.offers = [emiCode];
  }
  if (
    utmSourceParam !== null ||
    utmMediumParam !== null ||
    utmCampaignParam !== null
  ) {
    paymentRequest.payload.trackingDetails = {};
    if (utmSourceParam !== null) {
      paymentRequest.payload.trackingDetails.source = utmSourceParam;
    }
    if (utmMediumParam !== null) {
      paymentRequest.payload.trackingDetails.medium = utmMediumParam;
    }
    if (utmCampaignParam !== null) {
      paymentRequest.payload.trackingDetails.campaign = utmCampaignParam;
    }
  }
  paymentRequest.payload.shopper = updatedShopperDetails;

  return clearEmpties(JSON.parse(JSON.stringify(paymentRequest)));
};


export const buildDccRequest = ({ instrument, updatedShopperDetails, sessionDetails }) => {
  let { countryCode, clientPosId, orderId, localeCode, legalEntity: { code } } = sessionDetails?.paymentDetails?.context;
  let { amount, currencyCode } = sessionDetails?.paymentDetails?.money;
  let { firstName, lastName, email, uniqueReference, phoneNumber, pan, dob } = updatedShopperDetails;
  let data = {
    context: {
      countryCode,
      legalEntity: { code },
      clientPosId,
      orderId,
      localCode: localeCode
    },
    money: { amount, currencyCode },
    shopper: { firstName, lastName, email, uniqueReference, phoneNumber, pan, dob },
    instrument
  }

  return clearEmpties(JSON.parse(JSON.stringify(data)));
}