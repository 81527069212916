import { Skeleton, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TranslateText from "../../resuableComponents/TranslateText";
import "./paymentOptionsContainer.css";
import "../fontStyling.css";
import { useDispatch, useSelector } from "react-redux";
import {
  colorShadesGenerator,
  generateDynamicStyling,
} from "../../../Utils/utils";
import { changeActivePaymentMethod } from "../../../slices/PaymentButtonSlice";
import { handleTotalPaymentSummaryError } from "../../../slices/HandleAdditionalComponentsSlice";

const PaymentOptionsContainer = ({
  tabList,
  darkColor,
  lightColor,
  handleSelectedTabId,
}) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState();
  const paymentMethodDictionary = useSelector((state) => state?.paymentSessionDetails?.paymentMethodDictionary);

  useEffect(() => {
    if (paymentMethodDictionary) {
      const isOnlyUpiIntent =
        paymentMethodDictionary["Upi"] &&
        paymentMethodDictionary["Upi"].length === 1 &&
        paymentMethodDictionary["Upi"][0].brand === "UpiIntent";
      let idx = tabList.findIndex(
        (tab) => paymentMethodDictionary && tab?.type in paymentMethodDictionary
      );
      if (isOnlyUpiIntent) {
        idx = tabList.findIndex(
          (tab, index) =>
            tab?.type !== "Upi" &&
            paymentMethodDictionary[tab?.type]?.length > 0
        );
      }
      setSelectedTab(idx);
    }
  }, [paymentMethodDictionary]);

  const dispatch = useDispatch();
  const sessionDetails = useSelector(
    (state) => state.paymentSessionDetails.sessionDetails
  );

  useEffect(() => {
    if (sessionDetails) {
      generateDynamicStyling(sessionDetails);
      const shades = colorShadesGenerator(
        sessionDetails?.merchantDetails?.checkoutTheme?.headerColor
      );
    }
    dispatch(changeActivePaymentMethod(tabList[selectedTab]?.type));
  }, [sessionDetails, selectedTab]);

  const [isPaymentLoading, setIsPaymentLoading] = useState(true);

  useEffect(() => {
    if (Object.keys(paymentMethodDictionary)?.length >= 0) {
      const timer = setTimeout(() => {
        setIsPaymentLoading(false);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [paymentMethodDictionary]);

  useEffect(() => {
    if (selectedTab) {
      handleSelectedTabId(selectedTab);
    }
  }, [selectedTab]);

  const shouldDisplayUpi = (upiMethods) => {
    return upiMethods?.some((method) => method?.brand !== "UpiIntent");
  };

  return (
    <>
      {isPaymentLoading ? (
        <Skeleton active size="large" />
      ) : Object?.keys(paymentMethodDictionary)?.length ? (
        <div className="domestic-po-root">
          <div className="domestic-po-header-tabs">
            <div className="domestic-po-header">
              <img
                src="/assets/cardHeader/PaymentOption.svg"
                alt="card"
                style={{ height: "16px", widht: "16px" }}
              />
              <Typography
                className="semiBold16Heading dynamic-font-family"
                style={{ color: "var(--Neutral-Grey-1200)" }}
              >
                <TranslateText label={"PAYMENT_OPTIONS"}>Payment Options</TranslateText>
              </Typography>
            </div>
            {tabList?.map(
              (tab, id) =>
                (tab?.type !== "Upi" ||
                  shouldDisplayUpi(paymentMethodDictionary[tab?.type])) &&
                tab?.type in paymentMethodDictionary && (
                  <div
                    key={id}
                    className="domestic-po-tab"
                    style={{
                      backgroundColor: selectedTab === id ? lightColor : "",
                      borderLeft:
                        selectedTab === id
                          ? `5px solid ${darkColor}`
                          : `5px solid #fff`,
                    }}
                    onClick={() => {
                      setSelectedTab(id);
                      dispatch(handleTotalPaymentSummaryError(""));
                    }}
                  >
                    {paymentMethodDictionary[tab?.type]?.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        {tab?.logo}
                        <Typography
                          className={
                            selectedTab === id
                              ? "tab-title-heading-active dynamic-font-family"
                              : "tab-title-heading dynamic-font-family"
                          }
                        >
                          {/* <TranslateText label={tab.type}>{tab.type}</TranslateText> */}
                          <TranslateText label={tab.type.toUpperCase()}>
                            {tab?.type === "Recommended"
                              ? "Recommended"
                              : ["Wallet", "BuyNowPayLater"].includes(
                                paymentMethodDictionary[tab?.type][0]?.type
                              ) &&
                                paymentMethodDictionary[tab?.type].length === 1
                                ? paymentMethodDictionary[tab?.type][0]?.title
                                : paymentMethodDictionary[tab?.type][0]?.typeTitle}
                          </TranslateText>
                        </Typography>
                      </div>
                    )}
                  </div>
                )
            )}
          </div>
          <div className="domestic-po-content">
            {tabList?.length > 0 &&
              tabList[selectedTab]?.content &&
              tabList[selectedTab]?.type in paymentMethodDictionary ? (
              tabList[selectedTab]?.content
            ) : (
              <Skeleton active size="large" />
            )}
          </div>
        </div>
      ) : (
        <div className="domestic-empty-po-root">
          <div className="no-payment-options-div">
            <img
              src="/assets/paymentOptions/paymentoptions.svg"
              alt="payment"
            />
            <Typography className="no-payment-options-Heading dynamic-font-family">
              <TranslateText label={"NO_PAYMENT_OPTION_AVAILABLE"}>No payment option available</TranslateText>
            </Typography>
            <Typography className="no-payment-options-subHeading dynamic-font-family">
              <TranslateText label={"CHANGE_COUNTRY_FOR_PAYMENT_OPTIONS"}>Please change your country in the address to see the available payment options</TranslateText>
            </Typography>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentOptionsContainer;
