import { Card, Tooltip, Typography } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatDate, generateDynamicStyling } from "../../../../../Utils/utils";
import getSymbolFromCurrency from "currency-symbol-map";
import { selectCurrencyCode } from "../../../../../slices/PaymentSessionDetailsSlice";
import { selectIsMobileLayout } from "../../../../../slices/MobileLayoutSlice";
import TranslateText from "../../../../resuableComponents/TranslateText";

export default function PlansOrderSummary() {
  const { t } = useTranslation()
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));
  const currencyCode = useSelector((state) => selectCurrencyCode(state));
  const timeZone = sessionDetails?.merchantDetails?.timeZone;
  const nextBillingDateLocale = sessionDetails?.paymentDetails?.subscriptionDetails?.nextBillingDateLocale;
  const expiryDateLocale = sessionDetails?.paymentDetails?.subscriptionDetails?.expiryDateLocale;
  const nextBillingDate = formatDate(nextBillingDateLocale, timeZone);
  const expiryDate = formatDate(expiryDateLocale, timeZone);
  useEffect(() => {
    if (sessionDetails) {
      generateDynamicStyling(sessionDetails);
    }
  }, [sessionDetails]);
  return (
    <>
      {sessionDetails?.paymentDetails?.order?.items?.length > 0 && (
        <>
          {" "}
          {!isMobileLayout && (
            <div className="deliveryAddressHeader">
              <div className="headerDiv">
                <img
                  src="/assets/cardHeader/OrderSummary.svg"
                  alt="card"
                  style={{ height: "16px", widht: "16px" }}
                />
                <Typography className="deeliveryHeading dynamic-font-family">
                  <TranslateText label={"ORDER_SUMMARY"}>Order Summary</TranslateText>
                </Typography>
              </div>
            </div>
          )}
          <div className="orderSummaryCard">
            <div className="orderSummaryScroll">
              {sessionDetails?.paymentDetails?.context?.orderId && (
                <div style={{ display: "flex", gap: "4px" }}>
                  <Typography
                    className="dynamic-font-family payment-link-productName"
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    <TranslateText label={"REFERENCE_ID"}>Ref. Id</TranslateText>:
                  </Typography>
                  <Tooltip
                    placement="topLeft"
                    title={sessionDetails?.paymentDetails?.context?.orderId}
                  >
                    <Typography className="dynamic-font-family payment-link-productName payment-link-orderid">
                      {sessionDetails?.paymentDetails?.context?.orderId}
                    </Typography>
                  </Tooltip>
                </div>
              )}
              {sessionDetails?.paymentDetails?.order?.items?.map(
                (item, idx) => {
                  return (
                    <div key={idx}>
                      <div className="displayFlex subscriptionMarginBottom">
                        {item?.imageUrl?.length ? (
                          <img
                            src={item?.imageUrl}
                            alt="item"
                            className="itemImage"
                          />
                        ) : (
                          ""
                        )}
                        <div>
                          <Tooltip placement="topLeft" title={item?.itemName}>
                            <Typography className="dynamic-font-family semiBold14Heading subscriptionTitle itemNameEllipse">
                              {item?.itemName}
                            </Typography>
                          </Tooltip>
                          <Tooltip
                            placement="topLeft"
                            title={item?.description}
                          >
                            <Typography className="dynamic-font-family regular14Heading subscriptionDescription itemNameEllipse">
                              {item?.description}
                            </Typography>
                          </Tooltip>
                        </div>
                      </div>

                      <div className="subscriptionDetails subscriptionMarginBottom">
                        <Typography className="dynamic-font-family medium14Heading subHeadingValue">
                          <TranslateText label={"PLAN_FREQUENCY"}>Plan Frequency</TranslateText>:
                        </Typography>
                        <Typography className="dynamic-font-family semiBold14Heading itemAmount">
                          <TranslateText label={"EVERY"}>Every</TranslateText>{" "}
                          {
                            sessionDetails?.paymentDetails?.subscriptionDetails
                              ?.billingCycle?.count
                          }{" "}
                          {
                            sessionDetails?.paymentDetails?.subscriptionDetails
                              ?.billingCycle?.billingCycleValue
                          }
                        </Typography>
                      </div>
                      <div className="subscriptionDetails subscriptionMarginBottom">
                        <Typography className="dynamic-font-family medium14Heading subHeadingValue">
                          <TranslateText label={"NEXT_PAYMENT_DATE"}>Next Payment Date</TranslateText>:
                        </Typography>
                        <Typography className="dynamic-font-family semiBold14Heading itemAmount">
                          {nextBillingDate}
                        </Typography>
                      </div>
                      <div className="subscriptionDetails subscriptionMarginBottom">
                        <Typography className="dynamic-font-family medium14Heading subHeadingValue">
                          <TranslateText label={"PLAN_EXPIRY_DATE"}>Plan Expiry Date</TranslateText>:
                        </Typography>
                        <Typography className="dynamic-font-family semiBold14Heading itemAmount">
                          {expiryDate}
                        </Typography>
                      </div>
                      <Typography className="regular10Heading informationColor dynamic-font-family">
                        •  <TranslateText label={"YOU_WILL_BE_CHARGED"}>You will be charged</TranslateText>
                        <span className="currency-symbol subscriptionAmountCurrency">
                          {getSymbolFromCurrency(currencyCode)}{" "}
                        </span>
                        <span className="subscriptionAmount">
                          {
                            sessionDetails?.paymentDetails?.money
                              ?.amountLocaleFull
                          }
                        </span>{" "}
                        <TranslateText label={"ON_NEXT_PAYMENT_DATE"}>on the next payment date</TranslateText>
                      </Typography>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
