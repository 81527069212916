import React from 'react'
import { useSelector } from 'react-redux';
import { selectFrontendBackUrl, selectFrontReturnUrl } from '../../../slices/PaymentSessionDetailsSlice';
import Loading from '../loadingScreen/Loading';
import { useTranslation } from 'react-i18next';

const PaymentCompletionHandler = () => {
    const { t } = useTranslation();
    const backUrl = useSelector((state) => selectFrontendBackUrl(state));
    const returnUrl = useSelector((state) => selectFrontReturnUrl(state));
    const isIframePopUp = window.opener;
    const parsedUrl = new URL(window.location.href);
    const params = new URLSearchParams(parsedUrl.search);
    const paramsObject = {};
    // Iterate over all query parameters
    for (const [key, value] of params.entries()) {
        paramsObject[key] = decodeURIComponent(value);
    }
    if (returnUrl) {
        const urlObj = new URL(returnUrl);
        // Loop through the params object and append each param to the URL
        Object.keys(paramsObject).forEach(key => {
            urlObj.searchParams.append(key, paramsObject[key]);
        });
        const newUrl = urlObj.toString();
        window.location.href = newUrl;
    }
    // else {
    //     const urlObj = new URL(backUrl);
    //     // Loop through the params object and append each param to the URL
    //     Object.keys(paramsObject).forEach(key => {
    //         urlObj.searchParams.append(key, paramsObject[key]);
    //     });
    //     const newUrl = urlObj.toString();
    //     window.location.href = newUrl;
    // }

    return (
        <>
            <Loading
                text={t("LOADING")}
                subText=""
            />
        </>
    )
}

export default PaymentCompletionHandler