import React from "react";
import "./bottomFooter.css";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import TranslateText from "../../resuableComponents/TranslateText";

const BottomFooter = () => {
  const { t } = useTranslation();
  return (
    <div className="domestic-bottom-footer-root">
      <div className="logo-section">
        <div className="boxpay-section">
          <span className="footer-boxpay-front dynamic-font-family">
            <TranslateText label={"SECURED_BY"}>Secured by</TranslateText>
          </span>
          <div className="boxpay-branding">
            <img
              src="/assets/footer/boxpay-footer.svg"
              alt="boxpay-footer-logo"
            />
            <span className="footer-boxpay-back dynamic-font-family">
              BoxPay
            </span>
          </div>
        </div>
        <div className="boxpay-section-mobile">
          <Divider style={{ backgroundColor: "transparent", display: "flex" }}>
            <div className="boxpay-section-mobile">
              <span className="footer-boxpay-front dynamic-font-family">
                <TranslateText label={"SECURED_BY"}>Secured by</TranslateText>
              </span>
              <div className="boxpay-branding">
                <img
                  src="/assets/footer/boxpay-footer.svg"
                  alt="boxpay-footer-logo"
                />
                <span className="footer-boxpay-back dynamic-font-family">
                  BoxPay
                </span>
              </div>
            </div>
          </Divider>
        </div>
        <div className="boxpay-logo-divider">
          <Divider />
        </div>
        <div className="other-logo-section">
          <div className="footer-content-container">
            <img src="/assets/footer/visa-footer.svg" alt="visa-footer-logo" />
          </div>
          <div className="footer-content-container">
            <img
              src="assets/footer/mastercard-footer.svg"
              alt="mastercard-footer-logo"
            />
          </div>
          <div className="footer-content-container">
            <img src="assets/footer/rupay-footer.svg" alt="rupay-footer-logo" />
          </div>
          <div className="footer-content-container">
            <img src="assets/footer/amex-footer.svg" alt="amex-footer-logo" />
          </div>

          <div className="footer-content-container">
            <img src="assets/footer/pci-footer.svg" alt="pci-footer-logo" />
          </div>
        </div>
      </div>
      <div className="help-section dynamic-font-family ">
        <TranslateText label={"NEED_HELP_VISIT"}>Need help? Visit the</TranslateText>
        <a
          href="https://boxpay.atlassian.net/servicedesk/customer/portal/1"
          target="_blank"
          className="help-section-anchor "
        >
          <TranslateText label={"HELP_CENTER"}> Help Center</TranslateText>
        </a>{" "}
        <TranslateText label={"OR"}>Or</TranslateText>
        <a
          href="https://www.boxpay.tech/contact-us-form"
          target="_blank"
          className="help-section-anchor "
        >
          <TranslateText label={"CONTACT_US"}> Contact Us</TranslateText>
        </a>
      </div>
    </div>
  );
};

export default BottomFooter;
