import React, { useEffect } from "react";
import BoxPayCheckout from "./orchestrator/BoxPayCheckout";
import { clarity } from "react-microsoft-clarity";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Kutumb from "./kutumb/Kutumb";
import { toggleMobileLayout, toggleTabletLayout, setIphone, setAndroid, setNativeApp } from "./slices/MobileLayoutSlice";
import { useDispatch, useSelector } from "react-redux";
import './Utils/i18n'
import i18next from "i18next";
import { setAppState } from "./slices/finalStatusSlice";

function App() {
  clarity.init("lseof46hc2");
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.commonPersisted.selectedLanguage);
  let isUserInteracting = false;

  // Detect user interaction
  document.addEventListener('mousemove', () => {
    isUserInteracting = true;
  });
  document.addEventListener('keydown', () => {
    isUserInteracting = true;
  });

  // Check visibility change
  document.addEventListener("visibilitychange", () => {
    if (document.hidden) {
      if (isUserInteracting) dispatch(setAppState("SWITCHED_APP"))
      else dispatch(setAppState("BACKGROUND"))
    } else {
      dispatch(setAppState("CURRENT"))
      isUserInteracting = false; // Reset interaction status
    }
  });

  // sets flag based on screensize
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      dispatch(toggleMobileLayout(width <= 680));
      dispatch(toggleTabletLayout(width >= 681 && width <= 1060));
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // sets flag based on device seletion
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isIphone = /iphone/.test(userAgent.toLowerCase());
    const isAndroid = /android/.test(userAgent.toLowerCase());
    const isNativeApp = isIphone || isAndroid;
    dispatch(setIphone(isIphone));
    dispatch(setAndroid(isAndroid));
    dispatch(setNativeApp(isNativeApp));
  }, []);

  useEffect(() => {
    if (!selectedLanguage) return
    i18next.changeLanguage(selectedLanguage);
  }, [])

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<BoxPayCheckout />} />
          <Route exact path="/kutumb/*" element={<Kutumb />} />
          <Route exact path="*" element={<BoxPayCheckout />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
