import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Select, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrencyCode } from "../../../../slices/PaymentSessionDetailsSlice";
import { RightOutlined } from "@ant-design/icons";
import SelectedEmiBankForm from "./selectedEmiBankForm";
import { removeSelectedIntruments } from "../../../../slices/SavedInstrumentSlice";
import {
  changeBankDropdown,
  creditBankSelected,
} from "../../../../slices/EmiSlice";
import { methodSelectedEvent } from "../../../../Utils/uiAnalytics";
import getSymbolFromCurrency from "currency-symbol-map";
import TranslateText from "../../../resuableComponents/TranslateText";

export default function CreditCardEmi() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
  const emiCreditBankSelected = useSelector((state) => creditBankSelected(state));
  const currencyCode = useSelector((state) => selectCurrencyCode(state));
  const [showEmiPlanDetails, setShowEmiPlanDetails] = useState(false);
  const [selectedEmiBank, setSelectedEmiBank] = useState("");

  const emiOptions = sessionDetails?.configs?.paymentMethods
    ?.filter(
      (method) => method.type === "Emi" && method.brand === "CreditCardEMI"
    )
    ?.reduce((uniqueOptions, method) => {
      const brand = method?.brand;
      const issuer = method?.emiMethod?.issuer;
      const issuerTitle = method?.emiMethod?.issuerTitle;
      const logoUrl = method?.logoUrl;
      const emiAmount = method?.emiMethod?.emiAmount;
      const emiAmountLocaleFull = method?.emiMethod?.emiAmountLocaleFull;
      const effectiveInterestRate = method?.emiMethod?.effectiveInterestRate;
      const discountType = method?.emiMethod?.applicableOffer?.discount?.type;
      let emiOfferTypes = discountType ? [discountType] : [];
      if (effectiveInterestRate === 0) {
        emiOfferTypes.push("NoCostEmi");
      }
      let existingOption = uniqueOptions.find(
        (option) => option.value === issuer
      );

      if (!existingOption) {
        uniqueOptions.push({
          brand: brand,
          value: issuer,
          name: issuerTitle,
          logoUrl: logoUrl,
          emiAmount: emiAmount,
          emiAmountLocaleFull: emiAmountLocaleFull,
          emiOfferTypes: emiOfferTypes,
        });
      } else {
        emiOfferTypes.forEach((type) => {
          if (!existingOption.emiOfferTypes.includes(type)) {
            existingOption.emiOfferTypes.push(type);
          }
        });
      }

      return uniqueOptions;
    }, []);

  const filterOption = (input, option) =>
    (option?.props?.children ?? "").toLowerCase().includes(input.toLowerCase());
  const handleSelectedBank = (item) => {
    dispatch(changeBankDropdown(item.value));
    setSelectedEmiBank(item);
    setShowEmiPlanDetails(true);
    methodSelectedEvent(sessionDetails, "Emi", item?.brand, dispatch);
  };

  const handleEmiBank = (value) => {
    dispatch(changeBankDropdown(value));
    dispatch(removeSelectedIntruments());
    const selectedBankDetails = sessionDetails?.configs?.paymentMethods
      ?.filter(
        (method) =>
          method.type === "Emi" &&
          method.brand === "CreditCardEMI" &&
          method.emiMethod.issuer === value
      )
      ?.map((method) => ({
        brand: method.brand,
        value: method.emiMethod.issuer,
        name: method.emiMethod.issuerTitle,
        logoUrl: method.logoUrl,
        emiAmount: method.emiMethod.emiAmount,
        emiAmountLocaleFull: method.emiMethod.emiAmountLocaleFull,
      }));
    if (selectedBankDetails.length > 0) {
      setSelectedEmiBank(selectedBankDetails[0]);
    }
    setShowEmiPlanDetails(true);
    methodSelectedEvent(sessionDetails, "Emi", "CreditCardEMI", dispatch);
  };

  const sortedEmiOptions = emiOptions?.sort((a, b) =>
    a?.name?.localeCompare(b?.name)
  );
  const emiOptionsBasedOnAmount = [...emiOptions].sort(
    (a, b) => a.emiAmount - b.emiAmount
  );

  useEffect(() => {
    if (emiCreditBankSelected === "All Emi Options") {
      setShowEmiPlanDetails(false);
    }
  }, [emiCreditBankSelected]);

  const getEmiOfferText = (offerTypes) => {
    const hasNoCostEmi = offerTypes.includes("NoCostEmi");
    const hasLowCostEmi = offerTypes.includes("LowCostEmi");

    if (hasNoCostEmi && hasLowCostEmi) {
      return "No and Low-Cost EMI available";
    } else if (hasNoCostEmi) {
      return "No-Cost EMI available";
    } else if (hasLowCostEmi) {
      return "Low-Cost EMI available";
    }

    return null;
  };
  return (
    <div className="bankSelect">
      <Select
        style={{ marginBottom: "20px" }}
        showSearch
        placeholder="All EMI Options"
        optionFilterProp="name"
        onChange={handleEmiBank}
        filterOption={filterOption}
        value={emiCreditBankSelected}
      >
        {sortedEmiOptions?.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
      {showEmiPlanDetails ? (
        <SelectedEmiBankForm selectedEmiBank={selectedEmiBank} />
      ) : (
        <>
          {emiOptionsBasedOnAmount?.map((item, idx) => {
            return (
              <div key={idx}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "12px 0px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSelectedBank(item)}
                >
                  <div style={{ display: "flex" }}>
                    <img src={item?.logoUrl} alt="" className="bank-logo" />
                    <div>
                      <Typography className="emiOptionHeading dynamic-font-family overflowBankText">
                        {item?.name}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography className="emiOptionSubHeading dynamic-font-family">
                          <TranslateText label={"EMI_STARTS_FROM"}>EMI starts from</TranslateText>
                        </Typography>
                        <Typography
                          className="amountCurrency dynamic-font-family"
                          style={{ marginLeft: "2px" }}
                        >
                          {getSymbolFromCurrency(currencyCode)}{" "}
                        </Typography>
                        <Typography className="amountPerAnum dynamic-font-family">
                          {item?.emiAmount}
                        </Typography>
                      </div>
                      <div style={{ display: "flex", gap: "8px" }}>
                        {item?.emiOfferTypes?.length > 0 && (
                          <div className="no-cost-emi-available">
                            <Typography className="no-cost-text">
                              {getEmiOfferText(item?.emiOfferTypes)}
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <RightOutlined className="forwardIcon" />
                  </div>
                </div>
                {idx !== emiOptions.length - 1 && <Divider />}
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}
