import React from "react";
import PyamentButton from "../../paymentButton/PaymentButton";
import PaymentIntrumentSelection from "../paymentInstrumentSelection/PaymentIntrumentSelection";
import { useTranslation } from "react-i18next";
import { getComparator, sortFunction } from "../../../../Utils/utils";

const NetBanking = ({ netBankingMethodInfo, darkColor, lightColor }) => {
  const { t } = useTranslation();
  const sortedData = React.useMemo(() => {
    return (
      netBankingMethodInfo &&
      sortFunction(netBankingMethodInfo, getComparator("asc", "brand"))
    );
  }, [netBankingMethodInfo]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <PaymentIntrumentSelection
        data={sortedData}
        searchComponent={true}
        searchPlaceholder={t("SEARCH_BANKS")}
      />

      <PyamentButton />
    </div>
  );
};

export default NetBanking;
