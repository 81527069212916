import { Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectSelectedIntrument } from "../../../../slices/SavedInstrumentSlice";
import { selectCurrencyCode, selectCurrencySymbol, } from "../../../../slices/PaymentSessionDetailsSlice";
import getSymbolFromCurrency from "currency-symbol-map";
import TranslateText from "../../../resuableComponents/TranslateText";

export default function EmiPlanDetails() {
  const { t } = useTranslation();
  const selectedInstrument = useSelector((state) => selectSelectedIntrument(state));
  const currencyCode = useSelector((state) => selectCurrencyCode(state));
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);

  return (
    <>
      <div>
        <div style={{ display: "flex" }}>
          <Typography className="emiDetailsHeading dynamic-font-family">
            <TranslateText label={'TOTAL_COST'}>Total Cost</TranslateText>
          </Typography>
          <Typography className="emiDetailsHeading emiDetailsMargin dynamic-font-family">
            <span
              style={{
                fontFamily: "sans-serif",
                fontWeight: 400,
              }}
            >
              {getSymbolFromCurrency(currencyCode)}{" "}
            </span>
            {selectedInstrument?.emiMethod?.totalAmount}
          </Typography>
        </div>
        <div className="detailsStyling">
          <Typography className="emiDetailsHeading dynamic-font-family">
            <TranslateText label={'MONTHLY_EMI'}>Monthly EMI</TranslateText>
          </Typography>
          <Typography className="emiDetailsHeading emiDetailsMargin dynamic-font-family">
            <span
              style={{
                fontFamily: "sans-serif",
                fontWeight: 400,
              }}
            >
              {getSymbolFromCurrency(currencyCode)}{" "}
            </span>
            {selectedInstrument?.emiMethod?.emiAmountLocaleFull}
          </Typography>
        </div>
        <div className="detailsStyling">
          <Typography className="emiDetailsHeading dynamic-font-family">
            <TranslateText label={'TOTAL_INTEREST_CHARGED_BY_BANK'}>Total Interest (Charged by Bank)</TranslateText>
          </Typography>
          <Typography className="emiDetailsHeading emiDetailsMargin dynamic-font-family">
            <span
              style={{
                fontFamily: "sans-serif",
                fontWeight: 400,
              }}
            >
              {getSymbolFromCurrency(currencyCode)}{" "}
            </span>
            {selectedInstrument?.emiMethod?.interestChargedAmount}
          </Typography>
        </div>
        {selectedInstrument?.emiMethod?.processingFee && (
          <div className="detailsStyling">
            <Typography className="emiDetailsHeading dynamic-font-family">
              <TranslateText label={'PROCESSING_FEE'}>Processing fee</TranslateText>
            </Typography>
            <Typography className="emiDetailsHeading emiDetailsMargin dynamic-font-family">
              <span
                style={{
                  fontFamily: "sans-serif",
                  fontWeight: 500,
                }}
              >
                {getSymbolFromCurrency(currencyCode)}{" "}
              </span>
              {selectedInstrument?.emiMethod?.processingFee?.amount}
            </Typography>
          </div>
        )}
        <div style={{ display: "flex", margin: "8px 0px 12px 0px" }}>
          <Typography className="noteHeading dynamic-font-family">
            <TranslateText label={'CARD_CHARGED_AMOUNT'}>Your card will be charged for an amount of</TranslateText>
            <span style={{ fontWeight: 700 }}>
              <span
                style={{
                  fontFamily: "sans-serif",
                }}
              >
                {getSymbolFromCurrency(currencyCode)}{" "}
              </span>
              {sessionDetails?.paymentDetails?.money?.amountLocaleFull}.
            </span>{" "}
            <TranslateText label={'CHARGED_INTEREST'}>Your card will be charged for an amount of</TranslateText>
            <span style={{ fontWeight: 700 }}>
              <span
                style={{
                  fontFamily: "sans-serif",
                }}
              >
                {getSymbolFromCurrency(currencyCode)}{" "}
              </span>
              {selectedInstrument?.emiMethod?.interestChargedAmountLocaleFull}{" "}
            </span>
            <TranslateText label={'BANK_TOTAL_PAYABLE_AMOUNT'}>by the bank making the total payable amount as</TranslateText>
            <span style={{ fontWeight: 700 }}>
              <span
                style={{
                  fontFamily: "sans-serif",
                }}
              >
                {getSymbolFromCurrency(currencyCode)}{" "}
              </span>
              {selectedInstrument?.emiMethod?.totalAmountLocaleFull}.
            </span>
          </Typography>
        </div>
        {selectedInstrument?.emiMethod?.processingFee && (
          <>
            <div style={{ display: "flex", margin: "8px 0px 12px 0px" }}>
              <Typography className="noteHeading dynamic-font-family">
                <TranslateText label={'PROCESSING_FEE_AMOUNT'}>A processing fee of</TranslateText>
                <span style={{ fontWeight: 700 }}>
                  <span
                    style={{
                      fontFamily: "sans-serif",
                    }}
                  >
                    {getSymbolFromCurrency(currencyCode)}{" "}
                  </span>
                  {
                    selectedInstrument?.emiMethod?.processingFee
                      ?.amountLocaleFull
                  }{" "}
                  + <TranslateText label={'GST'}>GST</TranslateText>
                </span>{" "}
                <TranslateText label={'BANK_EMI_TRANSACTION'}>as applicable by the bank on the successful EMI transactions</TranslateText>.
              </Typography>
            </div>
          </>
        )}
      </div>
    </>
  );
}
