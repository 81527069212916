import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { generateDynamicLogo } from "../../../../Utils/utils";
import PaymentIntrumentSelection from "../../paymentOptions/paymentInstrumentSelection/PaymentIntrumentSelection";
import { selectBaseColor } from "../../../../slices/PaymentSessionDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "antd";
import "./viewSavedInstruments.css";
import ViewSavedInstruments from "./ViewSavedInstruments";
import { changeActivePaymentMethod } from "../../../../slices/PaymentButtonSlice";
import { removeSelectedIntruments } from "../../../../slices/SavedInstrumentSlice";
import TranslateText from "../../../resuableComponents/TranslateText";

const ViewSavedInstrumentsHeader = ({ title, data, type }) => {
  const { t } = useTranslation();
  const baseColor = useSelector((state) => selectBaseColor(state));
  const [selectedIntrument, setSelectedInstrument] = useState(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(removeSelectedIntruments());
    setOpen(false);
  };

  return (
    <div className="domestic-saved-instruments-root">
      <ViewSavedInstruments
        openModal={open}
        type={type}
        header={title}
        handleClose={handleClose}
        children={<PaymentIntrumentSelection data={data} />}
      />
      <div className="domestic-saved-instruments-title">
        <Typography
          className="semiBold14Heading dynamic-font-family"
          style={{ color: "var(--Neutral-Grey-1000)" }}
        >
          {title}
        </Typography>
        <div
          className="domestic-saved-instruments-view-all-logo dynamic-font-family"
          onClick={() => {
            dispatch(removeSelectedIntruments());
            dispatch(changeActivePaymentMethod("UpiSaved"));
            setOpen(true);
          }}
        >
          <Typography className="domestic-saved-instruments-view-all-title dynamic-title">
            <TranslateText label={"VIEW_ALL"}>View All</TranslateText>
          </Typography>
          {generateDynamicLogo("rightArraow", "14px", "14px", baseColor)}
        </div>
      </div>

      <div className="domestic-saved-instruments-content">
        <PaymentIntrumentSelection data={data?.slice(0, 2)} />
      </div>
    </div>
  );
};

export default ViewSavedInstrumentsHeader;
