import React, { useCallback, useEffect, useState, useRef } from "react";
import { DatePicker, Form, Input, Select, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { CountryData } from "../../../staticData/countryData";
import { blockInvalidChar, disableFutureDates, isDateInRange, isEmpty, validateDob } from "../../../Utils/utils";
import { addressAnalyticsEvent } from "../../../Utils/uiAnalytics";
import { useDispatch, useSelector } from "react-redux";
import { countryPinCodeData } from "../../../staticData/countryPinCodeData";
import CustomButton from "../../resuableComponents/CustomButton";
import "./deliveryAddress.css";
import { selectIsMobileLayout } from "../../../slices/MobileLayoutSlice";
import {
  collpaseAddressComponent,
  getAllPaymentMethods,
  updateSavedAddressData,
  updateShopperDetails,
} from "../../../slices/PaymentSessionDetailsSlice";
import CollpaseAddress from "./collpaseAddress";
import { UserOutlined } from "@ant-design/icons";
import MobileAddressButton from "./mobileAddressButon";
import TranslateText from "../../resuableComponents/TranslateText";
import dayjs from "dayjs";

export default function BasicDetails({
  addressFieldValue,
  setShowAddAddressOption,
  setSelectedCardButton,
  setAddressFieldValue,
  handleGuestData,
}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
  const addressCollpase = useSelector((state) => state.paymentSessionDetails.addressCollpase);
  const isShippingAddressDisplay = useSelector((state) => state.paymentSessionDetails.isShippingAddressDisplay);
  const { showEmailAddress, showFullName, showContactNumber, showPan, showDob } = useSelector((state) => state.additionalComponents)
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));
  const guestCheckout = useSelector((state) => state.shopperInfo.guestCheckout);
  const shopperinfo = useSelector((state) => state.shopperInfo.shopperinfo);
  const [form] = Form.useForm();
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [panNumber, setPanNmber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [phoneDialCode, setPhoneDialCode] = useState("");
  const [payloadData, setPayloadData] = useState("");
  const payloadDataRef = useRef("");
  const [disableButton, setDisableButton] = useState(true);
  const filterCountry = (input, option) => (option?.props?.children ?? "").toLowerCase().includes(input.toLowerCase());
  useEffect(() => {
    if (shopperinfo) {
      const dialCode = countryPinCodeData?.find((country) =>
        shopperinfo?.shopperRef?.startsWith(country.dial_code)
      );
      const phoneNumberWithoutDialCode = dialCode
        ? shopperinfo?.shopperRef.replace(dialCode.dial_code, "")
        : shopperinfo?.shopperRef;
      const normalizedPhoneNumber =
        sessionDetails?.paymentDetails?.shopper?.phoneNumber?.startsWith("+")
          ? sessionDetails?.paymentDetails?.shopper?.phoneNumber
          : `+${sessionDetails?.paymentDetails?.shopper?.phoneNumber}`;

      const country = CountryData.find((country) =>
        normalizedPhoneNumber.startsWith(country?.dial_code)
      );
      if (country) {
        setPhoneDialCode(country.dial_code);
      } else if (!country) {
        setPhoneDialCode("+91");
      }
      setPhoneNumber(phoneNumberWithoutDialCode);
      form.setFieldsValue({ phoneNumber: phoneNumberWithoutDialCode });
    }
  }, [shopperinfo, form]);
  useEffect(() => {
    if (sessionDetails && shopperinfo?.shopperRef === null) {
      const { firstName, lastName, email, panNumber, dateOfBirth } = sessionDetails?.paymentDetails?.shopper
      const normalizedPhoneNumber =
        sessionDetails?.paymentDetails?.shopper?.phoneNumber?.startsWith("+")
          ? sessionDetails?.paymentDetails?.shopper?.phoneNumber
          : `+${sessionDetails?.paymentDetails?.shopper?.phoneNumber}`;

      const dialCode = CountryData?.find((country) =>
        normalizedPhoneNumber?.startsWith(country?.dial_code)
      );
      const phoneNumberWithoutDialCode = dialCode
        ? normalizedPhoneNumber.replace(dialCode?.dial_code, "")
        : sessionDetails?.paymentDetails?.shopper?.phoneNumber;
      const country = CountryData.find((country) =>
        normalizedPhoneNumber.startsWith(country?.dial_code)
      );
      if (country) {
        setPhoneDialCode(country.dial_code);
      } else if (!country) {
        setPhoneDialCode("+91");
      }
      if (firstName && lastName) {
        const fullName = `${firstName} ${lastName}`;
        setFullName(fullName);
        form.setFieldsValue({ fullName: fullName });
      } else if (firstName) {
        setFullName(firstName);
        form.setFieldsValue({ fullName: firstName });
      } else if (lastName) {
        setFullName(lastName);
        form.setFieldsValue({ fullName: lastName });
      } else {
        setFullName("");
        form.setFieldsValue({ fullName: "" });
      }
      setEmailAddress(email);
      form.setFieldsValue({ email: email });

      setPanNmber(panNumber);
      form.setFieldsValue({ panNumber: panNumber });
      setPhoneNumber(phoneNumberWithoutDialCode);
      form.setFieldsValue({ phoneNumber: phoneNumberWithoutDialCode });

      if (!isEmpty(dateOfBirth) && isDateInRange(dateOfBirth)) {
        const date = dayjs(dateOfBirth);
        setDateOfBirth(dateOfBirth);
        form.setFieldsValue({ dateOfBirth: date });
      }
    }
  }, [sessionDetails, shopperinfo, form]);
  useEffect(() => {
    if (guestCheckout) {
      if (sessionDetails) {
        const normalizedPhoneNumber =
          sessionDetails?.paymentDetails?.shopper?.phoneNumber?.startsWith("+")
            ? sessionDetails?.paymentDetails?.shopper?.phoneNumber
            : `+${sessionDetails?.paymentDetails?.shopper?.phoneNumber}`;

        const dialCode = CountryData?.find((country) =>
          normalizedPhoneNumber?.startsWith(country.dial_code)
        );
        const phoneNumberWithoutDialCode = dialCode
          ? normalizedPhoneNumber.replace(dialCode.dial_code, "")
          : normalizedPhoneNumber;
        setPhoneNumber(phoneNumberWithoutDialCode);
        form.setFieldsValue({ phoneNumber: phoneNumberWithoutDialCode });
      }
    }
  }, [guestCheckout, sessionDetails, form]);
  useEffect(() => {
    if (guestCheckout) {
      if (sessionDetails?.paymentDetails?.shopper?.phoneNumber) {
        const phoneNumber = sessionDetails.paymentDetails.shopper.phoneNumber;
        const normalizedPhoneNumber = phoneNumber.startsWith("+")
          ? phoneNumber
          : `+${phoneNumber}`;

        const country = CountryData.find((country) =>
          normalizedPhoneNumber.startsWith(country.dial_code)
        );
        if (country) {
          setPhoneDialCode(country.dial_code);
        }
      } else {
        setPhoneDialCode("+91");
      }
    }
  }, [guestCheckout, sessionDetails]);
  const validateEmail = (rule, value, callback) => {
    const regex = /^[^\s@']+@[^\s@']+\.[^\s@']+$/;
    if (!value) {
      callback(t("REQUIRED"));
    } else if (!regex.test(value)) {
      callback(t("INVALID_EMAIL"));
      setDisableButton(true);
    } else {
      callback();
    }
  };
  const validatePan = (rule, value, callback) => {
    const regex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (!value) {
      callback(t("REQUIRED"));
    } else if (!regex.test(value)) {
      callback(t("INVALID_PAN"));
      setDisableButton(true);
    } else {
      callback();
    }
  }
  const handleFullName = (e) => {
    setFullName(e.target.value);
    addressAnalyticsEvent(sessionDetails, dispatch);
  };
  const validatePhoneNumber = (telNo, country) => {
    if (telNo === "") {
      return t("REQUIRED");
    } else if (country) {
      if (telNo.length !== country.phoneNumberLength) {
        return `Mobile number must be ${country.phoneNumberLength} digits`;
      } else if (telNo.charAt(0) === "0") {
        return "Invalid number";
      }
    }
    return "";
  };
  const handlePhoneChange = (e) => {
    const telNo = e.target.value;
    const re = /^[0-9\b]+$/;
    if (telNo === "" || re.test(telNo)) {
      setPhoneNumber(telNo);
      const country = countryPinCodeData.find(
        (country) => country.dial_code === phoneDialCode
      );
      const error = validatePhoneNumber(telNo, country);
      setPhoneError(error);
      addressAnalyticsEvent(sessionDetails, dispatch);
    }
  };
  const handleEmailAddress = (e) => {
    setEmailAddress(e.target.value);
    addressAnalyticsEvent(sessionDetails, dispatch);
  };
  const handleCountryChange = (value) => {
    setPhoneDialCode(value);
  };
  const handlePan = (e) => {
    setPanNmber(e.target.value);
    addressAnalyticsEvent(sessionDetails, dispatch);
  };
  const handleDob = (date, dateString) => {
    if (!date) {
      setDateOfBirth(null); // When no date is selected, set it to null
    } else {
      const formattedDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
      setDateOfBirth(formattedDate);
    }
    addressAnalyticsEvent(sessionDetails, dispatch);
  }
  useEffect(() => {
    const hasError = (showFullName ? !fullName : false)
      || (showContactNumber ? !phoneNumber : false)
      || (showEmailAddress ? !emailAddress : false)
      || (showPan ? !panNumber : false)
      || (showDob ? (!dateOfBirth || !isDateInRange(dateOfBirth)) : false);
    if (hasError || phoneError?.length) setDisableButton(true);
    else setDisableButton(false);

    if (!showFullName && !showContactNumber && !showEmailAddress && !isShippingAddressDisplay && !showDob && !showPan) {
      dispatch(collpaseAddressComponent());
    }
  }, [fullName, phoneNumber, emailAddress, showFullName, showContactNumber, showEmailAddress, phoneError, showDob, dateOfBirth, showPan, panNumber]);
  const handleBasicDetails = () => {
    const selectedCardData = {
      name: fullName,
      phoneNumber: isEmpty(phoneNumber) ? '' : phoneDialCode + phoneNumber,
      email: emailAddress,
      panNumber,
      dateOfBirth,
    };

    dispatch(updateSavedAddressData(selectedCardData));
    dispatch(updateShopperDetails({ selectedCardData }));
    dispatch(collpaseAddressComponent());
    dispatch(getAllPaymentMethods({
      token: sessionDetails?.token,
    })
    );
  };
  useEffect(() => {
    const { firstName = "", lastName = "", phoneNumber, email, panNumber, dateOfBirth } = sessionDetails?.paymentDetails?.shopper;
    if (showEmailAddress && (firstName || lastName) && phoneNumber && email && panNumber && (dateOfBirth && isDateInRange(dateOfBirth))) {
      const name = [firstName, lastName].filter(Boolean).join(" ");
      const selectedCardData = {
        name,
        phoneNumber: phoneNumber,
        email: email,
        panNumber,
        dateOfBirth,
      };
      dispatch(updateSavedAddressData(selectedCardData));
      dispatch(updateShopperDetails({ selectedCardData }));
      dispatch(collpaseAddressComponent());
    }
  }, [sessionDetails, showEmailAddress]);

  useEffect(() => {
    if (Object.keys(addressFieldValue)?.length) {
      const normalizedPhoneNumber = addressFieldValue?.phoneNumber?.startsWith("+") ? addressFieldValue?.phoneNumber : `+${addressFieldValue?.phoneNumber}`;
      const dialCode = CountryData?.find((country) =>
        normalizedPhoneNumber?.startsWith(country?.dial_code)
      );
      const phoneNumberWithoutDialCode = dialCode ? normalizedPhoneNumber.replace(dialCode?.dial_code, "") : normalizedPhoneNumber;
      setFullName(addressFieldValue.name);
      setPhoneNumber(phoneNumberWithoutDialCode);
      setPhoneDialCode(dialCode?.dial_code);

      setEmailAddress(addressFieldValue?.email);
      setPanNmber(panNumber);
      if (!isEmpty(addressFieldValue?.dateOfBirth) && isDateInRange(addressFieldValue?.dateOfBirth)) {
        const date = dayjs(addressFieldValue?.dateOfBirth);
        setDateOfBirth(addressFieldValue?.dateOfBirth);
        form.setFieldsValue({ dateOfBirth: date });
      }

      form.setFieldsValue({
        fullName: addressFieldValue?.name,
        phoneNumber: phoneNumberWithoutDialCode,
        email: addressFieldValue?.email,
        panNumber: addressFieldValue?.panNumber
      });
    }
  }, [addressFieldValue, form]);
  useEffect(() => {
    const formData = {
      name: fullName,
      email: emailAddress,
      phoneNumber: phoneNumber,
      panNumber: panNumber,
      dateOfBirth: dateOfBirth,
      phoneDialCode: phoneDialCode,
    };
    setPayloadData(formData);
  }, [fullName, emailAddress, phoneDialCode, phoneNumber, dateOfBirth, panNumber]);

  useEffect(() => {
    const handleLanguageChange = () => {
      form.validateFields(['fullName']);
      form.validateFields(['phoneNumber']);
      form.validateFields(['email']);
      form.validateFields(['panNumber']);
      form.validateFields(['dateOfBirth']);
    };

    // Listen for language changes
    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n, form]);

  const DobComponent = useCallback(() => {
    return (
      <Form.Item
        label={
          <Typography className="regular14Heading deliveryTitle dynamic-font-family">
            <TranslateText label={"DATE_OF_BIRTH"}>Date of birth</TranslateText>*
          </Typography>
        }
        name="dateOfBirth"
        rules={[
          {
            required: true,
            message: t("REQUIRED")
          },
          {
            validator: validateDob,
          },
        ]}
      >
        <DatePicker className={"date-picker-w-100"}
          defaultPickerValue={dayjs().subtract(5, 'year')}
          value={dateOfBirth ? dayjs(dateOfBirth) : null}
          onChange={handleDob}
          disabledDate={disableFutureDates}
          renderExtraFooter={() => null}
          placeholder={t("YYYY_DD_MM")}
          showToday={false}
          allowClear={false}
          inputReadOnly
        />
      </Form.Item>
    )
  }, [])

  return (
    <>
      {
        (showFullName || showContactNumber || showEmailAddress) && (
          <>
            {addressCollpase ? (
              <>
                <div className="deliveryForm">
                  <div className="deliveryAddressHeader">
                    <div className="headerDiv">
                      <UserOutlined />
                      <Typography className="deeliveryHeading dynamic-font-family">
                        <TranslateText label={"PERSONAL_DETAILS"}>Personal Details</TranslateText>
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "0px 0px 12px 12px",
                    }}
                  >
                    <CollpaseAddress
                      setShowAddAddressOption={setShowAddAddressOption}
                      setSelectedCardButton={setSelectedCardButton}
                      setAddressFieldValue={setAddressFieldValue}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="deliveryForm">
                <div className="deliveryAddressHeader">
                  <div className="headerDiv">
                    <UserOutlined />
                    <Typography className="deeliveryHeading dynamic-font-family">
                      <TranslateText label={"PERSONAL_DETAILS"}>Personal Details</TranslateText>
                    </Typography>
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "white",
                    paddingTop: "8px",
                    borderRadius: "0px 0px 12px 12px",
                  }}
                >
                  <Form
                    requiredMark={"optional"}
                    layout="vertical"
                    name="addresss"
                    form={form}
                  >
                    <div className="cardPadding">
                      <div className="deliveryAddressFields">
                        {
                          showFullName && (
                            <Form.Item
                              label={
                                <Typography className="regular14Heading deliveryTitle dynamic-font-family">
                                  <TranslateText label={"FULL_NAME"}>Full Name</TranslateText>*
                                </Typography>
                              }
                              name="fullName"
                              rules={[
                                {
                                  required: true,
                                  message: t("REQUIRED"),
                                },
                              ]}
                            >
                              <>
                                <Input
                                  placeholder={t("FULL_NAME")}
                                  value={fullName}
                                  onChange={handleFullName}
                                />
                              </>
                            </Form.Item>
                          )
                        }
                        {
                          showContactNumber && (
                            <Form.Item
                              label={
                                <Typography className="regular14Heading deliveryTitle dynamic-font-family">
                                  <TranslateText label={"MOBILE_NUMBER"}>Mobile Number</TranslateText>*
                                </Typography>
                              }
                              name="phoneNumber"
                              validateStatus={phoneError ? "error" : ""}
                              help={phoneError}
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Space
                                direction="vertical"
                                size="middle"
                                style={{ width: "100%" }}
                              >
                                <Space.Compact>
                                  <Select
                                    className="contactNumber"
                                    showSearch
                                    filterOption={filterCountry}
                                    value={phoneDialCode}
                                    onChange={handleCountryChange}
                                  >
                                    {CountryData.map((country) => (
                                      <Select.Option
                                        key={country.code}
                                        value={country.dial_code}
                                      >
                                        {country.dial_code}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                  <Input
                                    type="number"
                                    value={phoneNumber}
                                    style={{ width: "100%" }}
                                    onKeyDown={blockInvalidChar}
                                    onChange={handlePhoneChange}
                                    placeholder={t("MOBILE_NUMBER")}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                  />
                                </Space.Compact>
                              </Space>
                            </Form.Item>
                          )
                        }
                      </div>
                      <div className="deliveryAddressFields">
                        {showEmailAddress && (
                          <Form.Item
                            label={
                              <Typography className="regular14Heading deliveryTitle dynamic-font-family">
                                <TranslateText label={"EMAIL"}>Email</TranslateText>*
                              </Typography>
                            }
                            name="email"
                            rules={[
                              {
                                required: true,
                                validator: validateEmail,
                              },
                            ]}
                          >
                            <>
                              <Input
                                placeholder={t("EMAIL_ADDRESS")}
                                value={emailAddress}
                                onChange={handleEmailAddress}
                              />
                            </>
                          </Form.Item>
                        )}
                        {
                          showPan && (
                            <Form.Item
                              label={
                                <Typography className="regular14Heading deliveryTitle dynamic-font-family">
                                  <TranslateText label={"PAN"}>Pan</TranslateText>*
                                </Typography>
                              }
                              name="panNumber"
                              rules={[
                                {
                                  required: true,
                                  validator: validatePan,
                                },
                              ]}
                            >
                              <>
                                <Input
                                  placeholder={t("PAN")}
                                  value={panNumber}
                                  onChange={handlePan}
                                />
                              </>
                            </Form.Item>
                          )
                        }
                        {
                          !showPan && showDob && (
                            <DobComponent />
                          )
                        }
                      </div>
                      <div className="deliveryAddressFields">
                        {
                          showDob && showPan && (
                            <DobComponent />
                          )
                        }
                      </div>
                    </div>
                    <div
                      className={isMobileLayout ? "" : "continueButtonDiv"}
                      style={{ display: isMobileLayout ? "none" : "" }}
                    >
                      <CustomButton
                        text={t("CONTINUE")}
                        buttonType={"primary-btn"}
                        padding={"10px 50px"}
                        onClick={() => handleBasicDetails()}
                        htmlType="submit"
                        disabled={disableButton}
                      />
                    </div>
                    <div style={{ display: isMobileLayout ? "" : "none" }}>
                      <MobileAddressButton
                        disabled={disableButton}
                        showCancelButton={false}
                        setShowAddAddressOption={setShowAddAddressOption}
                        setSelectedAddressItem={""}
                        selectedAddressItem={""}
                        payloadData={payloadData}
                        otherLabel={""}
                        selectedSavedAddress={""}
                        guestCheckout={""}
                        handleGuestData={handleGuestData}
                        handleBasicDetails={handleBasicDetails}
                      />
                    </div>
                  </Form>
                </div>
              </div>
            )}
          </>
        )
      }

    </>
  );
}
